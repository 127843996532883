upload-status .progress {
  width: 85%;
  height: 8px;
  padding: 1px;
  border: 1px solid #888;
  border-radius: 3px;
}
upload-status .progress-text {
  width: 10%;
  line-height: 19px;
}
upload-status .bar {
  display: inline-block;
  height: 8px;
  transition: width 1s;
  border-radius: 3px;
  background-color: #B5DA2F;
}
