.role-list-expander .role-details-table {
  width: 100%;
  background: none;
}
.role-list-expander .role-details-head {
  background: none;
}
.role-list-expander .role-details-head-row {
  border-bottom: 1px solid #D2D2D2;
}
.role-list-expander .role-details-head-column {
  padding: 0 0 10px 0;
  border: 0 !important;
  color: #666;
  font-weight: bold;
  text-align: left;
}
.role-list-expander .role-details-column {
  padding: 10px;
}
.role-list-expander .role-details-column .role-details-cell-container {
  margin-bottom: 10px;
}
.role-list-expander .role-details-column .role-details-cell-container .role-details-name {
  font-weight: bold;
}
