*[percent-pile-chart] > div {
  height: 100%;
}
*[percent-pile-chart] > div > div.bar {
  width: 70px;
  height: 3px;
  margin: 1px auto 2px auto;
  background-color: #e5e5e5;
  border-radius: 3px;
  background-clip: padding-box;
}
*[percent-pile-chart] > div > div.bar.full {
  background-color: #67B0CB;
}
server-percent-pile-chart {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
