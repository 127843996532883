.update-card-about {
  grid-column-gap: 0;
  grid-row-gap: 0;
  border-top: thin solid #f4f4f4;
  background-color: #fff;
}
.update-card-about__tile {
  padding: 14px 10px;
}
.update-card-about__tile:first-child {
  border-right: thin solid #f4f4f4;
}
.update-card-about__tile-label {
  font-size: 12px;
  font-weight: bold;
}
.update-card-about__tile-text {
  font-size: 14px;
}
.update-card-about__tile-text--bottom {
  padding-bottom: 14px;
}
.update-card-about__tile--footer {
  border-top: thin solid #f4f4f4;
}
