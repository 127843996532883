.avi-cloud-page {
  padding: 1%;
  background-color: #fff;
}
.avi-cloud-page__detail-container {
  position: relative;
  border: 1px solid #e5e5e5;
}
.avi-cloud-page__detail-section {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  font-size: 15px;
  font-weight: 400;
}
.avi-cloud-page__detail-section:nth-child(3) {
  border-top: 1px solid #e5e5e5;
}
.avi-cloud-page__detail-section-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.avi-cloud-page__action-button {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.avi-cloud-page__action-button:nth-child(even) {
  border-top: 1px solid #e5e5e5;
}
.avi-cloud-page__action-button:last-child {
  border-top: 1px solid #e5e5e5;
}
.avi-cloud-page__status {
  color: #e5e5e5;
  letter-spacing: 1px;
}
.avi-cloud-page__status--connected {
  color: #c1e052;
}
.avi-cloud-page__detail-section-label {
  font-size: 18px;
  letter-spacing: 1px;
}
.avi-cloud-page__detail-section-icon {
  padding: 4px;
  font-size: 10px;
}
.avi-cloud-page__spinner {
  position: absolute;
  top: 40%;
  left: 50%;
}
