.roles-grid-legends {
  padding: 10px 0;
  float: right;
}
.roles-grid-legend {
  font-size: 16px;
  vertical-align: bottom;
}
.roles-grid-legend:not(:last-child) {
  margin-right: 10px;
}
.roles-collapsible-grid {
  width: 100%;
}
.roles-grid-row-spacer {
  height: 30px;
  border-collapse: collapse;
  border: 0;
  background: none !important;
}
.roles-collapsible-grid-group {
  border: 1px solid #999;
  cursor: pointer;
}
.roles-collapsible-grid-group.collapsed ~ .roles-collapsible-grid-row {
  display: none;
}
.roles-collapsible-grid-group.collapsed .roles-row-collapse-icon > i.icon-plus {
  display: block;
}
.roles-collapsible-grid-group.collapsed .roles-row-collapse-icon > i.icon-minus {
  display: none;
}
.roles-collapsible-grid-group .roles-row-collapse-icon {
  display: inline-block;
  position: relative;
}
.roles-collapsible-grid-group .roles-row-collapse-icon > i.icon-plus {
  display: none;
}
.roles-collapsible-grid-group .roles-row-collapse-icon > i.icon-minus {
  display: block;
}
.roles-collapsible-grid-row:nth-child(odd) {
  background: #e5e5e5;
}
.roles-collapsible-grid-row-cell,
.roles-collapsible-grid-group-cell {
  vertical-align: middle;
}
.roles-collapsible-grid-group-cell {
  padding: 10px 0;
}
.roles-collapsible-grid-row-cell {
  padding: 5px 0;
}
.roles-collapsible-grid-group-cell:nth-child(1) {
  padding-left: 10px;
}
.roles-collapsible-grid-row-cell:nth-child(1) {
  padding-left: 20px;
}
.roles-collapsible-grid-row-cell.check-cell,
.roles-collapsible-grid-group-cell.check-cell {
  width: 35px;
  text-align: center;
}
.graphic-checkbox {
  display: none;
}
.graphic-checkbox[disabled] + label {
  opacity: 0.5;
  pointer-events: none;
}
.graphic-checkbox + label {
  font-size: 18px;
  cursor: pointer;
}
.graphic-checkbox:checked + label > i.icon-circle-empty {
  color: #AD73E8;
}
.graphic-checkbox:checked + label > i.icon-ok-circled2 {
  color: #839c2b;
}
.graphic-checkbox:checked + label > i.icon-lock {
  color: #dca512;
}
.graphic-checkbox:checked + label > i.icon-cancel {
  color: #ce4b18;
}
.icon-circle-empty.roles-grid-icon.color {
  color: #AD73E8;
}
.icon-ok-circled2.roles-grid-icon.color {
  color: #839c2b;
}
.icon-lock.roles-grid-icon.color {
  color: #dca512;
}
.icon-cancel.roles-grid-icon.color {
  color: #ce4b18;
}
