.dropdown-menu__button {
  height: 30px;
  min-height: 30px;
  padding: 0 10px;
  border-radius: 3px;
  background-color: #425674;
  color: white;
}
.dropdown-menu__button:hover,
.dropdown-menu__button:active {
  background-color: #263242;
}
.dropdown-menu__icon:before {
  transition-duration: 0.3s;
}
.dropdown-menu__icon--flipped:before {
  transform: rotate(180deg);
}
