add-pool-servers {
  display: block;
  overflow: hidden;
}
add-pool-servers > div {
  box-sizing: border-box;
  width: 100%;
}
add-pool-servers > div .main {
  display: flex;
  flex-direction: row;
}
add-pool-servers > div .main > div {
  position: relative;
  flex: 1 1 auto;
  margin-right: 0.5em;
}
add-pool-servers > div .main > div > input.servers-str {
  width: 100%;
}
add-pool-servers > div .main > div > div[avi-loader] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  right: 0.5em;
  left: auto;
}
add-pool-servers > div .main > div > div.resolutions {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  overflow: hidden;
}
add-pool-servers > div .main > div > div.resolutions > input {
  flex: 1 1 auto;
}
add-pool-servers > div .main > button {
  flex: 0 1 100px;
}
