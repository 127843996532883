div.marathon-config {
  position: relative;
}
div.marathon-config > div.avi-btn-group {
  position: absolute;
  top: 1.1em;
  right: 0;
}
div.marathon-config:after {
  content: ' ';
  clear: both;
}
.cloud-mesos label.radio-label {
  margin-right: 45px;
}
.cloud-mesos input {
  width: 100%;
}
.cloud-mesos textarea {
  box-sizing: border-box;
  width: 100%;
  font-family: "brandon_text", sans-serif;
}
.awsSettings input {
  width: 100%;
}
.linux-host-radio-group {
  display: block;
  position: relative;
}
.linux-host-radio-group .host-value-input {
  width: 60px;
  height: 24px;
}
.linux-host-radio-group .radio-label {
  margin: 0;
}
