policy-grid-expander {
  width: 100%;
}
.policy-grid-expander {
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: flex-start;
}
.policy-grid-expander__info-container {
  box-sizing: border-box;
  flex: 0 0 48.5%;
  border: 1px solid #8c8c8c;
}
.policy-grid-expander__info-container--match {
  margin-right: 0.5%;
  margin-left: 1%;
}
.policy-grid-expander__info-container--action {
  margin-right: 1%;
  margin-left: 0.5%;
}
.policy-grid-expander__info-container__header {
  padding: 5px 10px;
  background-color: #8c8c8c;
  color: white;
  font-size: 11px;
  text-transform: uppercase;
}
.policy-grid-expander__info-container__body {
  padding: 10px;
}
.policy-grid-expander__info {
  padding: 3px 0;
}
.policy-grid-expander__key {
  color: #8c8c8c;
  font-size: 11px;
  text-transform: uppercase;
}
