.network-profile-create .control-group input[type=text],
.network-profile-create .control-group input[type=number] {
  width: 100%;
}
.network-profile-create .col {
  width: 49%;
}
.network-profile-create .inner-text {
  width: 100%;
}
.small-header {
  color: #AAA;
  font-weight: bold;
}
