.avi-virtualservice-create textarea {
  resize: vertical;
}
.avi-virtualservice-create div.warning.select-cloud {
  margin: 1em;
}
.avi-virtualservice-create .clientIp,
.avi-virtualservice-create .path,
.avi-virtualservice-create .query {
  display: inline-block;
  width: 60%;
}
.avi-virtualservice-create .clientIp .avi-btn-group,
.avi-virtualservice-create .path .avi-btn-group,
.avi-virtualservice-create .query .avi-btn-group {
  width: 100%;
}
.avi-virtualservice-create .clientIp .avi-btn-group .match-operation,
.avi-virtualservice-create .path .avi-btn-group .match-operation,
.avi-virtualservice-create .query .avi-btn-group .match-operation {
  border-right: none;
  line-height: 33px;
}
.avi-virtualservice-create .clientIp .avi-btn-group .match-operation checkbox,
.avi-virtualservice-create .path .avi-btn-group .match-operation checkbox,
.avi-virtualservice-create .query .avi-btn-group .match-operation checkbox {
  margin-right: 3px;
  margin-bottom: -2px;
  margin-left: 3px;
}
.avi-virtualservice-create .clientIp .avi-btn-group ip-or-group-list [ng-repeat]:first-child collection-dropdown-custom,
.avi-virtualservice-create .path .avi-btn-group ip-or-group-list [ng-repeat]:first-child collection-dropdown-custom,
.avi-virtualservice-create .query .avi-btn-group ip-or-group-list [ng-repeat]:first-child collection-dropdown-custom {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.avi-virtualservice-create .controls {
  margin-left: 0;
}
.avi-virtualservice-create .controls div[avi-loader] {
  position: absolute;
  top: 0;
  right: 20px;
  line-height: 35px;
}
.avi-virtualservice-create .logfilters-table {
  width: 60%;
}
.avi-virtualservice-create .panel,
.avi-virtualservice-create .panel.transparent {
  border: 0;
}
.avi-virtualservice-create .panel .panel-header,
.avi-virtualservice-create .panel.transparent .panel-header {
  padding: 0;
  border-bottom: 0;
}
.avi-virtualservice-create .panel .panel-body,
.avi-virtualservice-create .panel.transparent .panel-body {
  padding: 0;
}
.avi-virtualservice-create e-auto-complete.placement-subnet {
  width: 50%;
}
.avi-virtualservice-create div.add-pool-servers {
  display: flex;
  flex-direction: row;
}
.avi-virtualservice-create div.add-pool-servers add-pool-servers {
  flex: 1 1 auto;
  margin-right: 1em;
}
.avi-virtualservice-create div.add-pool-servers div.select-server-by-network {
  flex: 0 1 200px;
  padding-top: 1.231em;
}
.avi-virtualservice-create .analytics-form {
  margin: 0;
  padding: 0;
  list-style: none;
}
.avi-virtualservice-create .analytics-form > li {
  display: inline-block;
  width: 24%;
  height: 77px;
  margin: 0;
  float: left;
  border-right: 1px solid #CCC;
}
.avi-virtualservice-create .analytics-form > li .control-group {
  box-sizing: border-box;
  margin-top: -5px;
  padding: 0 20px;
}
.avi-virtualservice-create .analytics-form > li input[type=checkbox] {
  margin-right: 5px;
}
.avi-virtualservice-create .analytics-form > li .comment {
  font-size: 12px;
}
.avi-virtualservice-create .analytics-form li:last-child {
  border-right: 0;
}
.avi-virtualservice-create .summary h2 {
  margin-top: 15px;
}
.avi-virtualservice-create .summary .grid .actions-panel {
  display: none;
}
.avi-virtualservice-create .remove-rounded-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.avi-virtualservice-create .basic-advanced {
  position: absolute;
  top: 21px;
  right: 0;
}
.avi-virtualservice-create .service-port-list {
  display: block;
  position: relative;
  width: 70%;
}
.avi-virtualservice-create .service-port-list .service-port {
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.avi-virtualservice-create .service-port-list .service-port .service-port-input-container {
  display: inline-flex;
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid #ccc;
}
.avi-virtualservice-create .service-port-list .service-port .service-port-input-container .service-port-input {
  flex: 1;
  margin: 0;
  border: 0;
}
.avi-virtualservice-create .service-port-list .service-port .service-port-input-container .advanced-service-port {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}
.avi-virtualservice-create .service-port-list .service-port .service-port-input-container .advanced-service-port .to {
  margin: 0 30px;
  font-weight: bold;
  text-transform: uppercase;
}
.avi-virtualservice-create .service-port-list .service-port .service-port-addon {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;
}
.avi-virtualservice-create .address.aws-dns {
  width: 100%;
}
.avi-virtualservice-create dropdown.url-pattern {
  width: 30%;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.avi-virtualservice-create collection-dropdown-custom.url-string-group {
  width: 70%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.avi-virtualservice-create__ssl-certificate {
  margin-top: 15px;
}
.select2-drop {
  border-color: #BEBFC1;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
.select2-drop-active {
  border-color: #BEBFC1;
}
.description-field {
  width: 90% !important;
}
.description-field textarea {
  width: 100%;
  height: 150px;
}
.pool-field {
  width: 100%;
  max-width: 373px;
}
