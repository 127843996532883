.user-modal-password-settings {
  margin-bottom: 15px;
}
.user-modal-password-settings__generate-btn {
  margin: 22px 0 0 10px;
}
.user-modal-password-settings__confirmation {
  margin-top: 10px;
}
.user-modal-password-settings__invalid-password {
  border-color: #ce4b18 !important;
  color: #ce4b18 !important;
}
