e-auto-complete {
  display: inline-block;
  position: relative;
}
e-auto-complete input {
  width: 100%;
  line-height: inherit;
}
e-auto-complete > div[avi-loader] {
  position: absolute;
  top: 0;
  right: 0.3em;
  bottom: 0;
  height: 1.6em;
  margin: auto 0;
}
e-auto-complete > div.suggestions {
  display: none;
  position: absolute;
  z-index: 10101;
  left: 0;
  min-height: 8px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #cccccc;
  background: #fff;
  box-shadow: 0 1px 5px 0 #cccccc;
  text-align: left;
  cursor: pointer;
}
e-auto-complete > div.suggestions > div.list > table {
  width: 100%;
}
e-auto-complete > div.suggestions > div.list > table.variable td.value {
  width: 17em;
}
e-auto-complete > div.suggestions > div.list > table.operator td.value {
  width: 2.5em;
}
e-auto-complete > div.suggestions > div.list > table.query td.value {
  width: 6em;
}
e-auto-complete > div.suggestions > div.list > table.query td.percent {
  width: 6em;
}
e-auto-complete > div.suggestions > div.list > table.query td.padding {
  width: 10em;
}
e-auto-complete > div.suggestions > div.list > table tr.selected {
  background: #e5e5e5;
}
e-auto-complete > div.suggestions > div.list > table td {
  padding: 0.5em 0 0.5em 1em;
  color: #666;
}
e-auto-complete > div.suggestions > div.list > table td.descr {
  width: 17em;
}
e-auto-complete > div.suggestions > div.list > table td.value {
  color: #3b8dab;
  font-weight: normal;
}
e-auto-complete > div.suggestions > div.list > table td.value > span.provided {
  font-weight: bold;
}
