.file-upload-textarea {
  display: block;
}
.file-upload-textarea__header {
  display: flex;
  margin-bottom: 5px;
}
.file-upload-textarea__label {
  flex: 1 1 auto;
}
.file-upload-textarea__radio {
  flex: 0 1 auto;
}
.file-upload-textarea__radio:last-child {
  margin-right: 0;
}
.file-upload-textarea__file-upload-wrapper {
  display: flex;
  justify-content: flex-end;
}
.file-upload-textarea__file-upload {
  flex: 1 1 auto;
}
.file-upload {
  display: flex;
  position: relative;
  height: 35px;
}
.file-upload__wrapper {
  display: flex;
  box-sizing: border-box;
  flex: 1 1 100%;
  align-items: center;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: white;
  color: #666;
}
.file-upload__wrapper--no-border {
  border: 0;
}
.file-upload__input-file {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}
.file-upload__input-file[disabled] {
  display: none;
}
.file-upload__file-name {
  flex: 1 1 auto;
  padding-left: 0.5rem;
}
.file-upload__button {
  display: flex;
  flex: 0 0.5 auto;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  min-width: 100px;
  padding: 0 1.25rem;
  background-color: #999;
  color: white;
  white-space: nowrap;
  user-select: none;
}
.file-upload__button--primary {
  background-color: #8ead1f;
}
.file-upload__button--disabled {
  background-color: #CCC;
  color: #888;
  cursor: default;
}
.file-upload--type--button {
  min-width: 100px;
}
.file-upload--type--button .file-upload__wrapper {
  border: none;
}
.file-upload--type--button .file-upload__button {
  flex: 1 1 100%;
}
