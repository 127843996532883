div.help-popover-backdrop {
  position: fixed;
  z-index: 10101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  float: none;
  transition: all 0.25s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);
  color: #666;
  /* have to redefine some styles inherited from aviheader */
  line-height: 1em;
  cursor: default !important;
}
div.help-popover-backdrop ul li {
  list-style-position: inside;
  list-style-type: disc;
}
div.help-popover-backdrop .transparent {
  opacity: 0;
}
div.help-popover-backdrop > div.help-popover-circle {
  /* questionmark for help popovers */
  position: absolute;
  width: 35px;
  height: 35px;
  border: 2px solid #FFF;
  border-radius: 100%;
  color: white;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
}
div.help-popover-backdrop > div.help-popover-circle > div.logCalloutCarrat {
  display: block;
  position: absolute;
  top: 2px;
  left: -13px;
}
div.help-popover-backdrop > div.help-popover-circle > div.logCalloutCarrat.left {
  right: 7px;
  left: auto;
}
div.help-popover-backdrop > help-popover {
  position: absolute;
  z-index: 2;
  min-width: 300px;
  max-width: 600px;
  min-height: 150px;
  max-height: 700px;
  overflow: hidden;
  border: 1px solid #888;
  border-radius: 3px;
  background-color: #fff;
  cursor: default !important;
}
div.help-popover-backdrop > help-popover ul li,
div.help-popover-backdrop > help-popover p {
  padding-bottom: 5px;
  line-height: 1.25em;
}
div.help-popover-backdrop > help-popover ul li:first-child,
div.help-popover-backdrop > help-popover p:first-child {
  padding-bottom: 0;
}
div.help-popover-backdrop > help-popover.initial {
  top: 15%;
  right: 0;
  left: 0;
  margin: 0 auto;
}
div.help-popover-backdrop > help-popover > i.closeButton {
  position: absolute;
  z-index: 2;
  top: 0.5em;
  right: 0.5em;
  color: #666;
  cursor: pointer;
}
div.help-popover-backdrop > help-popover > i.closeButton:hover {
  color: #ce4b18;
}
div.help-popover-backdrop > help-popover > .content {
  padding: 1.5em 1em 0.5em 1em;
}
div.help-popover-backdrop > help-popover > .content ul,
div.help-popover-backdrop > help-popover > .content ol {
  margin-left: 1em;
}
div.help-popover-backdrop > help-popover > .content li {
  margin: 0 0 0.5em 0;
}
div.help-popover-backdrop > help-popover > .content li > ul,
div.help-popover-backdrop > help-popover > .content li > ol {
  margin-top: 0.5em;
}
div.help-popover-backdrop > help-popover > .content ul {
  list-style: disc outside none;
}
div.help-popover-backdrop > help-popover > .content ol {
  list-style: decimal outside none;
}
div.help-popover-backdrop > help-popover > .content ol ol {
  list-style: lower-alpha outside none;
}
div.help-popover-backdrop > help-popover div.helpPosIndicatorWrap {
  margin-bottom: 35px;
  padding: 1em 0;
  text-align: center;
}
div.help-popover-backdrop > help-popover div.helpPosIndicatorWrap > div.helpPosIndicator {
  display: inline-block;
  position: relative;
}
div.help-popover-backdrop > help-popover div.helpPosIndicatorWrap > div.helpPosIndicator > div.step {
  display: inline-block;
  margin-right: 0.5em;
  border: 1px solid #8c8c8c;
  border-radius: 50%;
  background-color: #fff;
}
div.help-popover-backdrop > help-popover div.helpPosIndicatorWrap > div.helpPosIndicator > div.step:last-child {
  margin-right: 0;
}
div.help-popover-backdrop > help-popover div.helpPosIndicatorWrap > div.helpPosIndicator > div.step > div {
  width: 1em;
  height: 1em;
  border: 1px solid #fff;
  border-radius: inherit;
  cursor: pointer;
}
div.help-popover-backdrop > help-popover div.helpPosIndicatorWrap > div.helpPosIndicator > div.step > div.current {
  background-color: #AFCC48;
  cursor: default;
}
div.help-popover-backdrop > help-popover div.helpPosIndicatorWrap > div.helpPosIndicator > div.line {
  position: absolute;
  z-index: -1;
  top: 47%;
  width: 100%;
  height: 2px;
  background-color: #cccccc;
}
div.help-popover-backdrop > help-popover > .navigation {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 35px;
  border-top: 1px solid #8c8c8c;
  background-color: #e5e5e5;
  line-height: 35px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
div.help-popover-backdrop > help-popover > .navigation > button {
  position: absolute;
  bottom: 0;
  height: 100%;
}
div.help-popover-backdrop > help-popover > .navigation > button:hover {
  border-bottom: none;
}
div.help-popover-backdrop > help-popover > .navigation > button.prev {
  left: 0;
  border-width: 0 1px 0 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
div.help-popover-backdrop > help-popover > .navigation > button.prev:hover {
  border-width: 0 1px 0 0;
}
div.help-popover-backdrop > help-popover > .navigation > button.next {
  right: 0;
  border-width: 0 0 0 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
div.help-popover-backdrop > help-popover > .navigation > button.next:hover {
  border-width: 0 0 0 1px;
}
div.help-popover-backdrop.from-modal > help-popover.initial {
  top: 20%;
}
div.help-popover-storage {
  display: none;
}
