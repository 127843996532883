.crash-reports-page {
  margin-top: 10px;
  padding: 0 30px 30px;
}
.crash-reports-page .page-section-header {
  margin: 20px 0 10px;
}
.crash-reports-page .page-section {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  margin: 30px 0;
  padding: 30px;
  background: white;
}
.crash-reports-page .page-section .page-section-header:first-child {
  margin-top: 0;
}
.crash-reports-page .grid .header-table-cell.grid-field-url,
.crash-reports-page .grid .body-table-cell.grid-cell-url {
  width: 100px;
  min-width: 100px;
  text-align: center;
}
.crash-reports-page .grid .stack-trace {
  padding: 20px;
  overflow: hidden;
  border: #666;
  background: #ccc;
  font-size: 12px;
}
