.controller-site-selector {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 8rem;
  min-width: 5.5rem;
  height: 40px;
  margin: 0 15px 0 0;
}
.controller-site-selector__collection-dropdown {
  border: none;
  background: none;
  color: #ccc;
  width: inherit;
}
.controller-site-selector__collection-dropdown :hover {
  color: #fff;
}
.controller-site-selector__collection-dropdown .dropdown-container {
  margin: 5px 0 0 0;
}
.controller-site-selector__collection-dropdown .dropdown-container .choice .placeholder:hover {
  color: #fff;
}
.controller-site-selector__collection-dropdown .dropdown-container .dropdown-value-container {
  min-width: 5.5rem;
  max-width: 8rem;
  padding: 0 0 5px 15px;
}
.controller-site-selector__collection-dropdown .dropdown-container .expand {
  margin: 0 0 5px 0;
}
