.serviceengine-config .c-grid-table-header-cell--field-name--vrf_ref,
.serviceengine-config .c-grid-table-header-cell--field-name--network_name,
.serviceengine-config .c-grid-table-header-cell--field-name--mac_address {
  width: 150px;
}
.serviceengine-config .c-grid-table-header-cell--field-name--adapter,
.serviceengine-config .c-grid-table-header-cell--field-name--state {
  width: 100px;
}
.serviceengine-config .c-grid-table-header-cell--field-name--dhcp_enabled {
  width: 120px;
}
.serviceengine-config .c-grid-table-header-cell--field-name--ip_address {
  width: 32%;
}
.serviceengine-config .grid-cell-ip_address {
  flex-direction: row;
  flex-wrap: wrap;
}
.serviceengine-config .grid-cell-ip_address .se-ip-wrapper {
  margin: 5px 0;
  padding-right: 4em;
}
.serviceengine-config .grid-cell-ip_address .se-ip-wrapper .static-ip {
  line-height: 30px;
}
.serviceengine-config .grid-cell-ip_address .se-ip-wrapper .key {
  margin-right: 5px;
}
.serviceengine-config .grid-cell-ip_address .se-ip-wrapper .se-ip-list {
  white-space: normal;
}
.serviceengine-config .c-grid.data-networks .c-grid__table-cell,
.serviceengine-config .c-grid.all-networks .c-grid__table-cell {
  height: auto;
  line-height: normal;
}
