@font-face {
    font-family: 'brandon_text';
    src: url(src/less/fonts/brandontext/BrandonText-Light.82a60861d918b6519dd2090c5d8a3371.woff2) format('woff2'),
        url(src/less/fonts/brandontext/BrandonText-Light.dfde6e155619991337bbc8bfc1f6d21c.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'brandon_text';
    src: url(src/less/fonts/brandontext/BrandonText-Medium.70b578bd403e3a1ce140df95ed1a0e3e.woff2) format('woff2'),
        url(src/less/fonts/brandontext/BrandonText-Medium.819371bd60f342e15b2066bec27643f0.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'brandon_text';
    src: url(src/less/fonts/brandontext/BrandonText-Bold.d59aa475e5c8d4cde88b15c5b3a68ea6.woff2) format('woff2'),
        url(src/less/fonts/brandontext/BrandonText-Bold.5be1efdeb855a3ffa40474f34e9e1f85.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'brandon_text';
    src: url(src/less/fonts/brandontext/BrandonText-Regular.5e6ffbe5d65f68789f793eb89a2c298d.woff2) format('woff2'),
        url(src/less/fonts/brandontext/BrandonText-Regular.fadd85918b8eb65cae21dfac32734256.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}
