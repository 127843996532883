grid-search {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  transition: width 500ms cubic-bezier(0.19, 1, 0.22, 1);
  border: 1px solid #ccc;
  background-color: white;
}
grid-search .grid-search {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
grid-search .grid-search .search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 0;
  padding: 0 4px;
  font-size: 20px;
}
grid-search .grid-search .grid-search-input {
  flex: 1 0 0;
  width: 0;
  max-width: 0;
  height: 33px;
  padding: 0;
  border: 0;
  background: transparent;
}
grid-search.expanded .grid-search-input {
  width: 150px;
  max-width: 150px;
  padding: 0 5px;
}
