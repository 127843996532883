.administration-authentication section.tenant-and-role-mapping-header {
  display: flex;
  align-items: center;
  margin: 30px;
  font-weight: 200;
}
.administration-authentication section.tenant-and-role-mapping-header .left,
.administration-authentication section.tenant-and-role-mapping-header .right {
  display: flex;
  position: relative;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start;
}
.administration-authentication section.tenant-and-role-mapping-header .left {
  font-size: 20px;
}
.administration-authentication section.tenant-and-role-mapping-header .right {
  justify-content: flex-end;
}
.administration-authentication .role-mapping-grid .c-grid__table-cell--field-name--authorization,
.administration-authentication .role-mapping-grid .c-grid__table-cell--field-name--assignment {
  height: auto;
  line-height: normal;
}
.auth-mapping-rule-assignment {
  width: 100%;
  margin: 10px 0;
}
.auth-mapping-rule-assignment__column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.auth-mapping-rule-assignment__column--full {
  grid-column-end: -1;
  grid-column-start: 1;
}
.auth-mapping-rule-assignment__column--split {
  grid-column: span 6;
}
.auth-mapping-rule-assignment__column--split.grid-container {
  grid-template-columns: repeat(6, 1fr);
}
