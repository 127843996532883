.sparkline-popup {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  display: block;
  position: absolute;
  box-sizing: border-box;
  padding: 10px;
  background-color: #EBEBEB;
  pointer-events: none;
}
.sparkline-popup:before {
  content: '▲';
  position: absolute;
  top: -9px;
  left: 75px;
  transform: scaleX(1.5);
  color: #ebebeb;
  font-size: 12px;
}
cell-sparkline .cell-sparkline {
  display: inline-block;
  position: relative;
  text-align: left;
}
cell-sparkline .cell-sparkline .cell-sparkline-group {
  display: inline-block;
  height: 35px;
  vertical-align: top;
}
cell-sparkline .cell-sparkline .reason {
  height: 15px;
  font-size: 12px;
  line-height: 15px;
}
cell-sparkline .cell-sparkline .currentValueGroup {
  line-height: 35px;
}
cell-sparkline .cell-sparkline .anomaly-value {
  color: #ce4b18;
}
