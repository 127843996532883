.waf-policy-confirm-change-crs-version__checkbox {
  padding-bottom: 20px;
}
.waf-policy-confirm-change-crs-version__change-log-title {
  font-weight: 400;
}
.waf-policy-confirm-change-crs-version__change-log-container {
  display: flex;
  justify-content: center;
  margin: 8px 0;
  padding: 10px;
  background-color: #EEE;
}
.waf-policy-confirm-change-crs-version__change-log-counter {
  flex: 1 0;
}
