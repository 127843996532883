.license-summary {
  padding: 15px;
  background-color: #FFF;
  grid-column-end: -1;
  grid-column-start: 1;
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}
.license-summary__cell {
  grid-column: span 4;
  padding-bottom: 10px;
}
.license-summary__cell.grid-container {
  grid-template-columns: repeat(4, 1fr);
}
.license-summary__cell--header {
  font-size: 15px;
  font-weight: 700;
}
.license-summary__cell__entry--greyed-out {
  color: #cccccc;
}
.license-summary .control-group {
  margin-bottom: 0;
}
.license-summary .control-group--vertical-gap {
  margin-bottom: 15px;
}
.license-summary--gap--none {
  grid-column-gap: 0;
  grid-row-gap: 0;
}
.license-summary--gap--legacy {
  grid-column-gap: 2%;
  grid-row-gap: 15px;
}
