avi-healthscore,
span[avi-healthscore],
.avi-healthscore {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-right: 2px;
  margin-left: 4px;
}
.popup {
  position: absolute;
  z-index: 5;
  text-align: left;
}
.right-section .popup {
  position: fixed;
}
.waf-health-status,
.avi-healthscore-outer {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.waf-health-status {
  position: absolute;
  z-index: 0;
  top: -4px;
  box-sizing: border-box;
  padding: 18px;
  border: 1px solid #49A1C1;
  background-color: white;
}
.waf-shield-icon {
  display: block;
  position: absolute;
  z-index: 2;
  top: -5px;
  right: -3px;
  box-sizing: border-box;
  padding-top: 3px;
  border: 1px solid;
  border-radius: 100%;
  background-color: white;
  color: #49A1C1;
  font-size: 11px;
  line-height: 0;
}
.avi-healthscore-outer {
  color: #2a2a2d;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
}
.avi-healthscore-outer .disabledIcon {
  color: #666;
}
.avi-healthscore-text {
  padding-left: 5px;
  border: solid 1px #888;
  border-bottom-width: 2px;
}
a .healthscore-text-colors {
  color: #666;
}
.healthscore-colors.high {
  transition: all 0.25s linear;
  background-color: #8EAD1F;
  fill: #8EAD1F;
}
.healthscore-colors.high i.icon.icon-exclamation {
  color: #FFF;
}
.healthscore-colors.high:hover,
.healthscore-colors.high.selected {
  background-color: #8ead1f;
  fill: #8ead1f;
}
.healthscore-colors.medium {
  transition: all 0.25s linear;
  background-color: #F3EB16;
  fill: #F3EB16;
}
.healthscore-colors.medium i.icon.icon-exclamation {
  color: #FFF;
}
.healthscore-colors.medium:hover,
.healthscore-colors.medium.selected {
  background-color: #ccc50a;
  fill: #ccc50a;
}
.healthscore-colors.low {
  transition: all 0.25s linear;
  background-color: #FF9900;
  fill: #FF9900;
}
.healthscore-colors.low i.icon.icon-exclamation {
  color: #FFF;
}
.healthscore-colors.low:hover,
.healthscore-colors.low.selected {
  background-color: #cc7a00;
  fill: #cc7a00;
}
.healthscore-colors.unknown,
.healthscore-colors.down,
.healthscore-colors.resources {
  transition: all 0.25s linear;
  background-color: #CC0000;
  fill: #CC0000;
}
.healthscore-colors.unknown i.icon.icon-exclamation,
.healthscore-colors.down i.icon.icon-exclamation,
.healthscore-colors.resources i.icon.icon-exclamation {
  color: #FFF;
}
.healthscore-colors.unknown:hover,
.healthscore-colors.down:hover,
.healthscore-colors.resources:hover,
.healthscore-colors.unknown.selected,
.healthscore-colors.down.selected,
.healthscore-colors.resources.selected {
  background-color: #990000;
  fill: #990000;
}
.healthscore-colors.disabled,
.healthscore-colors.inactive,
.healthscore-colors.creating,
.healthscore-colors.unused,
.healthscore-colors.initializing,
.healthscore-colors.loading {
  transition: all 0.25s linear;
  background-color: #eee;
  fill: #eee;
}
.healthscore-colors.disabled i.icon.icon-exclamation,
.healthscore-colors.inactive i.icon.icon-exclamation,
.healthscore-colors.creating i.icon.icon-exclamation,
.healthscore-colors.unused i.icon.icon-exclamation,
.healthscore-colors.initializing i.icon.icon-exclamation,
.healthscore-colors.loading i.icon.icon-exclamation {
  color: #FFF;
}
.healthscore-colors.disabled:hover,
.healthscore-colors.inactive:hover,
.healthscore-colors.creating:hover,
.healthscore-colors.unused:hover,
.healthscore-colors.initializing:hover,
.healthscore-colors.loading:hover,
.healthscore-colors.disabled.selected,
.healthscore-colors.inactive.selected,
.healthscore-colors.creating.selected,
.healthscore-colors.unused.selected,
.healthscore-colors.initializing.selected,
.healthscore-colors.loading.selected {
  background-color: #ccc;
  fill: #ccc;
}
.healthscore-popup-down {
  padding: 5px;
}
.healthscore-badge {
  border-radius: 3px;
  background-clip: padding-box;
}
.healthscore-sparkline path.line.health_score {
  stroke-width: 2px;
  stroke: #3b8dab;
}
.avi-healthscore-popup {
  width: 350px;
  border: solid 1px #E0E0E0;
  border-radius: 2px;
  background-color: #EBEBEB;
  box-shadow: 0 2px 4px 0 #999;
  font-size: 14px;
  line-height: 16px;
}
.avi-healthscore-popup .insights-link {
  margin-bottom: 20px;
}
.avi-healthscore-popup .avi-healthscore-popup-title {
  padding: 15px 20px 0;
  overflow: hidden;
  color: #2a2a2d;
  font-size: 24px;
  line-height: 30px;
  word-break: break-all;
}
.avi-healthscore-popup .avi-health-score-sparkline-label {
  padding: 10px 10px 0 10px;
  color: #666;
  font-size: 13px;
}
.avi-healthscore-popup .avi-healthscore-popup-sparkline {
  height: 70px;
  margin: 10px;
}
.avi-healthscore-popup .avi-healthscore-popup-sparkline svg.chart {
  margin-bottom: 10px;
  overflow: visible;
}
.avi-healthscore-popup .avi-healthscore-popup-sparkline svg.chart g {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.avi-healthscore-popup .avi-healthscore-popup-content div.sparkline.high svg path {
  stroke: #8ead1f;
  color: #8ead1f;
}
.avi-healthscore-popup .avi-healthscore-popup-content div.sparkline.high svg path.area {
  fill: #8EAD1F;
}
.avi-healthscore-popup .avi-healthscore-popup-content div.sparkline.medium svg path {
  stroke: #ccc50a;
  color: #ccc50a;
}
.avi-healthscore-popup .avi-healthscore-popup-content div.sparkline.medium svg path.area {
  fill: #F3EB16;
}
.avi-healthscore-popup .avi-healthscore-popup-content div.sparkline.low svg path {
  stroke: #cc7a00;
  color: #cc7a00;
}
.avi-healthscore-popup .avi-healthscore-popup-content div.sparkline.low svg path.area {
  fill: #FF9900;
}
.avi-healthscore-popup .value {
  padding: 5px 10px;
}
.avi-healthscore-popup .partheader {
  width: 115px;
  color: #666;
  font-size: 15px;
  line-height: 20px;
}
.avi-healthscore-popup .partvalue {
  width: 45px;
  line-height: 20px;
  text-align: right;
}
.avi-healthscore-popup .partheader,
.avi-healthscore-popup .partvalue,
.avi-healthscore-popup .bar-container {
  flex: 1 1 auto;
}
.avi-healthscore-popup .partheader .performanceBar,
.avi-healthscore-popup .partvalue .performanceBar,
.avi-healthscore-popup .bar-container .performanceBar {
  width: 100%;
  background: #EBEBEB;
}
.avi-healthscore-popup .partheader .hsLine,
.avi-healthscore-popup .partvalue .hsLine,
.avi-healthscore-popup .bar-container .hsLine {
  position: absolute;
  top: -136px;
  width: 0;
  height: 146px;
  border-left: 1px dashed #2a2a2d;
}
.avi-healthscore-popup .bar {
  box-sizing: border-box;
}
.avi-healthscore-popup .subtractive.bar {
  border: 1px solid #990000;
  border-bottom-width: 2px;
  background-color: #CC0000;
}
.avi-healthscore-popup .subtractive.partvalue {
  color: #CC0000;
}
.avi-healthscore-popup .bar-container {
  width: 70px;
  height: 10px;
  margin-left: 5px;
}
.avi-healthscore-popup .bar-container div {
  height: 100%;
}
.avi-healthscore-popup div.sparkline {
  height: 70px;
}
.health-icons .downIcon,
.health-icons .upIcon {
  color: white;
}
.health-icons .disabledIcon {
  color: black;
}
.avi-healthscore-popup-content {
  margin: 15px;
  background-color: white;
  white-space: normal;
}
div.aviPopover.healthScoreCard {
  z-index: 1001;
}
div.aviPopoverCarat.healthScoreCard {
  z-index: 1002;
}
div.aviPopoverCarat.healthScoreCard.top:before {
  top: 1px;
  border-top-color: #bfbfbf;
}
div.aviPopoverCarat.healthScoreCard.top:after {
  border-top-color: #ebebeb;
}
div.aviPopoverCarat.healthScoreCard.bottom:before {
  top: -1px;
  border-bottom-color: #bfbfbf;
}
div.aviPopoverCarat.healthScoreCard.bottom:after {
  border-bottom-color: #ebebeb;
}
div.aviPopoverCarat.healthScoreCard.right:before {
  left: -1px;
  border-right-color: #bfbfbf;
}
div.aviPopoverCarat.healthScoreCard.right:after {
  border-right-color: #ebebeb;
}
div.aviPopoverCarat.healthScoreCard.left:before {
  left: 1px;
  border-left-color: #bfbfbf;
}
div.aviPopoverCarat.healthScoreCard.left:after {
  border-left-color: #ebebeb;
}
