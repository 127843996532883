.url-top-list-styling .original-url {
  font-size: 16px;
}
.url-top-list-styling .original-url:hover {
  text-decoration: underline;
}
.url-top-list-styling .url-top-list {
  display: table;
  width: 100%;
  table-layout: auto;
}
.url-top-list-styling .url-top-list .list-item.url-list-item {
  display: table-row;
  position: relative;
  vertical-align: middle;
}
.url-top-list-styling .url-top-list .title-section,
.url-top-list-styling .url-top-list .stats,
.url-top-list-styling .url-top-list .sideways-stacking-bar-chart-container {
  display: table-cell;
  padding: 5px;
  vertical-align: middle;
}
.url-top-list-styling .url-top-list .title-section,
.url-top-list-styling .url-top-list .stats {
  width: 1px;
  white-space: nowrap;
}
.url-top-list-styling .url-top-list .title-section {
  overflow: hidden;
  text-overflow: ellipsis;
}
.url-top-list-styling .url-top-list .stats {
  text-align: right;
}
.url-top-list-styling .oneline-bar-chart {
  height: 30px;
}
.url-top-list-styling .top-list-timing-section {
  display: block;
  position: relative;
  margin: 0;
  overflow: hidden;
}
