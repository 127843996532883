.waf-policy-whitelist-rule-expander-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: white;
}
.waf-policy-whitelist-rule-expander-content__cell {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 3fr 7fr;
  padding: 8px;
  border-bottom: 1px solid #e5e5e5;
  background: white;
}
.waf-policy-whitelist-rule-expander-content__cell--header {
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  background: white;
  font-size: 0.625rem;
  font-weight: 600;
}
.waf-policy-whitelist-rule-expander-content__cell--match-header {
  border-right: 1px solid #e5e5e5;
}
.waf-policy-whitelist-rule-expander-content__cell--match {
  grid-column-start: 1;
  border-right: 1px solid #e5e5e5;
}
.waf-policy-whitelist-rule-expander-content__cell--match:last-child {
  border-bottom: 0;
}
.waf-policy-whitelist-rule-expander-content__cell--action {
  grid-column-start: 2;
  grid-row-start: 2;
}
.waf-policy-whitelist-rule-expander-content__cell--no-border-bottom {
  border-bottom: 0;
}
.waf-policy-whitelist-rule-expander-content__cell-label {
  font-weight: 600;
  justify-self: right;
}
