.main-menu-list {
  display: none;
  position: fixed;
  z-index: 1001;
  top: 40px;
  width: 242px;
  background-color: white;
  box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.15);
  color: #666;
  font-size: 24px;
}
.main-menu-list:before {
  content: '▲';
  position: absolute;
  top: -13px;
  left: 29px;
  transform: scaleX(1.5);
  color: white;
  font-size: 16px;
}
.main-menu-list--open {
  display: block;
}
