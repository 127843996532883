slider {
  display: inline-block;
  -webkit-touch-callout: none;
  user-select: none;
}
slider .scale {
  height: 5px;
  margin-top: 15px;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: #e5e5e5;
}
slider .scale .position {
  width: 100px;
  height: 5px;
  float: left;
  border-radius: 3px;
  background-color: #3b8dab;
}
slider .scale .slider {
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  width: 15px;
  height: 35px;
  margin-top: -15px;
  margin-left: -7px;
  float: left;
  border: 1px solid #3b8dab;
  border-bottom-width: 2px;
  border-radius: 3px;
  background-color: #67B0CB;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  line-height: 35px;
  cursor: default;
}
slider .scale .slider i [class^="icon-"]:before,
slider .scale .slider [class*=" icon-"]:before {
  margin: 0;
  color: #3b8dab;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}
slider .scale .points {
  position: absolute;
  height: 5px;
}
slider .scale .points .point {
  position: absolute;
  width: 60px;
  margin-left: -30px;
  float: left;
  font-size: 12px;
  text-align: center;
  white-space: initial;
}
slider .scale .points .point .pointer {
  width: 50%;
  height: 20px;
  border-right: 1px solid #CCC;
}
slider .scale .points .point label {
  width: 100%;
}
slider .trigger {
  position: relative;
  top: -16px;
  height: 52px;
  margin-top: -5px;
  cursor: pointer;
}
*.unselectable {
  user-select: none;
}
