.user-card__wrapper {
  min-height: 105px;
  padding: 10px 0 0 10px;
}
.user-card__item {
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-card__last-login {
  padding-top: 10px;
  color: #ccc;
}
.user-card__username {
  font-size: 15px;
  font-weight: bold;
}
.user-card__logo {
  padding: 0 5px;
  color: #ff4B00;
  font-size: 40px;
}
.user-card:hover {
  background-color: #fff;
  color: inherit;
  text-decoration: none;
}
