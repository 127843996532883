.waf-log-entry {
  display: block;
  margin-bottom: 2px;
  border: 1px solid #ccc;
}
.waf-log-entry__item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.waf-log-entry__item-header {
  color: #2a2a2d;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
}
.waf-log-entry__item--border-top {
  border-top: 1px solid #ccc;
}
.waf-log-entry__item--no-border-bottom {
  border-bottom: 0;
}
.waf-log-entry__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  font-size: 1rem;
}
.waf-log-entry__content {
  flex: 1;
}
.waf-log-entry__expander {
  display: block;
}
.waf-log-entry:last-child {
  margin-bottom: 0;
}
