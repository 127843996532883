.notification {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  height: 60px;
  animation-name: fadeIn;
  animation-duration: 150ms;
  border-width: 2px;
  border-style: solid;
  border-radius: 3px;
  background-color: white;
  animation-fill-mode: both;
}
.notification__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 10px;
  color: white;
}
.notification__icon {
  font-size: 1.5rem;
}
.notification__message {
  flex: 1;
  align-self: center;
  padding: 10px;
}
.notification__close-button {
  align-self: flex-start;
  margin: 5px;
  font-size: 1.125rem;
  cursor: pointer;
  justify-self: flex-start;
}
.notification--error {
  border-color: #DF221C;
}
.notification--error .notification__icon-container {
  background-color: #DF221C;
}
.notification--success {
  border-color: #C5D648;
}
.notification--success .notification__icon-container {
  background-color: #C5D648;
}
.notification--warning {
  border-color: #F2CC0D;
}
.notification--warning .notification__icon-container {
  background-color: #F2CC0D;
}
.notification--info {
  border-color: #3EACC4;
}
.notification--info .notification__icon-container {
  background-color: #3EACC4;
}
