.credential-verification__notification-container {
  margin-bottom: 10px;
}
.credential-verification__primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.credential-verification__close-button {
  align-self: start;
  margin: 5px 0 5px 5px;
  font-size: 1.3rem;
  cursor: pointer;
  justify-self: end;
}
