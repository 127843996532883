.state-button {
  cursor: pointer;
}
.state-button:hover {
  text-decoration: none;
}
.state-button--main-menu {
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 10px 0 30px;
  color: #666;
  font-size: 18px;
  font-weight: 100;
}
.state-button--main-menu:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #666;
}
.state-button--category {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 10px;
  color: #ccc;
}
.state-button--category--active {
  background-color: #ff4B00;
  color: #fff;
}
.state-button--category:hover {
  color: #fff;
}
