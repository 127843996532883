.waf-policy-psm-location-expander-content {
  display: grid;
  background: white;
}
.waf-policy-psm-location-expander-content__cell {
  display: grid;
  grid-column-gap: 10px;
  grid-column-start: 1;
  grid-template-columns: 2fr 8fr;
  padding: 8px;
  border-bottom: 1px solid #e5e5e5;
  background: white;
}
.waf-policy-psm-location-expander-content__cell-label {
  font-weight: 600;
  justify-self: right;
}
.waf-policy-psm-location-expander-content__header {
  padding: 8px;
  border-top: 2px solid #e5e5e5;
  border-bottom: 2px solid #e5e5e5;
  background: white;
  font-size: 0.625rem;
  font-weight: 600;
}
.waf-policy-psm-location-expander-content__header--argument-rules {
  border-top: 1px solid #e5e5e5;
}
