.repeated-input {
  display: flex;
  margin-bottom: 10px;
}
.repeated-input .buttons {
  flex: 0 0 100px;
  background-color: initial;
}
.repeated-input .buttons.disabled {
  box-shadow: none;
}
.repeated-input .inputs {
  flex: 1 1 auto;
}
.repeated-input .inputs input {
  width: 100%;
}
.repeated-input:last-child {
  margin-bottom: 0;
}
.repeated-input-group {
  display: flex;
}
.repeated-input-group .buttons {
  flex: 0 0 100px;
  height: 35px;
  margin-top: 17px;
}
.repeated-input-group .buttons.disabled {
  box-shadow: none;
}
.repeated-input-group .inputs {
  flex: 1 1 auto;
}
