.avi-virtualservice-security .security-dashboard > * {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  text-align: initial;
}
.avi-virtualservice-security .ddos-section div[chart-with-overlays] {
  display: block;
  height: 460px;
  margin-top: 17px;
}
.avi-virtualservice-security .ddos-section div[chart-with-overlays] .performance-chart {
  border: none;
}
.avi-virtualservice-security .ddos-section div[chart-with-overlays] .performance-chart .graph-title.graph-overlay {
  left: 15px;
}
.avi-virtualservice-security .ddos-section div[chart-with-overlays] .performance-chart-controls.text-left {
  border: none;
  text-align: center;
}
.avi-virtualservice-security .ddos-section div[chart-with-overlays] .performance-chart-controls.text-left li.header {
  display: none;
}
.avi-virtualservice-security .ddos-section div[chart-with-overlays] .chartWithOverlaysGridWrapper > div {
  position: relative;
}
.avi-virtualservice-security .ddos-section div[chart-with-overlays] .chartWithOverlaysGridWrapper > div div a {
  position: relative;
  left: 2px;
}
.avi-virtualservice-security .ddos-section div[chart-with-overlays] .chartWithOverlaysGridWrapper > div a.hideButton {
  position: absolute;
  right: 10px;
}
.avi-virtualservice-security .security-ssl-header {
  padding: 15px 0 20px 15px;
}
.avi-virtualservice-security .security-ddos-header {
  height: 22px;
  padding: 15px;
}
.avi-virtualservice-security .security-ddos-subheader {
  height: 52px;
}
.avi-virtualservice-security .security-ddos-subheader > div {
  display: inline-block;
  box-sizing: border-box;
  width: 45%;
  margin: 2%;
  border-bottom: 1px solid #888;
  text-align: center;
}
@media (max-width: 1152px) {
  .avi-virtualservice-security .security-ddos-subheader > div {
    font-size: 1em;
  }
}
.avi-virtualservice-security div[pie-chart-dashboard] {
  display: inline-block;
  width: 45%;
  height: 210px;
  padding-left: 15px;
}
.avi-virtualservice-security div[pie-chart-dashboard] > div.pie-chart-dashboard {
  height: 90%;
}
.avi-virtualservice-security .security-value {
  height: 81px;
  border-bottom: 1px solid #888;
}
.avi-virtualservice-security .grid .dos-attack {
  background-color: #f4bba4;
}
