.capsule {
  display: flex;
  box-sizing: border-box;
  height: 1.75em;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #fff;
}
.capsule__label,
.capsule__value {
  padding: 3px 4px;
  white-space: nowrap;
}
.capsule__label {
  border-right: 1px solid #cccccc;
  color: #999;
}
.capsule__value {
  max-width: 14em;
  padding-top: 4px;
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
}
