*[infinite-scroll] {
  display: block;
  position: relative;
  line-height: normal;
}
*[infinite-scroll] > div[avi-loader] {
  position: absolute;
  right: 0;
  bottom: 1em;
  left: 0;
  margin: auto;
}
*[infinite-scroll] > div[ng-transclude] {
  display: block;
  position: relative;
  height: 100%;
  overflow: hidden;
}
