sparkline-card,
[sparkline-card] {
  display: block;
  position: relative;
}
sparkline-card.child,
[sparkline-card].child {
  border-left: 10px solid #888;
}
sparkline-card sparkline,
[sparkline-card] sparkline,
sparkline-card [sparkline],
[sparkline-card] [sparkline] {
  display: block;
  position: relative;
}
sparkline-card sparkline div.sparkline-graph,
[sparkline-card] sparkline div.sparkline-graph,
sparkline-card [sparkline] div.sparkline-graph,
[sparkline-card] [sparkline] div.sparkline-graph {
  display: block;
  position: relative;
  width: 100%;
  height: 70px;
}
sparkline-card [avi-loader],
[sparkline-card] [avi-loader] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.sparkline-card-styling,
timeseries-card > div.timeseries-card-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
}
.sparkline-card-styling .sparkline-errors,
timeseries-card > div.timeseries-card-wrapper .sparkline-errors {
  margin-top: 9px;
  white-space: nowrap;
}
.sparkline-card-styling .bad-error,
timeseries-card > div.timeseries-card-wrapper .bad-error {
  color: #ce4b18;
}
.sparkline-card-styling .reason-string,
timeseries-card > div.timeseries-card-wrapper .reason-string {
  width: 100%;
  overflow: hidden;
}
.sparkline-card-styling .regular-reason-string,
timeseries-card > div.timeseries-card-wrapper .regular-reason-string {
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  max-width: 100%;
  margin-top: -3px;
  margin-left: 5px;
}
.sparkline-card-styling .regular-reason-string:hover,
timeseries-card > div.timeseries-card-wrapper .regular-reason-string:hover {
  overflow: visible;
}
.sparkline-card-styling .regular-reason-string:hover,
timeseries-card > div.timeseries-card-wrapper .regular-reason-string:hover {
  overflow: hidden;
}
.sparkline-card-styling .side-card-reason-string,
timeseries-card > div.timeseries-card-wrapper .side-card-reason-string {
  display: none;
  width: 95px;
  float: left;
}
.sparkline-card-styling .sparkline,
timeseries-card > div.timeseries-card-wrapper .sparkline {
  clear: both;
}
.sparkline-card-styling .units,
timeseries-card > div.timeseries-card-wrapper .units {
  line-height: 26px;
}
card-list > div.list.small-cards sparkline-card sparkline,
div.vs-app-map div.right-section-content div.list.small-cards sparkline-card sparkline,
card-list > div.list.small-cards [sparkline-card] sparkline,
div.vs-app-map div.right-section-content div.list.small-cards [sparkline-card] sparkline,
card-list > div.list.small-cards sparkline-card [sparkline],
div.vs-app-map div.right-section-content div.list.small-cards sparkline-card [sparkline],
card-list > div.list.small-cards [sparkline-card] [sparkline],
div.vs-app-map div.right-section-content div.list.small-cards [sparkline-card] [sparkline] {
  display: block;
  position: relative;
}
card-list > div.list.small-cards sparkline-card sparkline div.sparkline-graph,
div.vs-app-map div.right-section-content div.list.small-cards sparkline-card sparkline div.sparkline-graph,
card-list > div.list.small-cards [sparkline-card] sparkline div.sparkline-graph,
div.vs-app-map div.right-section-content div.list.small-cards [sparkline-card] sparkline div.sparkline-graph,
card-list > div.list.small-cards sparkline-card [sparkline] div.sparkline-graph,
div.vs-app-map div.right-section-content div.list.small-cards sparkline-card [sparkline] div.sparkline-graph,
card-list > div.list.small-cards [sparkline-card] [sparkline] div.sparkline-graph,
div.vs-app-map div.right-section-content div.list.small-cards [sparkline-card] [sparkline] div.sparkline-graph {
  display: block;
  position: relative;
  height: 30px;
}
card-list > div.list.small-cards .sparkline-card-styling .units,
div.vs-app-map div.right-section-content div.list.small-cards .sparkline-card-styling .units,
card-list > div.list.small-cards timeseries-card > div.timeseries-card-wrapper .units,
div.vs-app-map div.right-section-content div.list.small-cards timeseries-card > div.timeseries-card-wrapper .units {
  line-height: 16px;
}
card-list > div.list.small-cards .sparkline-card-styling .sparkline-value-container,
div.vs-app-map div.right-section-content div.list.small-cards .sparkline-card-styling .sparkline-value-container,
card-list > div.list.small-cards timeseries-card > div.timeseries-card-wrapper .sparkline-value-container,
div.vs-app-map div.right-section-content div.list.small-cards timeseries-card > div.timeseries-card-wrapper .sparkline-value-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  margin-top: 5px;
  font-size: 13px;
}
card-list > div.list.small-cards .sparkline-card-styling .sparkline-errors,
div.vs-app-map div.right-section-content div.list.small-cards .sparkline-card-styling .sparkline-errors,
card-list > div.list.small-cards timeseries-card > div.timeseries-card-wrapper .sparkline-errors,
div.vs-app-map div.right-section-content div.list.small-cards timeseries-card > div.timeseries-card-wrapper .sparkline-errors {
  width: 95px;
  margin-top: 0;
  margin-left: 10px;
}
card-list > div.list.small-cards .sparkline-card-styling .sparkline,
div.vs-app-map div.right-section-content div.list.small-cards .sparkline-card-styling .sparkline,
card-list > div.list.small-cards timeseries-card > div.timeseries-card-wrapper .sparkline,
div.vs-app-map div.right-section-content div.list.small-cards timeseries-card > div.timeseries-card-wrapper .sparkline {
  clear: none;
}
card-list > div.list.small-cards .sparkline-card-styling .side-card-reason-string,
div.vs-app-map div.right-section-content div.list.small-cards .sparkline-card-styling .side-card-reason-string,
card-list > div.list.small-cards timeseries-card > div.timeseries-card-wrapper .side-card-reason-string,
div.vs-app-map div.right-section-content div.list.small-cards timeseries-card > div.timeseries-card-wrapper .side-card-reason-string {
  display: inline-block;
  height: 28px;
  font-size: 12px;
}
card-list > div.list.small-cards .sparkline-card-styling .regular-reason-string,
div.vs-app-map div.right-section-content div.list.small-cards .sparkline-card-styling .regular-reason-string,
card-list > div.list.small-cards timeseries-card > div.timeseries-card-wrapper .regular-reason-string,
div.vs-app-map div.right-section-content div.list.small-cards timeseries-card > div.timeseries-card-wrapper .regular-reason-string {
  display: none;
}
card-list > div.list.small-cards .sparkline-card-styling .card-values,
div.vs-app-map div.right-section-content div.list.small-cards .sparkline-card-styling .card-values,
card-list > div.list.small-cards timeseries-card > div.timeseries-card-wrapper .card-values,
div.vs-app-map div.right-section-content div.list.small-cards timeseries-card > div.timeseries-card-wrapper .card-values {
  float: none;
}
