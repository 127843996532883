.avi-virtualservice-analytics body {
  margin-bottom: 0;
}
.avi-virtualservice-analytics body .avi-tab-body {
  margin-bottom: 0;
}
.avi-virtualservice-analytics .timing-tiles {
  margin-bottom: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
.chartWithOverlaysGridWrapper {
  position: relative;
}
.chartWithOverlaysGridWrapper .chart-grid-toggle-controls {
  display: block;
  position: relative;
  margin-bottom: 2px;
  padding: 0 2px;
}
.chartWithOverlaysGridWrapper .hideButton {
  position: absolute;
  top: 0;
  right: 2px;
}
