.expandedMode .inner {
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  border-left: 1px solid #CCC;
}
.top-list-styling .ellipsify {
  width: 500px;
  max-width: 500px;
  overflow-x: hidden;
}
.top-list-styling:last-child {
  padding-bottom: 0;
}
.top-list-styling:first-child {
  padding-top: 0;
}
.top-list-styling .list-item {
  line-height: 32px;
}
.top-list-styling .list-item .title {
  color: #49a1c1;
}
.top-list-styling .list-item > span {
  padding-right: 5px;
  padding-left: 5px;
}
.top-list-styling .oneline-bar-chart {
  height: 15px;
}
.top-list-styling .total-time {
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
}
.top-list-styling .value,
.top-list-styling .percent-change-value {
  display: inline-block;
  text-align: right;
}
.top-list-styling .flag {
  margin-top: 10px;
}
.top-list-styling .oneActive {
  color: #AAA;
}
.top-list-styling .oneActive .title {
  color: #AAA;
}
.top-list-styling .oneActive .active {
  background-color: #FFF;
  color: #49a1c1;
}
.top-list-styling .oneActive .active .title {
  color: #49a1c1;
}
.top-list-styling .percentChange.pos {
  color: #839c2b;
}
.top-list-styling .percentChange.neg {
  color: #ce4b18;
}
