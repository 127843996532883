.waf-policy-psm-rule-expander-content {
  display: grid;
  border-top: 2px solid #E5E5E5;
  background: white;
}
.waf-policy-psm-rule-expander-content__cell {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 2fr 8fr;
  padding: 8px;
  border-bottom: 1px solid #e5e5e5;
  background: white;
}
.waf-policy-psm-rule-expander-content__cell:last-child {
  border-bottom: 0;
}
.waf-policy-psm-rule-expander-content__cell-label {
  font-weight: 600;
  justify-self: right;
}
.waf-policy-psm-rule-expander-content__cell-value {
  padding-bottom: 8px;
}
.waf-policy-psm-rule-expander-content__cell-value:last-child {
  padding-bottom: 0;
}
