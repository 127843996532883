vs-security-ddos-top-lists {
  display: block;
  margin: 10px 0 15px 0;
}
vs-security-ddos-top-lists .ddos-table {
  width: inherit;
  text-align: initial;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
vs-security-ddos-top-lists .ddos-table .table-header {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  height: 45px;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}
vs-security-ddos-top-lists .ddos-table .table-header .attacks {
  flex: 0 0 40%;
}
vs-security-ddos-top-lists .ddos-table .table-header .subtable-header {
  flex: 0 0 60%;
  display: flex;
  justify-content: space-between;
}
vs-security-ddos-top-lists .ddos-table .table-header .header {
  box-sizing: border-box;
  padding: 0 20px;
}
vs-security-ddos-top-lists .ddos-table .table-header .header a {
  text-decoration: none;
}
vs-security-ddos-top-lists .ddos-table .table-header .header a.active > h2 {
  color: #ff4B00;
}
vs-security-ddos-top-lists .ddos-table .table-header .header a.sub-active {
  font-weight: 500;
}
vs-security-ddos-top-lists .ddos-table .table-container {
  display: flex;
  box-sizing: border-box;
}
vs-security-ddos-top-lists .ddos-table .table-container .attacks {
  box-sizing: border-box;
  flex: 0 0 40%;
  height: 300px;
  padding: 10px 5px 5px 5px;
  border-right: 1px solid #ccc;
  background-color: #fff;
}
vs-security-ddos-top-lists .ddos-table .table-container .attacks.active {
  background-color: white;
}
vs-security-ddos-top-lists .ddos-table .table-container .subtable {
  box-sizing: border-box;
  flex: 0 0 60%;
  height: 300px;
  padding: 10px 5px 5px 5px;
  background-color: white;
  background-color: #fff;
}
vs-security-ddos-top-lists .ddos-table .table-container .subtable.active {
  background-color: white;
}
vs-security-ddos-top-lists .ddos-table .body-table-wrapper {
  max-height: 230px;
  overflow-y: auto;
}
vs-security-ddos-top-lists .ddos-grid tr.header-table-row th.grid-field-ip {
  width: 130px;
}
vs-security-ddos-top-lists .ddos-grid tr.header-table-row th.grid-field-rate {
  width: 100px;
}
vs-security-ddos-top-lists .ddos-grid tr.header-table-row th.grid-field-duration {
  width: 120px;
}
vs-security-ddos-top-lists .ddos-grid tr.header-table-row th.header-table-cell.rowactions {
  width: 60px;
}
