.c-grid-table-header-cell-resize-handle {
  z-index: 1;
  flex: 0 0 2px;
  height: 25px;
  margin-right: -1px;
  border-right: 1px solid #ccc;
  cursor: col-resize;
}
.c-grid-table-header-cell-sort-icon {
  flex: 0 0 20px;
  height: 25px;
  line-height: 25px;
}
.c-grid-table-header-cell {
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex: 1 1 auto;
  align-items: center;
  height: 45px;
  padding-left: 10px;
  white-space: nowrap;
}
.c-grid-table-header-cell--sortable {
  cursor: pointer;
}
.c-grid-table-header-cell--field-name--select-checkbox {
  width: 60px;
  max-width: 60px;
}
.c-grid-table-header-cell--field-name--single-action.c-grid-table-header-cell--w--6 {
  width: 200px;
  max-width: 200px;
}
.c-grid-table-header-cell--field-name--single-action.c-grid-table-header-cell--w--5 {
  width: 170px;
  max-width: 170px;
}
.c-grid-table-header-cell--field-name--single-action.c-grid-table-header-cell--w--4 {
  width: 140px;
  max-width: 140px;
}
.c-grid-table-header-cell--field-name--single-action.c-grid-table-header-cell--w--3 {
  width: 110px;
  max-width: 110px;
}
.c-grid-table-header-cell--field-name--single-action.c-grid-table-header-cell--w--2 {
  width: 80px;
  max-width: 80px;
}
.c-grid-table-header-cell--field-name--single-action.c-grid-table-header-cell--w--1 {
  width: 50px;
  max-width: 50px;
}
.c-grid-table-header-cell__content-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
}
.c-grid-table-header-checkbox__popover.aviPopover {
  z-index: 10199;
  padding: 0 1em;
  border: 1px solid #ccc;
  line-height: 2;
}
.c-grid-table-header-checkbox__popover.aviPopover a {
  display: block;
  text-decoration: none;
}
.c-grid-table-header {
  display: block;
  height: 45px;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
}
.c-grid-table-header__wrapper {
  display: flex;
  overflow: hidden;
}
/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
.c-grid-table-row-expander {
  position: relative;
  box-sizing: border-box;
  flex: 1 1 auto;
  min-height: 55px;
  padding: 10px;
  overflow: hidden;
  border-top: 1px solid #EBEBEB;
  background-color: white;
  cursor: default;
}
.c-grid-table-row-action-reordering__icon--hidden {
  visibility: hidden;
}
.c-grid-table-row-actions {
  color: #999;
  text-align: right;
  user-select: none;
}
.c-grid-table-row-actions__transclude-wrapper {
  display: inline-block;
  overflow: hidden;
}
.c-grid-table-row-actions__action {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}
.c-grid {
  display: flex;
  color: black;
  font-size: 15px;
}
.c-grid__wrapper {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}
.c-grid__multi-actions {
  display: flex;
  position: relative;
  flex: 0 0 auto;
  align-items: center;
  margin-right: 10px;
}
.c-grid__mutli-action:not(:last-child),
.c-grid__multi-action-button:not(:last-child) {
  margin-right: 5px;
}
.c-grid__controls {
  display: flex;
  position: relative;
  flex: 0 0 50px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2px;
}
.c-grid__rows-count {
  flex: 0 0 27.5px;
  padding-left: 10px;
  font-size: 0.8em;
}
.c-grid__table-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  margin: 2px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.c-grid__table-wrapper .c-grid-table-header {
  flex: 1 0 auto;
}
.c-grid__table-body {
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
  font-size: 15px;
}
.c-grid__table-body-row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #EBEBEB;
}
.c-grid__table-body-row--selected,
.c-grid__table-body-row--dragged {
  background-color: rgba(142, 173, 30, 0.1);
}
.c-grid__table-body-row--expanded {
  flex-wrap: wrap;
  background-color: rgba(142, 173, 30, 0.1);
}
/*div.grid-debug {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0; right: 0;
    opacity: 0.5;
    height: 4px;
    background-color: cyan;

    &.top {
        background-color: magenta;
    }
}*/
.c-grid__table-cell {
  position: relative;
  box-sizing: border-box;
  height: 55px;
  padding: 0 10px;
  overflow: hidden;
  line-height: 55px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.c-grid__table-cell--w--full {
  flex: 1 1 auto;
}
.c-grid__table-cell a {
  color: inherit;
}
