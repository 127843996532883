.tooltip2 {
  display: block;
  position: absolute;
  z-index: 10101;
  box-sizing: border-box;
  border: 1px solid #888;
  background: #fff;
  pointer-events: none;
}
.tooltip2-text {
  padding: 10px;
  overflow-wrap: break-word;
}
.tooltip2-title {
  padding: 5px 10px;
  background: #888;
  color: #fff;
}
