.unit-info--virtualservice {
  display: inline-block;
  position: relative;
  min-width: 500px;
}
.unit-info--virtualservice__header {
  display: flex;
  justify-content: center;
  flex: 1 1;
  align-items: center;
  justify-content: flex-start;
}
.unit-info--virtualservice__label {
  display: inline-block;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unit-info--virtualservice__label--popup-opened {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  margin-left: -10px;
  padding: 10px;
  background-color: white;
}
.unit-info--virtualservice__name {
  display: inline-block;
  max-width: 500px;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unit-info--virtualservice__scale-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.unit-info--virtualservice__scale-buttons .avi-btn:not(:last-child) {
  margin-right: 3px;
}
.unit-info-table {
  margin: 0 10px;
  padding: 5px 0;
}
.unit-info-table__tr,
.unit-info-table__td {
  overflow: hidden;
  word-break: normal;
  white-space: normal;
}
.unit-info-table__tr {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-start;
}
.unit-info-table__header {
  flex: 1 1 0;
  flex: 1 1;
  color: #AAA;
  font-weight: 500;
}
.unit-info-table__sub-header {
  margin-left: 5px;
}
.unit-info-table__td {
  flex: 1 1;
  align-self: flex-start;
}
.unit-info-table__td--max-height {
  max-height: 80px;
  overflow: auto;
}
.unit-info-table__td-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
