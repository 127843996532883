.stacked-end-to-end {
  color: white;
}
.stacked-end-to-end .timing-container {
  position: relative;
  z-index: 1;
}
.stacked-end-to-end .bar {
  height: 35px;
  line-height: 35px;
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
.stacked-end-to-end .bar .title-bar {
  padding: 0 10px 0 2px;
}
.stacked-end-to-end .drilldown-portion {
  position: relative;
  transition: width 1.5s ease;
}
.stacked-end-to-end .drilldown-transitions {
  position: relative;
  transition: top 1.5s ease, opacity 1.5s ease;
}
.stacked-end-to-end .visible-drilldown {
  z-index: 0;
  top: 0;
  opacity: 1;
}
.stacked-end-to-end .hidden-drilldown {
  z-index: -1;
  top: -30px;
  opacity: 0;
}
.stacked-end-to-end .section-title {
  color: #777;
}
.stacked-end-to-end .sideways-stacking-bar-chart {
  z-index: 3;
}
