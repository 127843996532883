div.logCalloutCarrat {
  display: none;
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
}
div.logCalloutCarrat:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #888;
}
div.logCalloutCarrat:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  left: -1px;
  border-left-color: #fff;
}
div.logCalloutCarrat.left:before,
div.logCalloutCarrat.left:after {
  border-left-color: transparent;
}
div.logCalloutCarrat.left:before {
  border-right-color: #999;
}
div.logCalloutCarrat.left:after {
  left: 1px;
  border-right-color: #fff;
}
div.e2e-timings {
  height: 50px;
  padding: 1em 0 0.5em 0;
}
div.e2e-timings > div {
  margin-left: -0.5ex;
}
div.e2e-timings > .c_rtt {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 18%;
  padding-left: 2em;
  text-align: center;
}
div.e2e-timings > .c_rtt > .caption {
  position: absolute;
  bottom: -1.85em;
  left: 0;
  width: 100%;
}
div.e2e-timings > .c_rtt > .caption > .details {
  padding-left: 2em;
}
div.e2e-timings > .c_rtt > .caption > .name {
  position: absolute;
  top: 0;
  left: 0;
  color: #414666;
  font-size: 1.1em;
  font-weight: bold;
}
div.e2e-timings > .c_rtt > .caption > .name.right {
  right: 0;
  left: auto;
}
div.e2e-timings > .c_rtt:before,
div.e2e-timings > .c_rtt:after {
  position: absolute;
  top: 0.2em;
  color: #999;
  font-family: avi, serif;
  font-size: x-large;
}
div.e2e-timings > .c_rtt:before {
  left: 0.15em;
}
div.e2e-timings > .c_rtt:after {
  right: 0.15em;
}
div.e2e-timings > .c_rtt a.active {
  color: inherit;
}
div.e2e-timings > .c_rtt a.active:hover {
  text-decoration: none;
  cursor: default;
}
div.e2e-timings > .c_rtt:before {
  content: '\e827';
}
div.e2e-timings > .s_rtt {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 18%;
  padding-left: 2em;
  text-align: center;
}
div.e2e-timings > .s_rtt > .caption {
  position: absolute;
  bottom: -1.85em;
  left: 0;
  width: 100%;
}
div.e2e-timings > .s_rtt > .caption > .details {
  padding-left: 2em;
}
div.e2e-timings > .s_rtt > .caption > .name {
  position: absolute;
  top: 0;
  left: 0;
  color: #414666;
  font-size: 1.1em;
  font-weight: bold;
}
div.e2e-timings > .s_rtt > .caption > .name.right {
  right: 0;
  left: auto;
}
div.e2e-timings > .s_rtt:before,
div.e2e-timings > .s_rtt:after {
  position: absolute;
  top: 0.2em;
  color: #999;
  font-family: avi, serif;
  font-size: x-large;
}
div.e2e-timings > .s_rtt:before {
  left: 0.15em;
}
div.e2e-timings > .s_rtt:after {
  right: 0.15em;
}
div.e2e-timings > .s_rtt a.active {
  color: inherit;
}
div.e2e-timings > .s_rtt a.active:hover {
  text-decoration: none;
  cursor: default;
}
div.e2e-timings > .s_rtt:before {
  content: '\e826';
}
div.e2e-timings > .app_resp {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 18%;
  padding-left: 2em;
  text-align: center;
  width: 27%;
  padding: 0 2em;
}
div.e2e-timings > .app_resp > .caption {
  position: absolute;
  bottom: -1.85em;
  left: 0;
  width: 100%;
}
div.e2e-timings > .app_resp > .caption > .details {
  padding-left: 2em;
}
div.e2e-timings > .app_resp > .caption > .name {
  position: absolute;
  top: 0;
  left: 0;
  color: #414666;
  font-size: 1.1em;
  font-weight: bold;
}
div.e2e-timings > .app_resp > .caption > .name.right {
  right: 0;
  left: auto;
}
div.e2e-timings > .app_resp:before,
div.e2e-timings > .app_resp:after {
  position: absolute;
  top: 0.2em;
  color: #999;
  font-family: avi, serif;
  font-size: x-large;
}
div.e2e-timings > .app_resp:before {
  left: 0.15em;
}
div.e2e-timings > .app_resp:after {
  right: 0.15em;
}
div.e2e-timings > .app_resp a.active {
  color: inherit;
}
div.e2e-timings > .app_resp a.active:hover {
  text-decoration: none;
  cursor: default;
}
div.e2e-timings > .app_resp > .caption > .details {
  padding: 0 2em;
}
div.e2e-timings > .app_resp:before {
  content: '\e825';
}
div.e2e-timings > .app_resp:after {
  content: '\e824';
}
div.e2e-timings > .data_tr {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 18%;
  padding-left: 2em;
  text-align: center;
  width: 17%;
  padding-left: 0 !important;
}
div.e2e-timings > .data_tr > .caption {
  position: absolute;
  bottom: -1.85em;
  left: 0;
  width: 100%;
}
div.e2e-timings > .data_tr > .caption > .details {
  padding-left: 2em;
}
div.e2e-timings > .data_tr > .caption > .name {
  position: absolute;
  top: 0;
  left: 0;
  color: #414666;
  font-size: 1.1em;
  font-weight: bold;
}
div.e2e-timings > .data_tr > .caption > .name.right {
  right: 0;
  left: auto;
}
div.e2e-timings > .data_tr:before,
div.e2e-timings > .data_tr:after {
  position: absolute;
  top: 0.2em;
  color: #999;
  font-family: avi, serif;
  font-size: x-large;
}
div.e2e-timings > .data_tr:before {
  left: 0.15em;
}
div.e2e-timings > .data_tr:after {
  right: 0.15em;
}
div.e2e-timings > .data_tr a.active {
  color: inherit;
}
div.e2e-timings > .data_tr a.active:hover {
  text-decoration: none;
  cursor: default;
}
div.e2e-timings > .data_tr:before {
  content: '';
}
div.e2e-timings > .total {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 18%;
  padding-left: 2em;
  text-align: center;
  width: 15%;
  font-weight: bold;
}
div.e2e-timings > .total > .caption {
  position: absolute;
  bottom: -1.85em;
  left: 0;
  width: 100%;
}
div.e2e-timings > .total > .caption > .details {
  padding-left: 2em;
}
div.e2e-timings > .total > .caption > .name {
  position: absolute;
  top: 0;
  left: 0;
  color: #414666;
  font-size: 1.1em;
  font-weight: bold;
}
div.e2e-timings > .total > .caption > .name.right {
  right: 0;
  left: auto;
}
div.e2e-timings > .total:before,
div.e2e-timings > .total:after {
  position: absolute;
  top: 0.2em;
  color: #999;
  font-family: avi, serif;
  font-size: x-large;
}
div.e2e-timings > .total:before {
  left: 0.15em;
}
div.e2e-timings > .total:after {
  right: 0.15em;
}
div.e2e-timings > .total a.active {
  color: inherit;
}
div.e2e-timings > .total a.active:hover {
  text-decoration: none;
  cursor: default;
}
div.e2e-timings > .total:before {
  content: '=';
}
div.e2e-timings > .total > span.number {
  color: #839c2b;
  font-size: larger;
}
div.e2e-timings > .l_bracket,
div.e2e-timings > .r_bracket {
  display: inline-block;
  position: relative;
  top: -0.55em;
  width: 0.75em;
  height: 3.5em;
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
}
div.e2e-timings > .l_bracket {
  border-left: 1px solid #888;
}
div.e2e-timings > .r_bracket {
  border-right: 1px solid #888;
}
div.e2e-timings.layer4 > .c_rtt,
div.e2e-timings.layer4 > .s_rtt,
div.e2e-timings.layer4 > .total {
  width: 33%;
}
div.e2e-timings.layer4 > .s_rtt {
  padding: 0 3.25em 0 1.5em;
}
div.e2e-timings.layer4 > .s_rtt:after {
  content: '\e824';
  right: 1em;
}
div.e2e-timings.layer4 > .total {
  padding-left: 2.5em;
}
div.e2e-timings.layer4 > .total:before {
  content: '';
}
body > div.aviPopover.event-list-sidebar-popover {
  z-index: 1000;
  width: 600px;
  min-height: 130px;
  max-height: 80%;
  padding: 15px;
  /* in px only as used by JS */
  overflow-y: auto;
  transition: height 0.5s;
  transition: top 1s;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 5px 0 #999;
  /* popover > wrap > content */
}
body > div.aviPopover.event-list-sidebar-popover h2 {
  padding: 0 0 0.3em 0;
}
body > div.aviPopover.event-list-sidebar-popover i.closeButton {
  position: absolute;
  z-index: 2;
  top: 0.35em;
  right: 0.35em;
  cursor: pointer;
}
body > div.aviPopover.event-list-sidebar-popover div.wrap {
  min-height: 100px;
  overflow: hidden;
}
body > div.aviPopover.event-list-sidebar-popover span[log-filter-click],
body > div.aviPopover.event-list-sidebar-popover span.clickable {
  color: #3b8dab;
}
body > div.aviPopover.event-list-sidebar-popover .c-grid-table-header-cell--field-name--value {
  width: 30%;
  word-break: break-all;
}
body > div.aviPopover.event-list-sidebar-popover .c-grid-table-header-cell--field-name--count {
  width: 15%;
}
body > div.aviPopover.event-list-sidebar-popover .c-grid-table-header-cell--field-name--percentage {
  width: 14%;
}
body > div.aviPopover.event-list-sidebar-popover .c-grid-table-header-cell--field-name--percentageBar {
  width: 41%;
}
body > div.aviPopover.event-list-sidebar-popover .c-grid__table-cell div.progress {
  width: 100%;
}
body > div.aviPopoverCarat.event-list-sidebar-popover {
  z-index: 1000;
}
.log-callout {
  z-index: 1000;
  width: 600px;
  min-height: 130px;
  max-height: 80%;
  padding: 15px;
  /* in px only as used by JS */
  overflow-y: auto;
  transition: height 0.5s;
  transition: top 1s;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 1px 5px 0 #999;
  /* popover > wrap > content */
  position: fixed;
  height: 100px;
  min-height: 100px;
  border: 1px solid #999;
  /* animation oh popover hide/show driven by ngAnimate */
  /* callout > div[ng-include] > wrap > content */
}
.log-callout h2 {
  padding: 0 0 0.3em 0;
}
.log-callout i.closeButton {
  position: absolute;
  z-index: 2;
  top: 0.35em;
  right: 0.35em;
  cursor: pointer;
}
.log-callout div.wrap {
  min-height: 100px;
  overflow: hidden;
}
.log-callout span[log-filter-click],
.log-callout span.clickable {
  color: #3b8dab;
}
.log-callout .c-grid-table-header-cell--field-name--value {
  width: 30%;
  word-break: break-all;
}
.log-callout .c-grid-table-header-cell--field-name--count {
  width: 15%;
}
.log-callout .c-grid-table-header-cell--field-name--percentage {
  width: 14%;
}
.log-callout .c-grid-table-header-cell--field-name--percentageBar {
  width: 41%;
}
.log-callout .c-grid__table-cell div.progress {
  width: 100%;
}
.log-callout > div[ng-include] {
  overflow: hidden;
}
.log-callout.callout-show,
.log-callout.callout-hide {
  transition: all ease 0.3s;
}
.log-callout.callout-show {
  opacity: 0;
}
.log-callout.callout-show.callout-show-active {
  opacity: 1;
}
.log-callout.callout-hide {
  opacity: 1;
}
.log-callout.callout-hide.callout-hide-active {
  opacity: 0;
}
.log-callout div.wrap {
  height: 100px;
  transition: height 0.5s ease;
}
.log-callout div.log-popover-header-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  margin-bottom: 0.5em;
  background-color: white;
  text-align: center;
}
.log-callout div.log-popover-header-wrapper div.line {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #999;
}
.log-callout div.log-popover-header {
  display: inline-block;
  padding: 0 1em;
  background-color: white;
  line-height: 2em;
}
.log-callout div.log-popover-header span.bigNumber {
  color: #839c2b;
  font-size: x-large;
}
.log-callout div.log-popover-header span.small {
  color: #8c8c8c;
  font-size: small;
}
.log-callout table.log-popover-table th,
.log-callout table.log-popover-table td {
  vertical-align: middle;
}
.log-callout table.log-popover-table th.name,
.log-callout table.log-popover-table td.name {
  width: 44%;
  word-break: break-all;
}
.log-callout table.log-popover-table th.number,
.log-callout table.log-popover-table td.number {
  width: 12%;
}
.log-callout table.log-popover-table th.percent,
.log-callout table.log-popover-table td.percent {
  width: 13%;
}
.log-callout table.log-popover-table th.progress-chart,
.log-callout table.log-popover-table td.progress-chart {
  width: 31%;
}
.log-callout table.log-popover-table th.progress-chart > div.progress,
.log-callout table.log-popover-table td.progress-chart > div.progress {
  width: 100%;
}
.log-callout table.log-popover-table th {
  padding: 0 5px;
  text-overflow: clip;
}
.log-callout table.log-popover-table th.percent {
  padding: 0 0 0 5px;
  overflow: visible;
  text-overflow: clip;
}
.log-callout table.log-popover-table th.number {
  padding: 0 0 0 5px;
}
.log-callout table.log-popover-table th.progress-chart {
  border-left: none;
}
.log-callout table.log-popover-table tr.subheader {
  border-right: 1px solid;
  border-left: 1px solid;
}
.log-callout table.log-popover-table tr.subheader > td {
  padding: 0.3em 0;
  border-bottom: 1px solid #8c8c8c;
  background-color: #999;
  color: #fff;
  text-align: center;
}
.log-callout table.log-popover-table tr.row > td {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.log-callout table.log-popover-table.uri_path th.name,
.log-callout table.log-popover-table.referrer th.name {
  width: 56%;
}
.log-callout table.log-popover-table.uri_path td.name,
.log-callout table.log-popover-table.referrer td.name {
  padding: 0.5em;
}
.log-callout table.log-popover-table.uri_path th.number,
.log-callout table.log-popover-table.referrer th.number,
.log-callout table.log-popover-table.uri_path td.number,
.log-callout table.log-popover-table.referrer td.number {
  width: 13%;
}
.log-callout table.log-popover-table.uri_path th.percent,
.log-callout table.log-popover-table.referrer th.percent,
.log-callout table.log-popover-table.uri_path td.percent,
.log-callout table.log-popover-table.referrer td.percent {
  width: 11%;
}
.log-callout table.log-popover-table.uri_path th.progress-chart,
.log-callout table.log-popover-table.referrer th.progress-chart,
.log-callout table.log-popover-table.uri_path td.progress-chart,
.log-callout table.log-popover-table.referrer td.progress-chart {
  width: 20%;
}
.log-callout table.log-popover-table.groups th.progress-chart,
.log-callout table.log-popover-table.groups td.progress-chart {
  width: 30%;
  min-width: 150px;
}
.log-callout table.log-popover-table.groups th.checkbox,
.log-callout table.log-popover-table.groups td.checkbox {
  width: 2em;
  padding: 0;
  text-align: center;
}
.log-callout table.log-popover-table.significance tr.row.child > td.value {
  padding-left: 1.5em;
}
.log-callout table.log-popover-table > tbody:nth-child(even) > tr.row:nth-child(even) {
  background-color: #fff;
}
.log-callout table.log-popover-table > tbody:nth-child(even) > tr.row:nth-child(odd) {
  background-color: #e5e5e5;
}
.log-callout table.log-popover-table > tbody:last-child > tr.subheader td {
  border-bottom: none;
}
.log-callout .e2eChart {
  width: 100%;
  height: 150px;
  padding: 1.5em 0;
}
.log-callout .e2eChart svg {
  border: 1px solid #cccccc;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
.log-callout .e2eChart svg .bar {
  stroke: #839c2b;
  fill: #ecf3d4;
}
.log-callout .e2eChart svg .bar:hover {
  stroke: black;
  fill: #d2e298;
}
.log-callout .e2eChart svg .axis path,
.log-callout .e2eChart svg .axis line {
  stroke: #cccccc;
  fill: none;
  shape-rendering: crispEdges;
}
.log-callout .e2eChart svg .axis line {
  stroke: #888;
}
.log-callout .e2eChart svg .axis.x .tick {
  padding-top: 1em;
}
.log-callout .e2eChart svg .axis.y .tick line {
  stroke-width: 1px;
}
.log-callout .e2eChart svg .axis text {
  fill: #888;
  font: 10px sans-serif;
}
.log-callout .log-popup-empty-data-label {
  margin-top: 1em;
  font-weight: bold;
  text-align: center;
}
.log-callout .waf-phase-latency__table {
  width: 100%;
  table-layout: fixed;
}
.log-callout .waf-phase-latency__table tr {
  border-bottom: 1px solid #ccc;
}
.log-callout .waf-phase-latency__table-head {
  text-align: left;
}
.log-callout .waf-phase-latency__table-head th:not(.waf-phase-latency__table-head-phase) {
  padding: 10px;
}
.log-callout .waf-phase-latency__table-head-phase {
  width: 120px;
}
.log-callout .waf-phase-latency__table-body tr td .progress-container {
  display: flex;
}
.log-callout .waf-phase-latency__table-body tr td .progress-container .progress {
  width: 80%;
}
.log-callout .waf-phase-latency__table-body tr td .progress-container .progress .progress-bar {
  box-sizing: border-box;
  border-left-width: 1px;
  border-radius: 10px;
}
.log-callout .waf-phase-latency__table-phase-cell {
  padding: 0 10px;
  overflow: hidden;
  background-color: #49A1C1;
  color: white;
  font-size: 12px;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.log-callout .waf-phase-latency__table-phase-label {
  overflow: hidden;
  word-wrap: normal;
  white-space: nowrap;
}
.log-callout .waf-phase-latency__table-cell {
  padding: 10px;
}
.log-analytics__group-table {
  width: 100%;
  table-layout: fixed;
}
.log-analytics__group-table th,
.log-analytics__group-table td {
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.log-analytics__group-table th {
  font-weight: normal;
  text-transform: uppercase;
}
.log-analytics__group-table tr {
  border-bottom: 1px solid #ccc;
}
.log-analytics__group-table thead {
  font-size: 12px;
}
.log-analytics__group-table-value {
  width: 60px;
  font-weight: bold;
  text-align: right;
}
.log-analytics__group-table-name {
  text-align: left;
}
.log-analytics__group-table-child {
  font-size: 12px;
}
.log-analytics__group-table-child .log-analytics__group-table-name {
  padding-left: 20px;
}
.log-analytics__group-table-controls-col {
  width: 25px;
}
