div.avi-server-analytics collection-grid tr.expanded > td.rowactions > a > i[class='icon-plus']:before {
  content: '\e81e';
}
div.avi-server-analytics .cpu-stats,
div.avi-server-analytics .memory-stats {
  position: relative;
  height: 120px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
div.avi-server-analytics .cpu-stats {
  margin-right: 10px;
}
div.avi-server-analytics .cpu-stats .server,
div.avi-server-analytics .cpu-stats .pool,
div.avi-server-analytics .cpu-stats .host {
  height: 78px;
}
div.avi-server-analytics .cpu-stats .server div.legend,
div.avi-server-analytics .cpu-stats .pool div.legend,
div.avi-server-analytics .cpu-stats .host div.legend {
  position: absolute;
  bottom: 0;
  text-align: center;
}
div.avi-server-analytics .cpu-stats .server div[percent-arc-chart] g.percent-arc.used path {
  fill: #AFCC48;
}
div.avi-server-analytics .memory-stats .server div.legend,
div.avi-server-analytics .memory-stats .pool div.legend,
div.avi-server-analytics .memory-stats .host div.legend {
  margin-top: 5px;
  text-align: center;
}
div.avi-server-analytics .memory-stats .server div[percent-pile-chart] div.bar.full {
  background-color: #AFCC48;
}
div.avi-server-analytics div.top-metrics-block {
  margin-bottom: 1em;
  text-align: right;
}
div.avi-server-analytics div.top-metrics-block > div.stats-summary {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding-right: 2em;
  text-align: center;
}
div.avi-server-analytics div.top-metrics-block > div.hs-monitor {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  max-height: 266px;
  text-align: center;
  border-radius: 3px;
  background-clip: padding-box;
}
div.avi-server-analytics collection-grid .body-table-wrapper {
  max-height: 16em;
  overflow-y: auto;
}
div.avi-server-analytics collection-grid cell i.icon.icon-arrow-up {
  color: #8EAD1F;
}
div.avi-server-analytics collection-grid cell i.icon.icon-arrow-down {
  color: #CC0000;
}
div.avi-server-analytics collection-grid cell i.icon.icon-circle.high {
  color: #8EAD1F;
}
div.avi-server-analytics collection-grid cell i.icon.icon-circle.medium {
  color: #F3EB16;
}
div.avi-server-analytics collection-grid cell i.icon.icon-circle.low {
  color: #FF9900;
}
div.avi-server-analytics collection-grid cell i.icon.icon-circle.down {
  color: #CC0000;
}
div.avi-server-analytics collection-grid cell i.icon.icon-circle.loading {
  color: #e6e6e6;
}
div.avi-server-analytics collection-grid th.grid-field-status {
  width: 8%;
}
div.avi-server-analytics collection-grid th.grid-field-success {
  width: 10%;
}
div.avi-server-analytics collection-grid pre {
  white-space: pre-wrap;
}
