.modal-container-breadcrumbs {
  padding: 0 50px 0 15px;
  overflow: hidden;
  color: #888;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal-container-breadcrumbs__breadcrumb {
  padding-right: 5px;
}
.modal-container-breadcrumbs__breadcrumb:not(:last-child)::after {
  content: '>';
  padding-left: 5px;
}
