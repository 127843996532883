.modal-container__header {
  display: flex;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  flex-direction: column;
  min-height: 85px;
  margin: 0;
  padding: 20px 0;
  border: 0;
  border-bottom: 1px solid #c4c4c4;
  background: transparent;
  color: #444f51;
}
h1.modal-container__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 50px 0 15px;
}
.modal-container__body {
  position: absolute;
  top: 85px;
  bottom: 85px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 20px 50px;
  overflow-y: auto;
}
.modal-container__body--breadcrumbs {
  top: 102px;
}
.modal-wizard-nav {
  box-sizing: border-box;
  height: 50px;
  padding: 0 40px;
  border-bottom: 1px solid #c4c4c4;
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
}
.modal-wizard-nav__tab {
  margin-right: 50px;
  padding: 5px 0;
}
.modal-wizard-nav__link {
  transition: color 0.1s ease-in-out;
  color: inherit;
  font-size: 16px;
  font-weight: inherit;
}
.modal-wizard-nav__link:hover {
  text-decoration: none;
}
.modal-wizard-nav__link--active {
  color: #ff4B00;
}
.modal-wizard-nav__link--active:hover {
  color: #ff4B00;
}
.modal-container--wizard .modal-container__header {
  border: 0;
}
.modal-container--wizard .modal-container__body {
  top: 135px;
}
