.license-upload-panel {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 15px 25px;
  background-color: #FFF;
}
.license-upload-panel__key {
  grid-column: 2 / span 3;
}
.license-upload-panel__or {
  grid-column: 6 / span 1;
  text-align: end;
}
.license-upload-panel__file {
  grid-column: 9 / span 3;
}
.license-upload-panel__label {
  position: relative;
  top: 10px;
  margin-right: 5px;
}
.license-upload-panel__file-upload {
  display: inline-block;
}
