.gslb-service-tree-domains {
  display: inline-block;
  position: relative;
  margin: 0 10%;
  padding: 0 10px;
}
unit-tree {
  display: block;
  position: relative;
}
unit-tree:not(:last-child) .unit-tree-container .unit-tree-heading,
unit-tree:not(:last-child) .unit-tree-container .unit-tree-list {
  border-bottom: 1px solid #ccc;
}
unit-tree .unit-tree-container {
  display: block;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
unit-tree .unit-tree-container .unit-tree-collapse-bar {
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  height: 40px;
  cursor: pointer;
}
unit-tree .unit-tree-container .unit-tree-collapse-button {
  display: inline-block;
  position: absolute;
  z-index: 3;
  top: 25px;
  right: 0;
}
unit-tree .unit-tree-container .unit-tree-heading {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  height: 70px;
  font-size: 15px;
  cursor: pointer;
}
unit-tree .unit-tree-container .unit-tree-heading .vs-name {
  margin-left: 20px;
}
unit-tree .unit-tree-container .unit-tree-list,
unit-tree .unit-tree-container .gslb-service-tree {
  display: block;
  position: relative;
  padding: 40px 0 20px 0;
  overflow: hidden;
  white-space: nowrap;
}
unit-tree .unit-tree-container .unit-tree-list unit-card,
unit-tree .unit-tree-container .gslb-service-tree unit-card {
  max-width: 100%;
}
unit-tree servers-list {
  margin-left: 4em;
}
unit-tree servers-list div.servers {
  display: inline-block;
  vertical-align: middle;
}
unit-tree servers-list ul {
  margin-right: 2em;
}
unit-tree servers-list ul.minimized > li > unit-card {
  margin: 0 0.25em;
}
unit-tree servers-list ul.minimized > li > unit-card:first-child {
  margin-left: 0;
}
unit-tree ul {
  display: inline-block;
  position: relative;
  z-index: 1;
  white-space: nowrap;
}
unit-tree ul > li {
  vertical-align: inherit;
}
unit-tree ul > li > ul {
  margin-left: 4em;
}
unit-tree ul.vs-group {
  display: inline-block;
  position: relative;
  margin-right: 4em;
  vertical-align: top;
}
unit-tree ul.vs-group li:not(:first-child) {
  margin: 20px 0;
}
unit-tree ul.pools > li {
  min-height: 60px;
}
unit-tree ul.pools > li:not(:last-child) {
  margin-bottom: 5px;
}
unit-tree ul.nets > li > div {
  display: inline-block;
  width: 150px;
  vertical-align: inherit;
}
unit-tree ul.nets servers-list {
  margin-left: 2em;
}
unit-tree .unit-tree-list .background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
unit-tree .unit-tree-list .background > svg path {
  stroke-width: 1;
  stroke: #999;
  fill: none;
}
unit-tree .se-list-container {
  display: block;
  position: relative;
  margin-top: 60px;
}
unit-tree .se-list-container .se-list {
  display: inline-block;
  position: relative;
  padding: 10px;
  background-color: #f4f4f4;
}
unit-tree .se-list-container .se-list .se-item {
  display: inline-block;
  position: relative;
  height: 50px;
}
unit-tree .se-list-container .se-list .se-item:not(:last-child) {
  margin-right: 20px;
}
