.user-modal__user-status {
  margin-bottom: 10px;
}
.user-modal__user-status-label {
  font-size: 16px;
  line-height: 25px;
}
.user-modal__user-status-label--bold {
  font-weight: bold;
}
.user-modal__user-status-switch {
  margin-right: 10px;
}
.user-modal__super-user-checkbox {
  margin-right: 5px;
}
