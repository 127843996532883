.log-paginate {
  display: block;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.3em 0;
  background: #DDD;
  line-height: 2em;
  text-align: center;
}
.log-paginate label {
  display: inline-block;
  margin: 0 1.5em;
}
.log-paginate input {
  display: inline-block;
  width: 3.5em;
  height: 1.8em;
  margin-bottom: 0;
  line-height: 1em;
}
