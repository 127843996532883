dns-policy-rule-modal .match-header,
dns-policy-rule-modal .action-header {
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
dns-policy-rule-modal .match-header .icon-cancel,
dns-policy-rule-modal .action-header .icon-cancel {
  border: none;
  background: none;
}
dns-policy-rule-modal div.h-control.match-criteria {
  flex: 0 0 175px;
  margin-right: 15px;
}
