dos-rate-limiter,
dos-rate-limiter-vs {
  display: block;
}
dos-rate-limiter > div > div.list,
dos-rate-limiter-vs > div > div.list {
  padding: 0.5em 0 1em 0;
  border-bottom: 1px solid #e5e5e5;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 0.5em;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.count,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.count {
  width: 20%;
  padding-left: 0;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.period,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.period {
  width: 20%;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.period div.inner-text,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.period div.inner-text {
  display: block;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action {
  width: 55%;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.remove-btn,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.remove-btn {
  width: 4%;
  padding-right: 0;
  text-align: center;
  vertical-align: bottom;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details {
  display: block;
  padding: 0;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details > label,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details > label {
  display: inline-block;
  box-sizing: border-box;
  padding: 0 0.5em;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.redirect,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.redirect,
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.local,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.local {
  display: block;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.redirect label,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.redirect label,
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.local label,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.local label {
  display: inline-block;
  box-sizing: border-box;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.redirect label.status-code,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.redirect label.status-code,
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.local label.status-code,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.local label.status-code {
  width: 20%;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.local,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.local {
  padding-left: 40%;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.local label.file,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.local label.file {
  width: 80%;
  padding-right: 0;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.redirect,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.redirect {
  padding-left: 20%;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.redirect label.status-code,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.redirect label.status-code {
  padding: 0 0.5em;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.redirect label.protocol,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.redirect label.protocol {
  width: 40%;
  padding-top: 20px;
  text-align: center;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.redirect label.port,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.redirect label.port {
  width: 20%;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.redirect label.keep-query,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.redirect label.keep-query {
  width: 20%;
  padding-top: 20px;
  text-align: center;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.redirect label.host,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.redirect label.host {
  width: 25%;
  padding: 0 0.5em;
}
dos-rate-limiter > div > div.list > div.limiter-settings > div.action-details.redirect label.path,
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action-details.redirect label.path {
  width: 75%;
  padding: 0 0.5em;
}
dos-rate-limiter > div > div.type-selection > dropdown,
dos-rate-limiter-vs > div > div.type-selection > dropdown {
  width: 50%;
}
dos-rate-limiter dropdown,
dos-rate-limiter-vs dropdown,
dos-rate-limiter input,
dos-rate-limiter-vs input {
  display: inline-block;
  width: 100%;
}
dos-rate-limiter-vs > div > div.list > div.limiter-settings > div.action {
  width: 60%;
  padding-right: 0;
}
