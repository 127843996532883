.ordered-grid {
  display: block;
  position: relative;
  border: 1px solid #8c8c8c;
  border-bottom: 0;
}
.ordered-grid a:hover {
  text-decoration: none;
}
.ordered-grid__table-wrapper {
  display: block;
  position: relative;
  padding: 0;
  background: white;
}
.ordered-grid__cell {
  display: flex;
  box-sizing: border-box;
  flex-grow: 1;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ordered-grid__cell--header {
  position: relative;
  padding: 0;
}
.ordered-grid__cell--header__title {
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
}
.ordered-grid__cell--body {
  position: relative;
  box-sizing: border-box;
  height: 2.5rem;
  margin: 0;
  padding: 5px;
  font-size: 0.85rem;
  font-weight: 200;
}
.ordered-grid__cell--empty-body {
  justify-content: center;
}
.ordered-grid__cell.expanded {
  height: auto;
  max-height: 500px;
  transition: max-height 0.5s ease-in;
}
.ordered-grid__cell--drag-and-drop {
  flex: 0 0 30px;
}
.ordered-grid__cell--rowactions {
  flex: 0 0 100px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: flex-end;
}
.ordered-grid__cell--rowactions i.icon {
  color: #8c8c8c;
}
.ordered-grid__cell--rowactions i.icon:hover {
  color: black;
}
.ordered-grid__cell--rowactions i.icon-dot-3 {
  font-size: 18px;
  vertical-align: initial;
}
.ordered-grid__header {
  display: flex;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  flex-grow: 1;
  height: 23px;
  overflow: hidden;
  border-bottom: 1px solid #8c8c8c;
  background-color: white;
  color: #8c8c8c;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
}
.ordered-grid__body {
  display: block;
  position: relative;
  color: #4A4A4A;
}
.ordered-grid__body__row {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: flex-start;
  height: 52px;
  padding: 5px 0;
  border-bottom: 1px solid #8c8c8c;
  cursor: pointer;
}
.ordered-grid__body__row:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.ordered-grid__body__row__drag-placeholder {
  background-color: #f3f4f8;
}
.ordered-grid__body__row--expanded {
  position: relative;
  z-index: 200;
  background: white;
}
.ordered-grid__body__row--expanded:hover {
  background-color: initial;
}
.ordered-grid__body__row--expanded-template {
  display: flex;
  justify-content: flex-start;
  height: auto;
  padding: 10px;
  border-top: 0;
  box-shadow: 0 4px 20px -5px rgba(0, 0, 0, 0.4);
}
.ordered-grid__resizer {
  position: absolute;
  z-index: 1;
  top: 10%;
  right: 0;
  width: 5px;
  height: 80%;
  border-right: 1px solid #ccc;
  cursor: col-resize;
}
.create-between {
  display: flex;
  position: absolute;
  z-index: 1000;
  top: -6px;
  right: 0;
  left: 0;
  width: 100%;
  height: 1px;
  padding: 5px 0;
  color: #67B0CB;
  cursor: pointer;
}
.create-between:hover .create-between__line {
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-between__line {
  display: none;
  width: 100%;
  height: 2px;
  background-color: #67B0CB;
}
.create-between__text {
  display: flex;
  padding: 5px;
  border: 1px solid #67B0CB;
  border-radius: 15px;
  background: white;
}
.create-between__icon {
  margin-right: 5px;
}
.create-between--bottom {
  top: initial;
  bottom: -5px;
}
.ordered-grid__cell--enable {
  flex: 0 0 60px;
}
.ordered-grid__cell--index {
  flex: 0 0 40px;
  display: flex;
  justify-content: flex-end;
}
div.avi-menu-list.ordered-grid-menu {
  width: 180px;
  padding: 5px;
  border: 0;
  border-radius: 0;
  background-color: #999;
}
div.avi-menu-list.ordered-grid-menu li.avi-menu-list-item {
  height: 28px;
  padding: 0 10px;
  background-color: #999;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
}
div.avi-menu-list.ordered-grid-menu li.avi-menu-list-item > .icon-wrapper {
  width: 20px;
  margin-right: 5px;
}
div.avi-menu-list.ordered-grid-menu li.avi-menu-list-item:hover {
  background-color: #cccccc;
}
div.avi-menu-list.ordered-grid-menu .create-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #85ba65;
}
div.avi-menu-list.ordered-grid-menu .create-button .plus {
  color: white;
  font-size: 20px;
  font-weight: 100;
  line-height: 20px;
}
.circle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  margin: 3px 0;
  border-radius: 50%;
}
.circle-button .plus {
  color: white;
  font-size: 30px;
  font-weight: 100;
  line-height: 30px;
}
