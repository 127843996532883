ssl-bar-chart {
  display: block;
  width: 100%;
}
ssl-bar-chart .ssl-score {
  width: 50px;
  border-top-width: 0;
  border-radius: 3px;
  stroke-width: 1px;
  color: white;
  line-height: 35px;
  text-align: center;
}
ssl-bar-chart .ssl-score.green {
  background-color: #AFCC48;
  font-size: 1.286em;
}
ssl-bar-chart .ssl-score.orange {
  background-color: #FF9900;
  font-size: 1.286em;
}
ssl-bar-chart .ssl-score.disabled {
  width: 100px;
  background-color: #67B0CB;
}
ssl-bar-chart .chart rect {
  fill: #AFCC48;
}
ssl-bar-chart .chart .name,
ssl-bar-chart .chart .setting {
  text-anchor: end;
  alignment-baseline: hanging;
}
ssl-bar-chart .chart .name {
  fill: #666;
}
ssl-bar-chart .chart .setting {
  fill: #cccccc;
}
ssl-bar-chart .chart .value {
  fill: white;
  font-size: 20px;
  font-weight: 200;
  text-anchor: end;
}
ssl-bar-chart .chart .value[text-position=right] {
  fill: #AFCC48;
  text-anchor: start;
}
ssl-bar-chart .chart g.disabled rect,
ssl-bar-chart .chart g.disabled .value[text-position=right] {
  fill: #cccccc;
}
ssl-bar-chart .chart g.poor rect,
ssl-bar-chart .chart g.poor .value[text-position=right] {
  fill: #ffd699;
}
ssl-bar-chart .chart g.threat rect,
ssl-bar-chart .chart g.threat .name,
ssl-bar-chart .chart g.threat .setting,
ssl-bar-chart .chart g.threat .value[text-position=right] {
  fill: #FF9900;
}
