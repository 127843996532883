table.diff-view {
  width: 100%;
  table-layout: fixed;
  cursor: pointer;
}
table.diff-view thead {
  background-color: #5F6695;
}
table.diff-view thead > tr > th {
  position: relative;
  height: 1.75em;
  padding: 0 1em;
  overflow: hidden;
  border: 0;
  border-top: 1px solid #414666;
  border-bottom: 1px solid #414666;
  border-left: 2px outset #888;
  color: #fff;
  font-weight: normal;
  line-height: 1.75em;
  text-overflow: ellipsis;
  white-space: nowrap;
}
table.diff-view thead > tr > th .icon {
  position: absolute;
  top: 0;
  right: 5px;
  transition: color 0.25s ease-in-out;
  color: #414666;
  line-height: 24px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}
table.diff-view thead > tr > th .icon:hover {
  color: #FFF;
  text-shadow: none;
}
table.diff-view thead > tr > th .icon.icon-sort-asc,
table.diff-view thead > tr > th .icon.icon-sort-desc {
  color: #FFF;
}
table.diff-view thead > tr > th:first-child {
  position: relative;
  border-left: 1px solid #414666;
}
table.diff-view thead > tr > th:first-child .inner {
  border-left: none;
}
table.diff-view thead > tr > th:last-child {
  border-right: 1px solid #414666;
}
table.diff-view thead > tr > th:last-child .inner {
  border-right: none;
}
table.diff-view thead th {
  padding-left: 1em;
}
table.diff-view thead th i[class^='icon'] {
  position: absolute;
  right: 5px;
}
table.diff-view td {
  padding: 0.5em;
  overflow: hidden;
  border: 1px solid grey;
  word-break: break-all;
  vertical-align: top;
  user-select: none;
}
table.diff-view li.nodiff {
  display: none;
}
table.diff-view li.nodiff.show {
  display: list-item;
}
table.diff-view li.new {
  background-color: #ecf3d4;
}
table.diff-view li.deleted {
  background-color: #f4bba4;
}
table.diff-view li.edited {
  background-color: #fcf2d8;
}
table.diff-view li.edited span.value {
  background-color: #EEBB33;
}
table.diff-view li.edited span.placeholder {
  visibility: hidden;
}
table.diff-view li.new li.nodiff,
table.diff-view li.deleted li.nodiff,
table.diff-view li.edited li.nodiff,
table.diff-view li.empty li.nodiff {
  display: inherit;
}
table.diff-view li.empty {
  background-color: #e5e5e5;
  color: #e5e5e5;
}
table.diff-view li.empty ul {
  visibility: hidden;
}
table.diff-view.full-view li.nodiff {
  display: inherit;
}
table.diff-view.no-diff {
  cursor: default;
}
table.diff-view.no-diff th i[class^='icon'] {
  display: none;
}
