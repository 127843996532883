.resource-timing-cell {
  text-align: left;
  vertical-align: middle;
}
.resource-timing-cell > div {
  display: inline-block;
}
.resource-timing-cell .no-load-time {
  height: 20px;
  border-right: 1px solid #CCC;
}
.resource-timing-cell .line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
}
