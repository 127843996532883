/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
/*
 * **************************************************************************
 *
 * AVI CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Avi Networks Incorporated
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Avi Networks Incorporated and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Avi Networks
 * Incorporated, and its suppliers and are covered by U.S. and Foreign
 * Patents, patents in process, and are protected by trade secret or
 * copyright law, and other laws. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written
 * permission is obtained from Avi Networks Incorporated.
 */
.avi-form-label-tooltip {
  font-size: 0.8rem;
  word-break: break-word;
  white-space: normal;
}
.avi-form-label-tooltip__desc:not(:last-child) {
  padding-bottom: 5px;
  border-bottom: 1px solid #cccccc;
}
.avi-form-label-tooltip__header {
  margin: 8px 0 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.avi-form-label-tooltip__header,
.avi-form-label-tooltip__value {
  padding: 0 10px;
}
.avi-form-label__help-icon {
  display: inline-block;
  color: #cccccc;
  cursor: pointer;
}
.avi-form-label__help-icon--active,
.avi-form-label__help-icon:hover {
  color: #3b8dab;
}
.avi-form-label__tooltip.aviPopover,
.avi-form-label__tooltip.aviPopoverCarat {
  z-index: 10199;
}
.avi-form-label__tooltip.aviPopover {
  max-width: 250px;
  padding: 5px 0;
  border: 1px solid #888;
}
