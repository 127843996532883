item-detail-page-header {
  display: flex;
  position: relative;
  flex: 0 0 auto;
  flex-direction: column;
}
item-detail-page-header > subheader {
  display: flex;
  flex-direction: column;
}
item-detail-page-header.page-with-sidebar {
  margin-right: 300px;
}
item-detail-page-header.page-with-sidebar.page-sidebar-collapsed {
  margin-right: 20px;
}
