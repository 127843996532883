.system-update-modal__seg-section {
  margin: 20px 0;
}
.system-update-modal__seg-section-title {
  margin: 20px 0 10px 0;
  font-weight: bold;
  text-transform: uppercase;
}
.system-update-modal__radio-label {
  margin-right: 1em;
  line-height: 20px;
  /* radio button line height */
}
.system-update-modal__check-list {
  margin: 20px 0;
}
.system-update-modal__check-list-leading-words {
  margin-bottom: 10px;
  font-size: 0.95rem;
  font-weight: bold;
}
.system-update-modal__check-list-ending-words {
  margin-top: 10px;
  font-size: 0.95rem;
  font-weight: bold;
}
.system-update-modal__check-list-content {
  margin-top: 5px;
}
.system-update-modal__check-list-item {
  display: flex;
  padding: 6px 0;
}
.system-update-modal__item-icon {
  padding-right: 5px;
  font-size: 1.1rem;
}
.system-update-modal__item-icon--check {
  color: #839c2b;
}
.system-update-modal__item-icon--warning {
  color: #dca512;
}
.system-update-modal__item-icon--error {
  color: #DF221C;
}
.system-update-modal__waiting-section {
  width: 500px;
  height: 150px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.system-update-modal__main-loader {
  margin-bottom: 10px;
  font-size: 2em;
}
.system-update-modal__waiting-words {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}
