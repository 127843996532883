.aviPopover {
  position: fixed;
  box-sizing: border-box;
  transition: opacity 0.4s ease;
  z-index: 100;
  background-color: #fff;
  color: #666;
  border-radius: 3px;
  background-clip: padding-box;
}
.aviPopoverCarat {
  position: fixed;
  box-sizing: border-box;
  transition: opacity 0.4s ease;
  z-index: 102;
  width: 0;
  height: 0;
}
.aviPopoverCarat:before,
.aviPopoverCarat:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
}
.aviPopoverCarat.top:before {
  top: 1px;
  border-top-color: #888;
}
.aviPopoverCarat.top:after {
  border-top-color: #fff;
}
.aviPopoverCarat.bottom:before {
  top: -1px;
  border-bottom-color: #888;
}
.aviPopoverCarat.bottom:after {
  border-bottom-color: #fff;
}
.aviPopoverCarat.right:before {
  left: -1px;
  border-right-color: #888;
}
.aviPopoverCarat.right:after {
  border-right-color: #fff;
}
.aviPopoverCarat.left:before {
  left: 1px;
  border-left-color: #999;
}
.aviPopoverCarat.left:after {
  border-left-color: #fff;
}
