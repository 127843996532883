.newline-converted-textarea__grid {
  grid-column-gap: 0;
}
.newline-converted-textarea__header {
  height: 17px;
}
.newline-converted-textarea__half-section {
  grid-row-gap: 0;
}
.newline-converted-textarea__sub-header-left {
  padding-top: 5px;
  padding-bottom: 5px;
  box-sizing: border-box;
  height: 26px;
  border: 1px solid #ccc;
  background: #EEE;
  color: #333;
  font-size: 12px;
  font-weight: bold;
  text-indent: 8px;
  border-right: none;
}
.newline-converted-textarea__sub-header-right {
  padding-top: 5px;
  padding-bottom: 5px;
  box-sizing: border-box;
  height: 26px;
  border: 1px solid #ccc;
  background: #EEE;
  color: #333;
  font-size: 12px;
  font-weight: bold;
  text-indent: 8px;
  border-left: 1px solid #ccc;
}
.newline-converted-textarea__textarea-left {
  height: 200px;
  padding: 3px 8px 33px 8px;
  border-top: none;
  resize: none;
  border-right: none;
  border-top-left-radius: unset;
  border-bottom-right-radius: unset;
}
.newline-converted-textarea__textarea-right {
  height: 200px;
  padding: 3px 8px 33px 8px;
  border-top: none;
  resize: none;
  height: 172px;
  padding-bottom: 0;
  overflow: auto;
  border-right: 1px solid #ccc;
  border-bottom: none;
  border-left: 1px solid #ccc;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-left-radius: unset;
  background: #E5E5E5;
  white-space: nowrap;
}
.newline-converted-textarea__emphasized {
  font-weight: bold;
}
.newline-converted-textarea__footer-box {
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  height: 20px;
  padding-top: 0;
  background-color: #666;
}
.newline-converted-textarea__toggle-right {
  position: absolute;
  right: 15px;
  margin-top: 3px;
  color: #FFF;
  font-size: 12px;
  line-height: 20px;
}
.newline-converted-textarea__wordwrap {
  color: #ff4B00;
}
