.waf-rule-groups__list {
  position: relative;
}
.waf-rule-groups__list:not(:last-child) {
  margin-bottom: 10px;
}
.waf-rule-groups__waf-rule-group-container {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: white;
}
.waf-rule-groups__waf-rule-group-container:not(:last-child) {
  margin-bottom: 7px;
}
.waf-rule-groups__waf-rule-group {
  flex-grow: 1;
  min-width: 0;
}
.waf-rule-groups__create-group {
  padding: 3px 0;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.waf-rule-groups__create-between {
  top: -9px;
}
.waf-rule-groups__container {
  padding: 7px;
  background-color: #e5e5e5;
}
.waf-rule-groups__empty-groups {
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  background-color: #e5e5e5;
  color: #999;
}
.waf-rule-groups__empty-groups:not(:last-child) {
  border-bottom: 1px solid #cccccc;
}
.waf-rule-group__parent {
  border: 1px solid #c4c4c4;
}
.waf-rule-group__parent--unclickable {
  cursor: initial;
}
.waf-rule-group__child {
  border: 1px solid #c4c4c4;
  border-top: 0;
}
.waf-rule-group__child ul[dnd-list] {
  min-height: 42px;
  padding-left: 0;
}
.waf-rule-group__child ul[dnd-list] .dndDraggingSource {
  display: none;
}
.waf-rule-group__child ul[dnd-list] .dndPlaceholder {
  display: block;
  min-height: 42px;
  padding: 10px 15px;
  background-color: #DDD;
}
.waf-rule-group__summary {
  justify-content: space-between;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.waf-rule-group__summary:hover {
  background: #f6f6f6;
}
.waf-rule-group__summary-header {
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
  flex: 1 1 auto;
  min-width: 0;
}
.waf-rule-group__exclude-icon-wrapper {
  width: 20px;
}
.waf-rule-group__expander-icon {
  flex: 0 0 auto;
  justify-content: flex-end;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.waf-rule-group__border {
  display: flex;
  flex-direction: column;
  height: 40px;
  margin-left: 10px;
  border-left: 1px solid #c4c4c4;
}
.waf-rule-group__border::before {
  content: '';
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-bottom: 1px solid #c4c4c4;
}
.waf-rule-group__switch {
  margin-right: 0.5rem;
}
.waf-rule-group__name {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.waf-rule-group__name-input {
  flex: 1 1 auto;
}
.waf-rule-group__no-rules {
  padding: 10px 20px 10px 78px;
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
}
.waf-rule-group__no-rules:not(:last-child) {
  border-bottom: 1px solid #c4c4c4;
}
.waf-rule-group__rule-label {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.waf-rule-group__rule-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}
.waf-rule-group__settings-container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 78px;
  padding: 10px 20px 10px 0;
}
.waf-rule-group__settings-container--rule {
  margin-left: 0;
}
.waf-rule-group__waf-exclude-list {
  min-width: 0;
}
.waf-rule-group__waf-rule-list {
  position: relative;
}
.waf-rule-group__waf-rule-list:not(:last-child) {
  border-bottom: 1px solid #c4c4c4;
}
.waf-rule-group__waf-rule-list-item {
  position: relative;
  padding: 10px;
  padding-right: 20px;
}
.waf-rule-group__waf-rule-list-item:not(:last-child) {
  border-bottom: 1px solid #c4c4c4;
}
.waf-rule-group__icon {
  cursor: pointer;
}
.waf-rule-group__icon:hover {
  color: #ff4B00;
}
.waf-rule-group__settings-icon {
  position: absolute;
  top: 10px;
  right: 20px;
}
.waf-rule-group__settings-icon--editing {
  color: #ff4B00;
}
.waf-rule-group__settings-icon--disabled {
  color: #999;
}
.waf-rule-group__settings-icon--disabled:hover {
  color: #999;
}
.waf-rule-group__create-rule {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.waf-rule {
  margin-left: 70px;
}
.waf-rule__summary {
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.waf-rule__switch {
  margin-right: 0.5rem;
}
.waf-rule__name {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.waf-rule__show-rule {
  display: inline-block;
  margin: 5px 0 0 58px;
}
.waf-rule__rule-container {
  padding: 5px 15px 5px 58px;
}
.waf-rule__label {
  padding-bottom: 2px;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.waf-rule__rule {
  border: 0;
}
.waf-rule__rule-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.waf-rule__rule-string {
  word-wrap: break-word;
}
.waf-rule__textarea {
  width: 100%;
  resize: none;
}
.waf-rule__textarea[readonly] {
  border: 0;
}
.waf-rule__field-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-right: 15px;
}
.waf-rule__field-row > * {
  flex: 1 0 auto;
  min-width: 0;
}
.waf-rule__field-row > *:not(:last-child) {
  margin-right: 5px;
}
.waf-rule__field-row input {
  width: 100%;
}
.waf-rule__icon {
  cursor: pointer;
}
.waf-rule__icon:hover {
  color: #ff4B00;
}
.waf-rule__icon--editing {
  color: #ff4B00;
}
.waf-exclude-list__header {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}
.waf-exclude-list__label {
  display: flex;
  color: #8c8c8c;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.waf-exclude-list__no-exceptions {
  color: #8c8c8c;
}
.waf-exclude-list__separator {
  flex: 0 0 auto;
  max-width: 100%;
  padding-right: 5px;
  line-height: 35px;
}
.waf-exclude-list__checkbox-container {
  margin-top: 5px;
}
.waf-exclude-list__icon {
  cursor: pointer;
}
.waf-exclude-list__icon:hover {
  color: #ff4B00;
}
.waf-exclude-list__icon--editing {
  color: #ff4B00;
}
.waf-policy-child-mode-setter {
  padding: 5px 0;
}
.waf-policy-child-mode-setter__header {
  display: flex;
  color: #8c8c8c;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.waf-policy-child-mode-setter__radio-label {
  margin-right: 1em;
  line-height: 20px;
  /* radio button line height */
}
.waf-policy-child-mode-setter__warning {
  margin-bottom: 5px;
}
.waf-policy-child-mode-setter__warning-icon {
  color: #EEBB33;
}
.waf-policy-child-mode-setter__warning-parent-mode {
  font-weight: bold;
  text-transform: capitalize;
}
.waf-policy__enabled-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  background: white;
}
.waf-policy__group-label {
  display: flex;
  color: #8c8c8c;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 10px 0;
  font-size: 1rem;
}
.waf-policy__group-set-container {
  margin-bottom: 40px;
}
.waf-policy__groups-container {
  padding: 7px;
  background-color: #e5e5e5;
}
.waf-policy__waf-rule-group-container {
  background-color: white;
}
.waf-policy__waf-rule-group-container:not(:last-child) {
  margin-bottom: 7px;
}
.waf-policy__waf-rule-groups ul[dnd-list] {
  min-height: 42px;
  padding-left: 0;
}
.waf-policy__waf-rule-groups ul[dnd-list] .dndDraggingSource {
  display: none;
}
.waf-policy__waf-rule-groups ul[dnd-list] .dndPlaceholder {
  display: block;
  min-height: 42px;
  padding: 10px 15px;
  background-color: #DDD;
}
