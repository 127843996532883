.avi-message {
  display: none;
  position: relative;
  z-index: 10011;
  max-width: 300px;
  margin: 0 auto;
  padding: 10px 40px;
}
.avi-message.wide {
  max-width: 600px;
}
.avi-message.visible {
  display: block;
}
