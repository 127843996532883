div.avi-main.vs-app-map {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-flow: column;
  margin: auto;
}
div.avi-main.vs-app-map div.graph-settings {
  flex: none;
  margin: 10px 1% 0;
  line-height: 35px;
}
div.avi-main.vs-app-map div.graph-settings > div.input-prepend > label.add-after {
  width: 3.3em;
}
div.avi-main.vs-app-map div.graph-settings > div.input-prepend > label.add-after dropdown,
div.avi-main.vs-app-map div.graph-settings > div.input-prepend > label.add-after dropdown:hover {
  border: 0;
  background: 0;
  box-shadow: none;
}
div.avi-main.vs-app-map div.graph-settings dropdown {
  min-width: 13em;
}
div.avi-main.vs-app-map div.graph-settings dropdown.graph-metrics {
  border-radius: 0;
  background-clip: padding-box;
}
div.avi-main.vs-app-map div.graph-settings > button {
  margin-left: 10px;
}
div.avi-main.vs-app-map div.app-map-container {
  display: flex;
  flex: auto;
  margin: 10px 1% 30px;
  overflow: hidden;
}
div.avi-main.vs-app-map div.app-map-container > ms-map-graph-chart {
  display: inline-block;
  width: 100%;
  height: auto;
}
div.avi-main.vs-app-map > div.two-column-container > div.right-section div.instances-list {
  padding: 20px;
}
div.avi-main.vs-app-map > div.two-column-container > div.right-section div.instances-list > unit-card {
  margin-right: 10px;
}
