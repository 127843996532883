.nsm-log {
  height: 50px;
  padding: 0 10px;
}
.nsm-log__rule-info {
  padding: 0 0 10px 20px;
}
.nsm-log__rule-info:last-child {
  padding-bottom: 0;
}
.nsm-log__tag {
  display: inline-flex;
  align-items: center;
  height: 20px;
  margin: 3px;
  margin-right: 5px;
  padding: 0 10px;
  border-radius: 10px;
  background: #49A1C1;
  color: white;
  font-size: 0.825rem;
  font-weight: 500;
}
.nsm-log__rule {
  display: flex;
  justify-content: space-between;
  padding: 0 0 12px 30px;
  overflow-x: hidden;
  cursor: pointer;
}
.nsm-log__rule-content {
  flex: 1;
}
.nsm-log__rule-name {
  padding: 12px 0;
}
.nsm-log__buttons-container {
  display: flex;
}
.nsm-log__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 50px;
  background: #263242;
  color: white;
  cursor: pointer;
}
.nsm-log__button:hover {
  color: #ff4B00;
}
