/**
* To be imported by every specific Item detail component.
* Not to be used directly, mixins only.
**/
virtualservice-detail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
virtualservice-detail > div[ui-view=''] {
  flex: 1 1 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
virtualservice-detail .two-column-container > .left-section {
  top: 110px;
}
virtualservice-detail .absolute-page {
  top: 110px;
}
