sparkline-chart,
sparkline-chart-list {
  display: block;
  position: relative;
  overflow: hidden;
}
sparkline-chart .sparkline-chart {
  display: block;
  position: relative;
}
sparkline-chart .sparkline-chart .chart-title {
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
}
sparkline-chart .sparkline-chart .chart-container {
  display: block;
  position: relative;
  height: 60px;
}
sparkline-chart .sparkline-chart g.guideline g.guideline-container circle.v-circle {
  stroke-width: 3px;
  stroke-opacity: 1;
  stroke: #BCBCBC;
  fill: #6B6B6B;
  fill-opacity: 1;
  r: 5px;
}
sparkline-chart-list {
  flex: 1 1 0;
}
sparkline-chart-list sparkline-chart:not(:last-child) {
  margin-bottom: 10px;
}
sparkline-chart-list .sparkline-chart-list {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
}
sparkline-chart-list .chart-list {
  position: relative;
  flex: 1 1 0;
  overflow: hidden;
  overflow-y: auto;
}
sparkline-chart-list .sparkline-list-axis {
  display: block;
  position: relative;
  height: 30px;
}
sparkline-chart-list .sparkline-list-axis g.chart-axis path,
sparkline-chart-list .sparkline-list-axis g.chart-axis line {
  stroke-width: 1px;
}
sparkline-chart-list .sparkline-list-axis g.chart-axis g.tick line {
  stroke-opacity: 0;
}
sparkline-chart-list .sparkline-list-axis.top {
  margin-bottom: 5px;
}
sparkline-chart-list .sparkline-list-axis.top g.chart-axis {
  transform: translate(0, 23px);
}
sparkline-chart-list .sparkline-list-axis.top g.chart-axis g.tick text {
  transform: translate(0, -5px);
}
sparkline-chart-list .sparkline-list-axis.top g.axis-timestamp text.axis-timestamp-text {
  transform: translate(0, 18px);
}
sparkline-chart-list .sparkline-list-axis.top g.axis-timestamp-circle circle {
  transform: translate(0, 23px);
}
sparkline-chart-list .sparkline-list-axis.bottom {
  margin-top: 5px;
}
sparkline-chart-list .sparkline-list-axis.bottom g.chart-axis {
  transform: translate(0, 2px);
}
sparkline-chart-list .sparkline-list-axis.bottom g.chart-axis g.tick text {
  transform: translate(0, 7px);
}
sparkline-chart-list .sparkline-list-axis.bottom g.axis-timestamp text.axis-timestamp-text {
  transform: translate(0, 15px);
}
sparkline-chart-list .sparkline-list-axis.bottom g.axis-timestamp-circle circle {
  transform: translate(0, 3px);
}
