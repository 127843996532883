.cloud-status-tooltip {
  display: block;
  position: absolute;
  z-index: 10101;
  box-sizing: border-box;
  width: 210px;
  margin: 0;
  border: 1px solid #888;
  background: #fff;
  font-size: 14px;
  pointer-events: none;
}
.cloud-status-tooltip__header {
  grid-column-gap: 0;
  margin: 5px 10px 10px 5px;
}
.cloud-status-tooltip__header-cloud-name {
  margin-top: 7px;
}
.cloud-status-tooltip__header-cloud-state-reason {
  font-size: 10px;
}
.cloud-status-tooltip__circle-icon {
  margin-top: 3px;
  font-size: 1.45em;
}
.cloud-status-tooltip__circle-icon.status-in-progress {
  color: #F3EB16;
}
.cloud-status-tooltip__circle-icon.status-unknown {
  color: #FF9900;
}
.cloud-status-tooltip__circle-icon.status-ready {
  color: #8EAD1F;
}
.cloud-status-tooltip__circle-icon.status-failed {
  color: #CC0000;
}
.cloud-status-tooltip__separation-bar {
  margin: 3px 0 10px 0;
}
.cloud-status-tooltip__versions-list {
  margin: 0;
}
.cloud-status-tooltip__version-row {
  margin: 0 10px 10px 10px;
}
.cloud-status-tooltip__version-row-number {
  font-weight: bold;
}
.cloud-status-tooltip__version-row-status {
  display: inline-block;
  position: absolute;
  right: 10px;
}
