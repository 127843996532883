.update-card-progress {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.update-card-progress__empty {
  justify-content: center;
}
.update-card-progress__tile {
  padding: 20px 10px;
  /*
        &-progress-bar-container {
            padding: 0;
        }
        */
}
.update-card-progress__tile:nth-child(2) {
  padding-top: 0;
}
.update-card-progress__tile .update-card-progress__icon-loader {
  font-size: 14px;
}
.update-card-progress__tile-controller-text {
  display: inline-block;
}
.update-card-progress__tile-segroups-text {
  display: inline-block;
}
.update-card-progress__tile-segroups-number {
  font-weight: bold;
}
.update-card-progress__tile--nothing-in-progress {
  align-self: center;
  color: #CCC;
}
.update-card-progress__progress-bar {
  padding-right: 10px;
  padding-left: 10px;
}
