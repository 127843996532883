.virtualservice-config .grid-field-name {
  width: 15%;
}
.virtualservice-config .grid-field-address {
  width: 15%;
}
.virtualservice-config .grid-field-service {
  width: 5%;
}
.virtualservice-config .grid-field-pool {
  width: 15%;
}
.virtualservice-config .grid-field-serviceengine {
  width: 15%;
}
