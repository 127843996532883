.waf-logs-list__container {
  margin-bottom: 5px;
}
.waf-logs-list__expander {
  display: block;
  margin-bottom: 2px;
}
.waf-logs-list__expander-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  padding: 6px 0 6px 10px;
  border: 1px solid #E2E6EA;
  background-color: #E2E6EA;
  color: #ff4B00;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
}
