collection-grid {
  display: block;
}
.table-bottom {
  display: block;
  position: relative;
  padding: 10px;
}
.grid {
  display: block;
  position: relative;
  height: 100%;
}
.grid .table-wrapper {
  display: block;
  position: relative;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
  background: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.grid .grid-controls {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 50px;
}
.grid .grid-controls .grid-controls-right,
.grid .grid-controls .grid-controls-left {
  display: flex;
  align-items: center;
}
.grid .grid-controls .grid-controls-left {
  flex: 1 1 auto;
  justify-content: flex-start;
}
.grid .grid-controls .grid-controls-right {
  flex: 0 0 auto;
  justify-content: flex-end;
}
.grid .grid-controls .actions-panel {
  display: flex;
  position: relative;
  flex: 0 0 auto;
  align-items: center;
  margin-right: 10px;
}
.grid .grid-controls .actions-panel .grid-action:not(:last-child),
.grid .grid-controls .actions-panel .grid-action-button:not(:last-child) {
  margin-right: 5px;
}
.grid .grid-controls .actions-panel .options {
  border: 1px solid #888;
  border-radius: 2px;
  white-space: nowrap;
}
.grid .grid-controls .actions-panel .options input[type=checkbox] {
  margin: 9px 5px 3px 8px;
}
.grid .grid-controls .grid-default-controls {
  display: inline-flex;
  position: relative;
  flex: 0 0 auto;
  align-items: center;
  margin-right: 10px;
}
.grid .grid-controls .grid-default-controls > *:not(:last-child) {
  margin-right: 10px;
}
.grid .displaying {
  padding: 10px 10px 0;
  font-size: 12px;
}
.grid .table-header-container {
  display: block;
  position: relative;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
}
.grid .header-table,
.grid .body-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 15px;
}
.grid .header-table-wrapper,
.grid .body-table-wrapper {
  display: block;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}
.grid .header-table-header {
  color: #666;
  font-weight: bold;
  text-align: left;
}
.grid .header-table-row {
  border-bottom: 1px solid #ccc;
}
.grid .header-table-cell,
.grid .body-table-cell {
  box-sizing: border-box;
}
.grid .header-table-cell .table-action-icon,
.grid .body-table-cell .table-action-icon {
  color: #999;
}
.grid .header-table-cell {
  position: relative;
  padding: 0;
  overflow: hidden;
}
.grid .header-table-cell.sorted-asc .inner,
.grid .header-table-cell.sorted-desc .inner {
  border-left-color: transparent;
}
.grid .header-table-cell:first-child {
  position: relative;
}
.grid .header-table-cell:first-child .inner {
  border-left: none;
}
.grid .header-table-cell:last-child .inner {
  border-right: none;
}
.grid .header-table-cell:last-child > div.columns-setup {
  position: absolute;
  top: 0;
  right: 0;
}
.grid .header-table-cell.selectable {
  width: 66px;
}
.grid .header-table-cell.rowactions {
  width: 100px;
  overflow: hidden;
}
.grid .header-table-cell.rowactions .inner {
  justify-content: flex-end;
}
.grid .header-table-cell:nth-of-type(2) {
  width: auto;
}
.grid .header-table-cell.grid-field-index {
  width: 70px;
}
.grid .header-table-cell .inner,
.grid .header-table-cell .icon {
  line-height: 25px;
}
.grid .header-table-cell .inner {
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px;
  text-align: left;
}
.grid .header-table-cell .inner > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.grid .header-table-cell .inner .field-name {
  display: inline-block;
  align-items: center;
  overflow: hidden;
  line-height: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.grid .header-table-cell .icon {
  display: inline-block;
  position: relative;
  margin-left: 5px;
}
.grid .header-table-cell selection-panel li .icon-check {
  position: inherit;
  float: right;
}
.grid .body-table-body {
  color: #2a2a2d;
}
.grid .body-table-row {
  height: 45px;
  border-bottom: 1px solid #EBEBEB;
}
.grid .body-table-row + .details {
  border-bottom: 1px solid #EBEBEB;
}
.grid .body-table-row + .details > td[colspan] {
  padding: 15px;
}
.grid .body-table-row .row-expander-icon {
  display: none;
}
.grid .body-table-row .row-expander-icon .icon-minus {
  display: none;
}
.grid .body-table-row.selected {
  background-color: rgba(142, 173, 30, 0.1);
}
.grid .body-table-row.clickable {
  cursor: pointer;
}
.grid .body-table-row.clickable .row-expander-icon {
  display: inline-block;
}
.grid .body-table-row.clickable:hover {
  background: rgba(142, 173, 30, 0.1);
}
.grid .body-table-row.clickable.expanded {
  box-sizing: border-box;
  background-color: rgba(142, 173, 30, 0.1);
}
.grid .body-table-row.clickable.expanded .row-expander-icon .icon-plus {
  display: none;
}
.grid .body-table-row.clickable.expanded .row-expander-icon .icon-minus {
  display: inline;
}
.grid .body-table-row.dragged {
  background: rgba(142, 173, 30, 0.1);
}
.grid .body-table-cell {
  margin: 0;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.grid .body-table-cell.disabled {
  color: #8c8c8c;
}
.grid .body-table-cell.rowactions {
  width: 100px;
  text-align: right;
}
.grid .body-table-cell.rowactions a {
  text-decoration: none;
}
.grid .body-table-cell.rowactions a[disabled] {
  display: none;
}
.grid .body-table-cell[grid-drag-and-drop-handle] {
  user-select: none;
}
.grid .body-table-cell a {
  color: inherit;
}
.grid .body-table-cell input[type=text],
.grid .body-table-cell input[type=number] {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  margin: 2px 0;
  line-height: 30px;
}
.grid .drop-mask {
  display: none;
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.grid > div.empty {
  padding: 10px;
  border: 1px solid #CCC;
  border-top: none;
  background-color: #FFF;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.grid.disabled {
  opacity: 0.65;
}
.grid .resize {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 0;
  width: 5px;
  height: 25px;
  border-right: 1px solid #ccc;
  cursor: col-resize;
}
.grid .column-configuration {
  border: 1px solid #989C9C;
  border-top: none;
  border-radius: 3px;
}
.grid .column-configuration .header {
  height: 25px;
  margin: -1px -1px 0 -1px;
  padding: 0 15px;
  border: 1px solid #414666;
  background-color: #5F6695;
  color: #FFF;
  line-height: 25px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
.grid .column-configuration ul {
  height: 200px;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  background-color: #FFF;
  color: #3b8dab;
  list-style: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.grid .column-configuration ul li {
  box-sizing: border-box;
  height: 35px;
  padding: 0 15px;
  border: 1px solid transparent;
  line-height: 35px;
  cursor: pointer;
}
.grid .column-configuration ul li:nth-child(even) {
  background-color: #fff;
}
.grid .column-configuration ul li:not(.selected):hover {
  border-color: #414666;
  box-shadow: 0 0 5px 0 rgba(65, 70, 102, 0.5);
}
.grid .column-configuration ul li.selected {
  border: 1px solid #839c2b;
  background-color: #ecf3d4;
  cursor: default;
}
.grid .table-navigation {
  display: flex;
  justify-content: flex-end;
  position: relative;
  flex-direction: row;
  align-content: flex-end;
  align-items: center;
  padding: 10px;
}
.grid .table-navigation .avi-label {
  margin: 0 10px;
}
.grid .table-navigation dropdown {
  float: none;
}
.selection-panel {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 29px;
  left: -1px;
  min-width: 120px;
  margin-top: 3px;
  margin-left: -1px;
  padding: 10px 0;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.27);
  color: #666;
  line-height: normal;
}
.selection-panel:before {
  content: '▲';
  position: absolute;
  top: -12px;
  left: 6px;
  transform: scaleX(1.5);
  color: white;
  font-size: 12px;
  text-shadow: 0 -1px 2px rgba(0, 0, 0, 0.27);
}
.selection-panel a {
  display: block;
  position: relative;
  padding: 10px 25px;
  overflow: hidden;
  color: inherit;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.selection-panel a.active {
  color: #ff4B00;
}
.selection-panel a:hover {
  background-color: #F1F1F1;
}
.grid-field-health {
  width: 90px;
}
.grid-field-throughput {
  width: 100px;
}
cell {
  display: flex;
  align-items: center;
  justify-content: stretch;
  height: 100%;
}
cell[disabled] {
  justify-content: center;
}
cell > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*div.grid-debug {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0; right: 0;
    opacity: 0.5;
    height: 4px;
    background-color: cyan;

    &.top {
        background-color: magenta;
    }
}*/
.aviPopover.collection-grid-create-actions-popover .create-actions-panel {
  display: block;
  z-index: 9999;
  border: 1px solid #888;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  color: #3b8dab;
  line-height: 30px;
  text-align: left;
}
.aviPopover.collection-grid-create-actions-popover .create-actions-panel ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.aviPopover.collection-grid-create-actions-popover .create-actions-panel ul li {
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.aviPopover.collection-grid-create-actions-popover .create-actions-panel ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
}
.aviPopover.collection-grid-create-actions-popover .create-actions-panel ul li:hover {
  background-color: #fff;
  color: #2e6d85;
}
.aviPopover.collection-grid-create-actions-popover .create-actions-panel ul li.selected {
  border-left: 4px solid #3b8dab;
  background-color: #fff;
}
.aviPopover.collection-grid-create-actions-popover .create-actions-panel ul li.selected:hover {
  cursor: default;
}
