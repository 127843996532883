.top-list-timing-section {
  display: block;
  position: relative;
  height: 35px;
  overflow: hidden;
}
.top-list-timing-section .plt-section {
  height: 10px;
}
.top-list-timing-section .plt-section .stacked-end-to-end {
  height: 5px;
}
.top-list-timing-section .plt-section .bar {
  height: 10px;
  line-height: 10px;
}
.top-list-timing-section .pdt-section {
  z-index: 1;
  height: 5px;
}
.top-list-timing-section .pdt-section .bar {
  height: 10px;
  line-height: 10px;
}
