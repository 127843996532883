.searchWrapper {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  min-height: 35px;
  margin-bottom: 0.75em;
}
.searchWrapper button.refresh {
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 38px;
}
.searchWrapper event-search,
.searchWrapper .event-search {
  display: block;
  position: relative;
  flex: 1;
}
.searchWrapper log-search {
  flex: 1;
}
.searchWrapper log-search button.saved i {
  color: #f6da91 !important;
}
.searchWrapper .searchRight {
  margin-left: 10px;
}
div.avi-main.events event-search,
div.avi-main.alerts event-search,
div.avi-main.events .event-search,
div.avi-main.alerts .event-search {
  display: block;
}
.log-search {
  position: relative;
  width: 100%;
  margin-right: 10px;
}
.log-search search-pill {
  display: block;
  position: relative;
  height: 100%;
  margin: 4px 2px 3px 6px;
  padding-right: 22px;
  line-height: 18px;
}
.log-search search-pill > i {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}
.log-search search-pill.edit-mode {
  margin: 0;
  padding-right: 0;
  padding-left: 6px;
  background-color: #fff;
}
.log-search log-input-pills {
  min-height: 35px;
  padding-right: 80px;
  float: none;
}
.log-search log-input-pills span.choice ul {
  width: auto;
}
.log-search log-input-pills span.choice ul > li.search-pill-wrapper {
  height: 27px;
  padding: 0;
}
.log-search log-input-pills input[type='text'] {
  float: none;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  line-height: 30px !important;
}
.log-search .log-search-input {
  display: block;
  position: relative;
  width: 100%;
  height: 35px;
  margin-bottom: 0;
  margin-left: 0;
  padding: 4px 80px 4px 4px;
  border-radius: 3px;
  font-weight: bold;
  vertical-align: top;
}
.log-search > button {
  position: absolute;
  top: 0;
  height: 30px;
  padding: 0 5px;
  line-height: 30px;
}
.log-search > button:nth-last-child(1) {
  right: 0px;
}
.log-search > button:nth-last-child(2) {
  right: 30px;
}
.log-search > button:nth-last-child(3) {
  right: 60px;
}
.log-search.multiline {
  /* searchbar in logs can be multiline */
}
.log-search.multiline .log-search-button-search {
  border-bottom-right-radius: 0;
}
.log-search.multiline .log-empty-field {
  border-bottom-left-radius: 3px;
}
div.log-search-typeahead {
  display: none;
  position: absolute;
  z-index: 4;
  min-height: 8px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #cccccc;
  background: #fff;
  box-shadow: 0 1px 5px 0 #cccccc;
  cursor: pointer;
}
div.log-search-typeahead > div.log-search-typeahead-list > table {
  width: 100%;
}
div.log-search-typeahead > div.log-search-typeahead-list > table.variable td.value {
  width: 17em;
}
div.log-search-typeahead > div.log-search-typeahead-list > table.operator td.value {
  width: 2.5em;
}
div.log-search-typeahead > div.log-search-typeahead-list > table.query td.value {
  width: 6em;
}
div.log-search-typeahead > div.log-search-typeahead-list > table.query td.percent {
  width: 6em;
}
div.log-search-typeahead > div.log-search-typeahead-list > table.query td.padding {
  width: 10em;
}
div.log-search-typeahead > div.log-search-typeahead-list > table tr.selected {
  background: #e5e5e5;
}
div.log-search-typeahead > div.log-search-typeahead-list > table td {
  padding: 0.5em 0 0.5em 1em;
  color: #666;
}
div.log-search-typeahead > div.log-search-typeahead-list > table td.descr {
  width: 17em;
}
div.log-search-typeahead > div.log-search-typeahead-list > table td.value {
  color: #3b8dab;
}
div.log-search-typeahead > div.log-search-typeahead-list > table td.value > span.provided {
  font-weight: bold;
}
body > div.aviPopover.saveLogSearch {
  z-index: 1001;
  padding: 0.5em;
  border: 1px solid #cccccc;
}
body > div.aviPopover.saveLogSearch input {
  margin-right: 0.5em;
  font-family: 'Helvetica', sans-serif;
}
body > div.aviPopoverCarat.saveLogSearch {
  z-index: 1002;
}
body > div.aviPopover.exportLogs {
  line-height: 30px;
}
body > div.aviPopover.exportLogs li {
  padding: 0 1em;
  white-space: nowrap;
}
body > div.aviPopover.exportLogs li:hover {
  background-color: #fff;
  color: #2e6d85;
}
body > div.aviPopover.exportLogs li a {
  width: 100%;
}
