.avi-pool-analytics .row-fluid {
  margin: 0;
}
.avi-pool-analytics .anomaly-panel {
  margin-top: 10px;
}
.avi-pool-analytics .pool-performance,
.avi-pool-analytics .server-summary {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}
@media (max-width: 750px) {
  .avi-pool-analytics .pool-performance,
  .avi-pool-analytics .server-summary {
    width: 100%;
  }
  .avi-pool-analytics .server-summary {
    position: relative;
    top: -300px;
  }
  .avi-pool-analytics .pool-performance {
    position: relative;
    top: 300px;
  }
}
.avi-pool-analytics .avi-subheader .navbar {
  width: auto;
}
.avi-pool-analytics table .avi-healthscore {
  margin-top: 5px;
  line-height: 35px;
}
.avi-pool-analytics .timing-tiles {
  margin-bottom: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
