.update-card {
  display: flex;
  flex-flow: column;
  background-color: #fff;
}
.update-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 10px;
}
.update-card__header-text {
  font-size: 18px;
}
.update-card__content {
  height: 100%;
}
