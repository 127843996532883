div.avi-modal-body.sslprofile {
  /* main view of the modal */
}
div.avi-modal-body.sslprofile > div.top {
  border-bottom: 1px solid #888;
}
div.avi-modal-body.sslprofile > div.top > div {
  display: inline-block;
  box-sizing: border-box;
}
div.avi-modal-body.sslprofile > div.top > div.name-and-type {
  width: 70%;
}
div.avi-modal-body.sslprofile > div.top > div.indication {
  position: relative;
  width: 29%;
  height: 90px;
  padding: 0 0 1em 1.5em;
  border-left: 1px solid #888;
}
div.avi-modal-body.sslprofile > div.top > div.indication > div > ul {
  line-height: 1.25;
}
div.avi-modal-body.sslprofile > div.top > div.indication > div > ul > li > span.good,
div.avi-modal-body.sslprofile > div.top > div.indication > div > ul > li > span.high,
div.avi-modal-body.sslprofile > div.top > div.indication > div > ul > li > span.excellent {
  color: #8ead1f;
}
div.avi-modal-body.sslprofile > div.top > div.indication > div > ul > li > span.average,
div.avi-modal-body.sslprofile > div.top > div.indication > div > ul > li > span.medium {
  color: #ccc50a;
}
div.avi-modal-body.sslprofile > div.top > div.indication > div > ul > li > span.bad,
div.avi-modal-body.sslprofile > div.top > div.indication > div > ul > li > span.low,
div.avi-modal-body.sslprofile > div.top > div.indication > div > ul > li > span.very-bad,
div.avi-modal-body.sslprofile > div.top > div.indication > div > ul > li > span.not-secure {
  color: #cc7a00;
}
div.avi-modal-body.sslprofile > div.top > div.indication > h4 {
  margin-bottom: 0.4em;
}
div.avi-modal-body.sslprofile > div.top > div.indication div[avi-loader] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  bottom: auto;
  left: auto;
  height: 20px;
}
div.avi-modal-body.sslprofile > div.top > div.indication span.error {
  color: #ce4b18;
}
div.avi-modal-body.sslprofile > h2 {
  border-top: none;
  box-shadow: none;
}
div.avi-modal-body.sslprofile > div.global-settings {
  margin-bottom: 1em;
}
div.avi-modal-body.sslprofile > div.global-settings label.checkbox-label {
  line-height: 20px;
}
div.avi-modal-body.sslprofile > div.global-settings > div.version > label {
  display: inline-block;
  margin: 5px 0;
}
div.avi-modal-body.sslprofile > div.global-settings > div.version label.checkbox-label {
  margin-right: 1.25em;
}
div.avi-modal-body.sslprofile > div.global-settings > div.other-settings {
  text-align: right;
}
div.avi-modal-body.sslprofile > div.global-settings > div.other-settings label.checkbox-label {
  margin-left: 1.25em;
}
div.avi-modal-body.sslprofile > div.body label {
  display: inline-block;
  margin-bottom: 5px;
}
div.avi-modal-body.sslprofile > div.body.list .c-grid-table-header-cell--field-name--enabled {
  width: 5em;
}
div.avi-modal-body.sslprofile > div.body.list .c-grid-table-header-cell--field-name--cipher {
  width: 40%;
}
div.avi-modal-body.sslprofile > div.body.list .c-grid-table-header-cell--field-name--securityScore {
  width: 12%;
}
div.avi-modal-body.sslprofile > div.body.list .c-grid-table-header-cell--field-name--pfs {
  width: 8%;
}
div.avi-modal-body.sslprofile > div.body.list .c-grid-table-header-cell--field-name--performance,
div.avi-modal-body.sslprofile > div.body.list .c-grid-table-header-cell--field-name--compatibility {
  width: 12%;
}
div.avi-modal-body.sslprofile > div.body.list .c-grid__table-body-row.enabled:not(.c-grid__table-body-row--dragged) {
  background-color: #fcf2d8;
}
div.avi-modal-body.sslprofile > div.body.string > div > input[type=text] {
  width: 100%;
}
