.system-update__container {
  display: flex;
  justify-content: space-between;
  height: 215px;
  margin: 30px 0;
}
.system-update__update-card {
  flex: 1 1;
  margin-right: 40px;
}
.system-update__update-card:last-child {
  margin-right: 0;
}
.system-update__update-card-header-icon {
  position: relative;
  top: 2px;
  margin-right: 5px;
  font-size: 18px;
}
.system-update__update-card-progress {
  height: 100%;
}
.rollback-dropdown-menu-list {
  display: block;
  width: 200px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.27);
  line-height: normal;
}
