virtualservice-grid-address .icon-arrow-up {
  color: #8EAD1F;
}
virtualservice-grid-address .icon-arrow-up:before {
  margin: 0;
}
virtualservice-grid-address .icon-arrow-down {
  color: #CC0000;
}
virtualservice-grid-address .icon-arrow-down:before {
  margin: 0;
}
