.waf-policy-psm-groups-list {
  margin-bottom: 7px;
}
.waf-policy-psm-groups-list__group {
  display: block;
  margin-bottom: 5px;
}
.waf-policy-psm-groups-list__group.cdk-drag-preview {
  z-index: 10199 !important;
  border: 1px solid #ccc;
}
.waf-policy-psm-groups-list__group.cdk-drag-placeholder {
  opacity: 0.5;
}
