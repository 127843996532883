event-chart {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: white;
}
event-chart .event-icon {
  display: block;
  position: relative;
  width: 14px;
  height: 14px;
  overflow: hidden;
  border: 2px solid white;
  border-radius: 100%;
  line-height: 14px;
  cursor: pointer;
}
event-chart .event-icon.anomalies {
  background-color: #EEBB33;
}
event-chart .event-icon.alerts {
  background-color: #EA7648;
}
event-chart .event-icon.config {
  background-color: #67B0CB;
}
event-chart .event-icon.system {
  background-color: #AD73E8;
}
event-chart .event-icon.selected {
  left: -1px;
  border-width: 3px;
}
event-chart .event-icon i {
  display: block;
  position: relative;
  color: white;
  font-size: 10px;
  line-height: 14px;
}
event-chart .chart-header {
  display: flex;
  position: relative;
  align-content: center;
  align-items: stretch;
  justify-content: space-between;
  min-height: 25px;
  padding: 10px 25px;
  border-bottom: 1px solid #ccc;
  line-height: 25px;
}
event-chart .chart-header > * {
  flex: 1 1 0;
}
event-chart .chart-header h3.chart-title {
  display: inline-block;
  position: relative;
  color: black;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
}
event-chart .chart-header .chart-controls {
  display: inline-block;
  position: relative;
  flex: 3 0 0;
  text-align: center;
}
event-chart .chart-header .chart-controls .event-controls {
  display: inline-flex;
  position: relative;
}
event-chart .chart-header .chart-controls .event-controls .event-control {
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  white-space: nowrap;
}
event-chart .chart-header .chart-controls .event-controls .event-control .avi-label {
  font-size: 12px;
}
event-chart .chart-header .chart-controls .event-controls .event-control .event-icon {
  width: 18px;
  height: 18px;
  margin: 0 5px;
  border: 0;
}
event-chart .chart-header .chart-controls .event-controls .event-control .event-icon i {
  font-size: 12px;
  line-height: 18px;
}
event-chart .axis-container {
  display: flex;
  padding: 10px 20px 0;
}
event-chart .axis-container .axis-label {
  position: relative;
  flex: 1 1 0;
  font-size: 10px;
}
event-chart .axis-container .axis-label .title {
  font-size: 1em;
}
event-chart .axis-container .axis-label.left .title {
  font-weight: bold;
}
event-chart .axis-container .axis-label.right {
  text-align: right;
}
event-chart .chart-group {
  display: flex;
  position: relative;
  max-width: 100%;
  padding: 0 20px 10px;
  overflow: hidden;
}
event-chart .chart-group .chart-container {
  display: block;
  position: relative;
  box-sizing: border-box;
  flex: 1 1 0;
  height: 0;
  min-height: 250px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
event-chart .chart-group .chart-container text.average-label {
  transform: translate(-27px, 0);
}
event-chart .chart-group .chart-events-container {
  display: block;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 80px;
}
event-chart .chart-group .chart-events-container .event-icons {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
}
