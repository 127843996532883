hs-insights {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  height: 100%;
  padding: 10px;
}
hs-insights > div {
  display: inherit;
  flex-direction: inherit;
  height: inherit;
}
hs-insights > div.main {
  flex: 1 0 635px;
  flex-direction: column;
  overflow-x: hidden;
}
hs-insights > div.main > div {
  display: inherit;
  box-sizing: border-box;
  margin-bottom: 5px;
}
hs-insights > div.main > div.top-row {
  flex: 2 0 310px;
}
hs-insights > div.main > div.top-row > div {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
hs-insights > div.main > div.top-row > div.tree {
  width: 70%;
  overflow: auto;
}
hs-insights > div.main > div.top-row > div.sidebar {
  width: 30%;
  margin-left: 20px;
  padding: 20px 10px 10px;
  overflow: hidden;
  background: white;
}
hs-insights > div.main > div.top-row > div.sidebar .sidebar-content {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
}
hs-insights > div.main > div.top-row > div.sidebar .sidebar-content .charts-title {
  margin-bottom: 7px;
  padding-bottom: 7px;
  border-bottom: 1px solid #999;
  color: black;
  font-size: 14px;
  font-weight: bold;
}
hs-insights > div.main > div.chart-row {
  position: relative;
  flex: 1 0 310px;
  min-height: 310px;
  margin-top: 5px;
}
hs-insights > div.under-the-cut-row {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  flex: 0 0 300px;
  margin: 10px 0;
  background-color: #fff;
  font-size: 2em;
  line-height: 10;
  text-align: center;
}
