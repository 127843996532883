.item-alert-level-icon {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 1em;
}
.item-alert-level-icon__icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  box-sizing: border-box;
  width: 0.9em;
  height: 0.9em;
  border: 2px solid #888;
  border-radius: 1em;
}
.item-alert-level-icon__icon_level_high {
  border-color: #ce4b18;
  background: #EA7648;
}
.item-alert-level-icon__icon_level_medium {
  border-color: #dca512;
  background: #EEBB33;
}
.item-alert-level-icon__icon_level_low {
  border-color: #3b8dab;
  background: #67B0CB;
}
