wizard {
  display: inline-block;
  position: relative;
  z-index: 10;
  width: 100%;
  float: left;
  overflow: hidden;
  border-bottom: 1px solid #2e6d85;
  background-color: #67B0CB;
  line-height: 10px;
}
wizard ul {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
wizard ul li {
  position: relative;
  height: 30px;
  float: left;
  white-space: nowrap;
}
wizard ul li:nth-child(1) {
  z-index: 10;
}
wizard ul li:nth-child(2) {
  z-index: 9;
}
wizard ul li:nth-child(3) {
  z-index: 8;
}
wizard ul li:nth-child(4) {
  z-index: 7;
}
wizard ul li:nth-child(5) {
  z-index: 6;
}
wizard ul li a {
  display: block;
  overflow: hidden;
  color: #2e6d85;
  line-height: 30px;
  text-overflow: ellipsis;
}
wizard ul li a:hover {
  text-decoration: none;
  cursor: not-allowed;
}
wizard ul li i {
  margin-left: 15px;
  float: left;
  color: #2e6d85;
  font-size: 18px;
  line-height: 30px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}
wizard ul li:before {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  width: 0;
  height: 0;
  transform: rotate(360deg);
  transition: all 0.2s linear;
  border-width: 15px 0 16px 10px;
  border-style: solid;
  border-color: transparent transparent transparent #2e6d85;
}
wizard ul li:after {
  content: "";
  position: absolute;
  top: 0;
  right: -9px;
  width: 0;
  height: 0;
  transform: rotate(360deg);
  transition: all 0.2s linear;
  border-width: 15px 0 16px 10px;
  border-style: solid;
  border-color: transparent transparent transparent #67B0CB;
}
wizard ul li.unlocked:before {
  border-color: transparent transparent transparent #2e6d85;
}
wizard ul li.unlocked:after {
  border-color: transparent transparent transparent #67B0CB;
}
wizard ul li.unlocked i {
  color: #AFCC48;
  text-shadow: none;
}
wizard ul li.unlocked:not(.current) a {
  color: #2e6d85;
}
wizard ul li.unlocked:not(.current) a:hover {
  color: #fff;
  cursor: pointer;
}
wizard ul li.current {
  background-color: #3b8dab;
}
wizard ul li.current a {
  color: #fff;
  cursor: default;
}
wizard ul li.current:before {
  border-color: transparent transparent transparent #2e6d85;
}
wizard ul li.current:after {
  border-color: transparent transparent transparent #3b8dab;
}
wizard ul li.current i {
  color: #2e6d85;
}
wizard ul li.current.unlocked i {
  color: #839c2b;
  text-shadow: none;
}
