.radio-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  transition: background-color 0.25s ease-in-out;
  border: 1px solid #888;
  border-radius: 20px;
  background-color: white;
  line-height: 20px;
}
.radio-button:not([checked]):not([disabled]):hover {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25), inset 0 0 0 4px #FFF;
  cursor: pointer;
}
.radio-button[checked] {
  background-color: #839c2b;
  box-shadow: inset 0 0 0 4px #FFF;
}
.radio-button[disabled] {
  border-color: #cccccc;
  background: #e5e5e5;
}
