pool-group-create grid div.grid th.grid-field-ratio {
  width: 15%;
}
pool-group-create grid div.grid th.grid-field-priority_label {
  width: 17%;
}
pool-group-create grid div.grid th.grid-field-deployment_state {
  width: 20%;
}
pool-group-create .avi-btn-group {
  background-color: transparent;
}
