.performance-chart {
  box-sizing: border-box;
}
.performance-chart th.checkbox {
  width: 20px;
}
.performance-chart .alert-priority {
  line-height: 32px;
}
.performance-chart .alert-priority .alertOutter {
  margin-top: 8px;
}
.performance-chart .dotWrapper {
  position: absolute;
  height: 45px;
  margin-top: 45px;
}
.performance-chart .alignHeight {
  width: 0;
  height: 45px;
}
.performance-chart .dotContainer {
  width: 0;
}
.performance-chart .dotContainer .graph-shape.shape {
  display: block;
  position: relative;
  margin: 0;
}
.performance-chart .dotWrapper > * {
  display: inline-block;
  vertical-align: bottom;
}
.performance-chart-controls-container {
  position: relative;
  z-index: 3;
}
.performance-chart-controls {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.performance-chart-controls .chart-control {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  white-space: nowrap;
  cursor: pointer;
}
@media screen and (max-width: 1300px) {
  .performance-chart-controls .chart-control .avi-label {
    display: none;
  }
}
.performance-chart-controls .chart-control .shape {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 2px;
  margin-left: 5px;
  font-size: 12px;
  line-height: 20px;
}
.performance-chart-controls .chart-control .shape i {
  line-height: 20px;
}
.performance-chart-controls .chart-controls-checkbox {
  pointer-events: none;
}
.performance-chart-controls .control-count {
  margin-left: 10px;
}
.performance-chart-header {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  height: 45px;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}
.performance-chart-header .chart-header-content-middle {
  display: block;
  position: relative;
  flex: 1 1 0;
}
