.range-slider {
  display: block;
  position: relative;
  height: 35px;
  overflow: hidden;
}
.range-slider-track {
  position: absolute;
  right: 0;
  left: 0;
  box-sizing: border-box;
  height: 35px;
  border: 1px solid #999;
  border-radius: 3px;
  background: #fff;
}
.range-slider-track .range-slider-track-fill {
  display: block;
  position: absolute;
  left: 0;
  box-sizing: border-box;
  height: 33px;
  border-right: 1px solid #999;
  background: #AFCC48;
}
.range-slider-thumb {
  display: block;
  position: absolute;
  z-index: 1;
  cursor: w-resize;
}
.range-slider-thumb .range-slider-thumb-inner {
  position: absolute;
  left: -10px;
  width: 20px;
  height: 35px;
  background: transparent;
}
