.match-container__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
}
.match-container__close-button {
  border: none;
  background: none;
}
.match-container .h-controls-group > .match-container__radio-group {
  flex: 0 0 175px;
  margin-right: 15px;
}
