.log-barchart {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  display: block;
  position: relative;
  height: 125px;
  margin-bottom: 15px;
  background-color: white;
  /* icons used inside SVG */
}
.log-barchart > div[avi-loader] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 22px;
  margin: auto;
}
.log-barchart .brush .extent {
  stroke-width: 2px;
  stroke: #67B0CB;
  shape-rendering: crispEdges;
}
.log-barchart .axis {
  shape-rendering: crispEdges;
}
.log-barchart rect.barchart {
  stroke-width: 1;
}
.log-barchart rect.barchart.adf {
  stroke: #ce4b18;
  fill: #f4bba4;
}
.log-barchart rect.barchart.nf {
  stroke: #839c2b;
  fill: #ecf3d4;
}
.log-barchart div.in-svg i.icon-zoom-in {
  color: #67B0CB;
  font-size: 1.3em;
  cursor: pointer;
}
.log-barchart div.in-svg i.icon-lostlog {
  color: #dca512;
}
.log-barchart div.in-svg i.icon:before {
  margin: 0;
  padding-right: 1px;
}
.log-barchart .y.axis line,
.log-barchart .x.axis line,
.log-barchart .y.axis path,
.log-barchart .x.axis path {
  stroke: #cccccc;
  fill: none;
}
.log-barchart .y.axis line.topline,
.log-barchart .x.axis line.topline {
  stroke-width: 1;
}
.log-barchart .highlight {
  stroke: #e0cbf6;
}
.log-barchart.events {
  height: 100px;
}
.log-barchart.events rect.barchart {
  stroke: #839c2b;
  fill: #ecf3d4;
}
.log-barchart g.waf-container {
  pointer-events: none;
}
.log-barchart g.waf-container .waf-icon-container {
  display: block;
  position: absolute;
}
.log-barchart g.waf-container .waf-icon-container .waf-icon {
  display: block;
  position: relative;
  width: 12px;
  height: 12px;
  transform: translate(-100%, -100%);
  border-radius: 100%;
  background-color: #485465;
  color: white;
  font-size: 8px;
}
.log-barchart g.waf-container .waf-icon-container .waf-icon .icon-shield {
  display: block;
  position: relative;
  transform: translate(2px, 2px);
}
div.chart-wrapper {
  position: relative;
}
div.chart-wrapper > div.chart-legend {
  position: absolute;
  z-index: auto;
  top: 6px;
  right: 12px;
}
div.chart-wrapper > div.chart-legend span.chart-color-warning {
  font-size: 0.9em;
}
div.chart-wrapper > div.chart-legend span.legend-pair {
  border-left: 0;
  cursor: pointer;
}
div.chart-wrapper > div.chart-legend span.legend-pair.not-clickable {
  cursor: not-allowed;
}
div.chart-wrapper > div.chart-legend span.legend-pair.not-clickable:hover {
  text-decoration: none;
}
.log-barchart-loading {
  height: 3px;
}
.log-barchart-loading .avi-progress {
  height: 3px;
  color: #3b8dab;
}
div.logBarchartCarat {
  position: absolute;
  z-index: 102;
  width: 0;
  height: 0;
}
div.logBarchartCarat:before {
  border-top-color: #888;
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
}
div.logBarchartCarat:after {
  top: -1px;
  border-top-color: #fff;
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
}
div.logBarchartCarat.inCallout {
  position: fixed;
  z-index: 1001;
}
