body {
  min-width: 768px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-attachment: fixed;
  background-repeat: no-repeat;
  font-size: 14px;
  font-weight: 300;
}
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
  line-height: 0;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.pull-none {
  float: none;
}
.align-bottom {
  vertical-align: bottom;
}
.align-middle {
  vertical-align: middle;
}
.align-top {
  vertical-align: top;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.vertical-align-middle {
  vertical-align: middle;
}
.hide {
  display: none;
}
.hidden {
  display: none !important;
}
.hide-overflow {
  overflow: hidden;
}
span.count {
  font-size: 14px;
  vertical-align: bottom;
}
.descriptionField {
  height: 70px;
}
.avi-select {
  width: 220px;
}
.avi-wide-control {
  min-width: 300px;
  margin-right: 20px;
  margin-left: 40px;
}
.pageSeparator {
  height: 1px;
  margin-top: 15px;
  float: none;
  border-bottom: 1px solid #888;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  display: inline-block;
  position: relative;
  width: 98%;
  margin-right: 1%;
  margin-left: 1%;
  float: left;
}
.top-border-radiuses {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
.bottom-border-radiuses {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.right-border-radiuses {
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
.left-border-radiuses {
  border-top-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.all-border-radiuses {
  border-radius: 3px;
  background-clip: padding-box;
}
.lg-txt {
  font-size: 1.714em;
}
.med-txt {
  font-size: 1em;
}
.sm-txt {
  font-size: 0.857em;
}
.icon-rotate-90:before {
  transform: rotate(90deg);
}
.sub-header-left,
.sub-header-right {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
}
.sub-header-right {
  flex: 0 0 auto;
  justify-content: flex-end;
}
.sub-header-right .item-alert-bell {
  width: 25px;
  height: 25px;
  margin: 0 10px;
  font-size: 20px;
  line-height: 25px;
}
.lg-icon {
  font-size: 20px;
}
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  display: inline-block;
  box-sizing: padding-box;
  height: 35px;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  outline: none;
  font-size: 1em;
}
select[disabled],
textarea[disabled],
input[type="text"][disabled],
input[type="password"][disabled],
input[type="datetime"][disabled],
input[type="datetime-local"][disabled],
input[type="date"][disabled],
input[type="month"][disabled],
input[type="time"][disabled],
input[type="week"][disabled],
input[type="number"][disabled],
input[type="email"][disabled],
input[type="url"][disabled],
input[type="search"][disabled],
input[type="tel"][disabled],
input[type="color"][disabled],
select[readonly],
textarea[readonly],
input[type="text"][readonly],
input[type="password"][readonly],
input[type="datetime"][readonly],
input[type="datetime-local"][readonly],
input[type="date"][readonly],
input[type="month"][readonly],
input[type="time"][readonly],
input[type="week"][readonly],
input[type="number"][readonly],
input[type="email"][readonly],
input[type="url"][readonly],
input[type="search"][readonly],
input[type="tel"][readonly],
input[type="color"][readonly] {
  border: 1px solid #888;
  background: #e5e5e5;
  box-shadow: none;
  color: #666;
}
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea {
  box-sizing: border-box;
  padding: 0 5px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #ccc;
  outline: none;
  font-family: "brandon_text", sans-serif;
  border-radius: 2;
  background-clip: padding-box;
}
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="color"]::placeholder,
textarea::placeholder {
  color: #cccccc;
}
textarea {
  height: 150px;
  padding: 5px;
  line-height: 1.5;
}
.search.input-inline {
  display: inline-block;
  position: relative;
}
.search.input-inline input[type=text] {
  border-radius: 3px;
  background-clip: padding-box;
}
.search.input-inline > div.inner-icon,
.search.input-inline > button {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  color: #888;
  font-size: 1em;
}
.search.input-inline > div.inner-icon {
  line-height: 35px;
}
.search.input-inline > div.inner-icon.highlight-on-hover:hover {
  color: #3b8dab;
}
.search.input-inline > button {
  outline: none;
  background: none;
}
.search.input-inline > button:hover {
  color: #3b8dab;
}
.dashboard-pool,
.infrastructureList,
.applicationList,
.templatesList,
.adminList {
  padding: 0 30px 30px;
}
div.dashboard {
  position: absolute;
  top: 70px;
  right: 0;
  bottom: 10px;
  left: 0;
  width: 98%;
  margin: auto;
  overflow: auto;
}
.absolute-page {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
}
.absolute-page .scroll-container {
  display: block;
  position: relative;
  flex: 1 1;
  padding: 10px 30px;
  overflow: hidden;
  overflow-y: auto;
}
.insights-body-container {
  display: block;
  position: relative;
  flex: 1 1;
  padding: 10px 30px;
  overflow: hidden;
  overflow-y: auto;
}
.avi-main.events .body,
.avi-main.logs .body {
  padding: 10px 30px;
}
.avi-main.events .log-filter-container,
.avi-main.logs .log-filter-container {
  z-index: 1;
  padding: 10px 30px;
}
.avi-main.events .log-filter-container .log-barchart,
.avi-main.logs .log-filter-container .log-barchart {
  margin: 0;
}
.avi-main.alerts .body {
  padding: 0 30px;
}
.avi-main.dns-records .body {
  padding: 5px 30px;
}
.avi-main .body {
  overflow-y: auto;
}
.grid-field-last_signed_in {
  width: 350px;
  vertical-align: middle;
}
.col-16 {
  display: block;
  position: relative;
  width: 98%;
  margin-right: 1%;
  margin-left: 1%;
  float: left;
}
.col-12 {
  display: block;
  position: relative;
  width: 73%;
  margin-right: 1%;
  margin-left: 1%;
  float: left;
}
.col-8 {
  display: block;
  position: relative;
  width: 48%;
  margin-right: 1%;
  margin-left: 1%;
  float: left;
}
.col-4 {
  display: block;
  position: relative;
  width: 23%;
  margin-right: 1%;
  margin-left: 1%;
  float: left;
}
.col-2 {
  display: block;
  position: relative;
  width: 10.5%;
  margin-right: 1%;
  margin-left: 1%;
  float: left;
}
.col-1 {
  display: block;
  position: relative;
  width: 4.25%;
  margin-right: 1%;
  margin-left: 1%;
  float: left;
}
.full-width {
  width: 100%;
  vertical-align: middle;
}
.text-no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-y: auto;
}
.no-wrap {
  white-space: nowrap;
}
.no-margin {
  margin: 0 !important;
}
.noresults {
  display: block;
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: center;
}
.hswrapper:hover {
  text-decoration: none;
}
.h-controls-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
}
.h-controls-group.no-padding {
  padding: 0;
}
.h-controls-group.align-right {
  justify-content: flex-end;
}
.h-controls-group.align-bottom {
  align-items: flex-end;
}
.h-controls-group.align-top {
  align-items: flex-start;
}
.h-controls-group .h-control {
  flex: 1 1 auto;
  max-width: 100%;
  padding-right: 5px;
}
.h-controls-group .h-control label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  vertical-align: baseline;
}
.h-controls-group .h-control i.icon-help-circled {
  vertical-align: baseline;
}
.h-controls-group .h-control .inner-text {
  width: 100%;
}
.h-controls-group .h-control:last-child {
  padding-right: 0;
}
.h-controls-group .h-control.no-flex {
  flex: 0 0 auto;
}
.h-controls-group .h-control.equal {
  flex: 1 1 0;
  min-width: 0;
}
.h-controls-group .h-control.equal.for-two {
  max-width: 50%;
}
.h-controls-group .h-control.flex-11 {
  flex: 1 1 0;
  overflow: hidden;
}
.h-controls-group .h-control.flex-22 {
  flex: 2 2 0;
  overflow: hidden;
}
.h-controls-group .h-control.flex-33 {
  flex: 3 3 0;
  overflow: hidden;
}
.h-controls-group .h-control.flex-4 {
  flex: 0 0 100%;
}
.h-controls-group .h-control.flex-3 {
  flex: 0 0 75%;
}
.h-controls-group .h-control.flex-2 {
  flex: 0 0 50%;
}
.h-controls-group .h-control.flex-1 {
  flex: 0 0 25%;
}
.h-controls-group.with-checkbox {
  align-items: stretch;
}
.h-controls-group.with-checkbox > .h-control.with-checkbox {
  display: flex;
  justify-content: center;
  padding-top: 21px;
}
.h-controls-group.col-gap > .h-control:not(:last-child) {
  padding-right: 2%;
}
.h-controls-group.col-gap > .h-control.equal.for-two {
  max-width: 49%;
}
.h-controls-group .h-col {
  box-sizing: border-box;
  flex: initial;
  width: 50%;
  padding: 5px 0;
}
label.block.relative input[type="text"][required],
label.block.relative input[type="number"][required] {
  width: 100%;
}
.avi-modal-header > h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-y: auto;
}
fieldset {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
fieldset legend {
  padding: 0 5px;
}
.abs-tr-10 {
  position: absolute;
  top: 10px;
  right: 10px;
}
.modal-component {
  display: block;
  z-index: 1050;
  outline: none;
  background-color: white;
  display: none;
  position: fixed;
  box-sizing: unset;
  width: 85%;
  min-width: 850px;
  height: 90%;
  min-height: 200px;
  border: 1px solid #888;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: #666;
  font-family: "brandon_text", sans-serif;
  border-radius: 3px;
  background-clip: padding-box;
  top: 0 !important;
  bottom: 0;
  left: 50% !important;
  width: 55%;
  height: initial;
  transform: translate(-50%);
  border: 0;
  border-radius: initial;
  background: #f1f1f1;
  box-shadow: 0 0 30px 15px rgba(0, 0, 0, 0.5);
  color: #444f51;
  font-size: 14px;
}
.modal-component p {
  margin-bottom: 15px;
}
.modal-component h1 {
  margin: 0;
  padding: 0 15px;
  color: #fff;
  font-size: 1.286em;
  line-height: 44px;
  text-shadow: none;
}
.modal-component h2 {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 5px;
  border-top: 1px solid #888;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.1);
  font-size: 1.286em;
  text-align: center;
}
.modal-component h2:before,
.modal-component h2:after {
  content: "•";
  padding: 0 5px;
}
.modal-component h2.confirmation {
  border: none;
  box-shadow: none;
}
.modal-component h2.confirmation:before,
.modal-component h2.confirmation:after {
  content: "";
}
.modal-component h3 {
  font-size: 1em;
  font-weight: bold;
}
.modal-component .edit-mode h2 {
  border-color: #839c2b;
  color: #839c2b;
}
.modal-component .avi-modal-header {
  position: relative;
  z-index: 10;
  box-sizing: unset;
  height: 44px;
  margin: -1px -1px 0 -1px;
  padding: 0;
  border: 1px solid #000000;
  border-bottom: 2px solid #000000;
  background-color: #2a2a2d;
  line-height: 44px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
.modal-component .avi-modal-header .divider {
  width: 0;
  height: 44px;
  border-right: 1px solid rgba(49, 20, 20, 0.25);
  border-left: 1px solid #000000;
}
.modal-component .avi-modal-header .element-section {
  float: left;
  font-size: 1em;
}
.modal-component .avi-modal-header .close {
  position: relative;
  width: 44px;
  height: 44px;
  margin: 0;
  opacity: 1;
}
.modal-component .avi-modal-header [help-popover-engine] {
  padding: 0 1em;
}
.modal-component .avi-modal-header .close,
.modal-component .avi-modal-header [help-popover-engine] {
  float: right;
  color: #8e8e95;
  text-shadow: none;
}
.modal-component .avi-modal-header .close:hover,
.modal-component .avi-modal-header [help-popover-engine]:hover {
  background-color: #5b5b62;
  color: #fff;
  text-decoration: none;
}
.modal-component .avi-modal-header .close:hover.disabled,
.modal-component .avi-modal-header [help-popover-engine]:hover.disabled {
  background: inherit;
  color: #8e8e95;
}
.modal-component .pageSeparator {
  width: 100%;
  margin-left: -15px;
  padding-right: 30px;
}
.modal-component .avi-modal-body {
  position: absolute;
  top: 47px;
  bottom: 45px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 15px;
  overflow-y: auto;
}
.modal-component .avi-modal-body.reduced-height {
  top: 77px;
}
.modal-component .scrollable {
  overflow-x: hidden;
}
.modal-component .modal-section {
  margin-top: 30px;
  margin-bottom: 30px;
}
.modal-component .summary h1.name-summary {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #888;
  color: #414666;
  font-size: 1.286em;
}
.modal-component .summary .information-summary {
  display: block;
}
.modal-component .summary .information-summary label {
  font-weight: bold;
}
.modal-component .summary .information-summary p {
  font-size: 1em;
}
.modal-component .avi-modal-footer {
  display: block;
  position: absolute;
  bottom: 1px;
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding: 0;
  border-top: 1px solid #888;
  background-color: #fff;
  box-shadow: inset 0 1px 0 #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.modal-component .avi-modal-footer button.pull-left,
.modal-component .avi-modal-footer button.pull-right {
  height: 45px;
  margin: 0;
}
.modal-component .avi-modal-footer button.pull-left.avi-btn,
.modal-component .avi-modal-footer button.pull-right.avi-btn {
  min-width: 75px;
}
.modal-component .avi-modal-footer button.pull-left.avi-btn:hover,
.modal-component .avi-modal-footer button.pull-right.avi-btn:hover {
  border-bottom-width: 0;
}
.modal-component .avi-modal-footer button.pull-left {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
  border-width: 0 1px 0 0;
}
.modal-component .avi-modal-footer button.pull-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
  border-width: 0 0 0 1px;
}
.modal-component .avi-modal-footer button.prev {
  border-radius: 0;
  background-clip: padding-box;
}
.modal-component .avi-modal-footer .margin9 {
  margin: 8px 9px 9px 9px;
}
.modal-component .avi-modal-footer > div[avi-loader] {
  margin-top: 0.75em;
}
.modal-component .modal-confirm {
  display: block;
  position: absolute;
  z-index: 10001;
  top: 46px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 10%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.modal-component .modal-section {
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.modal-component .modal-section:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.modal-component .modal-section-title {
  margin: 0 0 1em 0;
  padding: 0;
  border: 0;
  box-shadow: none;
  font-size: 24px;
  text-align: left;
}
.modal-component .modal-section-title:before,
.modal-component .modal-section-title:after {
  content: '';
  display: none;
}
.modal-component .avi-modal-header {
  box-sizing: border-box;
  height: 85px;
  margin: 0;
  padding: 20px 0;
  border: 0;
  border-bottom: 1px solid #c4c4c4;
  background: transparent;
  color: #444F51;
}
.modal-component .avi-modal-header h1 {
  padding-right: 44px;
}
.modal-component .avi-modal-header button.close {
  position: relative;
  width: 44px;
  height: 44px;
  margin: 0 10px 0 0;
  color: inherit;
  font-size: 24px;
  font-weight: normal;
  text-shadow: none;
}
.modal-component .avi-modal-header button.close:hover {
  background-color: transparent;
  color: inherit;
}
.modal-component .avi-modal-header .divider {
  width: 0;
  border: 0;
}
.modal-component .avi-modal-body {
  top: 85px;
  bottom: 85px;
  box-sizing: border-box;
  padding: 20px 50px;
}
.modal-component .control-group label,
.modal-component .h-control label {
  font-weight: 500;
}
.modal-component collection-dropdown {
  float: none;
}
.modal-component .avi-modal-footer {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 85px;
  padding: 20px;
  border-top: 1px solid #c4c4c4;
  background-color: transparent;
}
.modal-component .avi-modal-footer .avi-btn-primary,
.modal-component .avi-modal-footer .avi-btn-default {
  min-width: 150px;
  height: 40px;
}
.modal-component h1 {
  background: none;
  color: #444f51;
  font-size: 24px;
  font-weight: 500;
}
.modal-component h3 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: inherit;
}
.modal-component h4 {
  font-size: 16px;
  font-weight: inherit;
}
.modal-component .duration-combo-box .add-on {
  background-color: white;
}
.modal-component .control-group > label,
.modal-component label.control-label {
  margin: 0 0 5px 2px;
  color: #444f51;
  font-size: 14px;
  font-weight: 500;
}
.modal-component .checkbox-label-on-top .controls {
  box-sizing: border-box;
  padding-top: 10px;
}
.modal-component .link {
  color: #8ead1f;
}
.modal-component .link:hover {
  text-decoration: none;
}
.modal-component .trash-button {
  font-size: 25px;
  cursor: pointer;
}
.modal-component .modal-confirm {
  display: flex;
  top: 0;
  bottom: 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.74);
}
.modal-component .modal-confirm h2.confirmation {
  margin: 0 0 2em;
  padding: 0;
  color: white;
}
.modal-component .close-button {
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 20px;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  background: transparent;
  cursor: pointer;
}
.modal-component .close-button:before {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  transform: rotate(45deg);
  border-bottom: 1px solid;
}
.modal-component .close-button:after {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  transform: rotate(-45deg);
  border-bottom: 1px solid;
}
.modal-component.xl-padding > .avi-modal-header > h1 {
  padding: 0 100px;
}
.modal-component.xl-padding > .avi-modal-body {
  padding: 15px 100px;
}
.modal-component section {
  margin: 30px 0;
}
.modal-component .warning {
  align-self: center;
}
.avi-confirm {
  z-index: 1050;
  outline: none;
  background-color: white;
  display: none;
  position: fixed;
  box-sizing: unset;
  width: 85%;
  min-width: 850px;
  height: 90%;
  min-height: 200px;
  border: 1px solid #888;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: #666;
  font-family: "brandon_text", sans-serif;
  border-radius: 3px;
  background-clip: padding-box;
  min-width: 500px;
  height: 400px;
  margin-right: 7.5%;
  margin-left: 12.5%;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-duration: 150ms;
  animation-name: pulse;
  height: 250px;
  width: 60%;
  margin: 0 12.5%;
}
.avi-confirm p {
  margin-bottom: 15px;
}
.avi-confirm h1 {
  margin: 0;
  padding: 0 15px;
  color: #fff;
  font-size: 1.286em;
  line-height: 44px;
  text-shadow: none;
}
.avi-confirm h2 {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 5px;
  border-top: 1px solid #888;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.1);
  font-size: 1.286em;
  text-align: center;
}
.avi-confirm h2:before,
.avi-confirm h2:after {
  content: "•";
  padding: 0 5px;
}
.avi-confirm h2.confirmation {
  border: none;
  box-shadow: none;
}
.avi-confirm h2.confirmation:before,
.avi-confirm h2.confirmation:after {
  content: "";
}
.avi-confirm h3 {
  font-size: 1em;
  font-weight: bold;
}
.avi-confirm .edit-mode h2 {
  border-color: #839c2b;
  color: #839c2b;
}
.avi-confirm .avi-modal-header {
  position: relative;
  z-index: 10;
  box-sizing: unset;
  height: 44px;
  margin: -1px -1px 0 -1px;
  padding: 0;
  border: 1px solid #000000;
  border-bottom: 2px solid #000000;
  background-color: #2a2a2d;
  line-height: 44px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
.avi-confirm .avi-modal-header .divider {
  width: 0;
  height: 44px;
  border-right: 1px solid rgba(49, 20, 20, 0.25);
  border-left: 1px solid #000000;
}
.avi-confirm .avi-modal-header .element-section {
  float: left;
  font-size: 1em;
}
.avi-confirm .avi-modal-header .close {
  position: relative;
  width: 44px;
  height: 44px;
  margin: 0;
  opacity: 1;
}
.avi-confirm .avi-modal-header [help-popover-engine] {
  padding: 0 1em;
}
.avi-confirm .avi-modal-header .close,
.avi-confirm .avi-modal-header [help-popover-engine] {
  float: right;
  color: #8e8e95;
  text-shadow: none;
}
.avi-confirm .avi-modal-header .close:hover,
.avi-confirm .avi-modal-header [help-popover-engine]:hover {
  background-color: #5b5b62;
  color: #fff;
  text-decoration: none;
}
.avi-confirm .avi-modal-header .close:hover.disabled,
.avi-confirm .avi-modal-header [help-popover-engine]:hover.disabled {
  background: inherit;
  color: #8e8e95;
}
.avi-confirm .pageSeparator {
  width: 100%;
  margin-left: -15px;
  padding-right: 30px;
}
.avi-confirm .avi-modal-body {
  position: absolute;
  top: 47px;
  bottom: 45px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 15px;
  overflow-y: auto;
}
.avi-confirm .avi-modal-body.reduced-height {
  top: 77px;
}
.avi-confirm .scrollable {
  overflow-x: hidden;
}
.avi-confirm .modal-section {
  margin-top: 30px;
  margin-bottom: 30px;
}
.avi-confirm .summary h1.name-summary {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #888;
  color: #414666;
  font-size: 1.286em;
}
.avi-confirm .summary .information-summary {
  display: block;
}
.avi-confirm .summary .information-summary label {
  font-weight: bold;
}
.avi-confirm .summary .information-summary p {
  font-size: 1em;
}
.avi-confirm .avi-modal-footer {
  display: block;
  position: absolute;
  bottom: 1px;
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding: 0;
  border-top: 1px solid #888;
  background-color: #fff;
  box-shadow: inset 0 1px 0 #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.avi-confirm .avi-modal-footer button.pull-left,
.avi-confirm .avi-modal-footer button.pull-right {
  height: 45px;
  margin: 0;
}
.avi-confirm .avi-modal-footer button.pull-left.avi-btn,
.avi-confirm .avi-modal-footer button.pull-right.avi-btn {
  min-width: 75px;
}
.avi-confirm .avi-modal-footer button.pull-left.avi-btn:hover,
.avi-confirm .avi-modal-footer button.pull-right.avi-btn:hover {
  border-bottom-width: 0;
}
.avi-confirm .avi-modal-footer button.pull-left {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
  border-width: 0 1px 0 0;
}
.avi-confirm .avi-modal-footer button.pull-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
  border-width: 0 0 0 1px;
}
.avi-confirm .avi-modal-footer button.prev {
  border-radius: 0;
  background-clip: padding-box;
}
.avi-confirm .avi-modal-footer .margin9 {
  margin: 8px 9px 9px 9px;
}
.avi-confirm .avi-modal-footer > div[avi-loader] {
  margin-top: 0.75em;
}
.avi-confirm .modal-confirm {
  display: block;
  position: absolute;
  z-index: 10001;
  top: 46px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 10%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.avi-confirm .avi-modal-header {
  border: 1px solid #a03b12;
  border-bottom: 2px solid #a03b12;
  background-color: #ce4b18;
}
.avi-confirm .avi-modal-header .close {
  color: #722a0d;
}
.avi-confirm .avi-modal-header .close:hover {
  background-color: #EA7648;
  color: #fff;
}
.avi-confirm .avi-modal-header .divider {
  border-left-color: #a03b12;
}
.avi-confirm.single {
  height: 250px;
}
.avi-confirm .avi-modal-body {
  height: 310px;
  min-height: 200px;
  margin: 0;
  padding: 10px;
  vertical-align: middle;
}
.avi-confirm .avi-modal-body .single-error {
  height: 90px;
  color: #ce4b18;
  text-align: center;
}
.avi-confirm .avi-modal-body .error-list {
  color: #EA7648;
}
.avi-confirm .avi-modal-body .error-list div {
  padding: 10px 15px;
}
.avi-confirm .avi-modal-body .error-list .warning {
  margin: 5px 0;
}
.avi-confirm.fadeInLeft,
.avi-confirm.fadeInRight {
  animation-duration: 250ms;
}
.avi-prompt {
  z-index: 1050;
  outline: none;
  background-color: white;
  display: none;
  position: fixed;
  box-sizing: unset;
  width: 85%;
  min-width: 850px;
  height: 90%;
  min-height: 200px;
  border: 1px solid #888;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  color: #666;
  font-family: "brandon_text", sans-serif;
  border-radius: 3px;
  background-clip: padding-box;
  font-size: 14px;
  max-width: 900px;
}
.avi-prompt p {
  margin-bottom: 15px;
}
.avi-prompt h1 {
  margin: 0;
  padding: 0 15px;
  color: #fff;
  font-size: 1.286em;
  line-height: 44px;
  text-shadow: none;
}
.avi-prompt h2 {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 5px;
  border-top: 1px solid #888;
  box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.1);
  font-size: 1.286em;
  text-align: center;
}
.avi-prompt h2:before,
.avi-prompt h2:after {
  content: "•";
  padding: 0 5px;
}
.avi-prompt h2.confirmation {
  border: none;
  box-shadow: none;
}
.avi-prompt h2.confirmation:before,
.avi-prompt h2.confirmation:after {
  content: "";
}
.avi-prompt h3 {
  font-size: 1em;
  font-weight: bold;
}
.avi-prompt .edit-mode h2 {
  border-color: #839c2b;
  color: #839c2b;
}
.avi-prompt .avi-modal-header {
  position: relative;
  z-index: 10;
  box-sizing: unset;
  height: 44px;
  margin: -1px -1px 0 -1px;
  padding: 0;
  border: 1px solid #000000;
  border-bottom: 2px solid #000000;
  background-color: #2a2a2d;
  line-height: 44px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
.avi-prompt .avi-modal-header .divider {
  width: 0;
  height: 44px;
  border-right: 1px solid rgba(49, 20, 20, 0.25);
  border-left: 1px solid #000000;
}
.avi-prompt .avi-modal-header .element-section {
  float: left;
  font-size: 1em;
}
.avi-prompt .avi-modal-header .close {
  position: relative;
  width: 44px;
  height: 44px;
  margin: 0;
  opacity: 1;
}
.avi-prompt .avi-modal-header [help-popover-engine] {
  padding: 0 1em;
}
.avi-prompt .avi-modal-header .close,
.avi-prompt .avi-modal-header [help-popover-engine] {
  float: right;
  color: #8e8e95;
  text-shadow: none;
}
.avi-prompt .avi-modal-header .close:hover,
.avi-prompt .avi-modal-header [help-popover-engine]:hover {
  background-color: #5b5b62;
  color: #fff;
  text-decoration: none;
}
.avi-prompt .avi-modal-header .close:hover.disabled,
.avi-prompt .avi-modal-header [help-popover-engine]:hover.disabled {
  background: inherit;
  color: #8e8e95;
}
.avi-prompt .pageSeparator {
  width: 100%;
  margin-left: -15px;
  padding-right: 30px;
}
.avi-prompt .avi-modal-body {
  position: absolute;
  top: 47px;
  bottom: 45px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 15px;
  overflow-y: auto;
}
.avi-prompt .avi-modal-body.reduced-height {
  top: 77px;
}
.avi-prompt .scrollable {
  overflow-x: hidden;
}
.avi-prompt .modal-section {
  margin-top: 30px;
  margin-bottom: 30px;
}
.avi-prompt .summary h1.name-summary {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #888;
  color: #414666;
  font-size: 1.286em;
}
.avi-prompt .summary .information-summary {
  display: block;
}
.avi-prompt .summary .information-summary label {
  font-weight: bold;
}
.avi-prompt .summary .information-summary p {
  font-size: 1em;
}
.avi-prompt .avi-modal-footer {
  display: block;
  position: absolute;
  bottom: 1px;
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding: 0;
  border-top: 1px solid #888;
  background-color: #fff;
  box-shadow: inset 0 1px 0 #fff;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.avi-prompt .avi-modal-footer button.pull-left,
.avi-prompt .avi-modal-footer button.pull-right {
  height: 45px;
  margin: 0;
}
.avi-prompt .avi-modal-footer button.pull-left.avi-btn,
.avi-prompt .avi-modal-footer button.pull-right.avi-btn {
  min-width: 75px;
}
.avi-prompt .avi-modal-footer button.pull-left.avi-btn:hover,
.avi-prompt .avi-modal-footer button.pull-right.avi-btn:hover {
  border-bottom-width: 0;
}
.avi-prompt .avi-modal-footer button.pull-left {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
  border-width: 0 1px 0 0;
}
.avi-prompt .avi-modal-footer button.pull-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
  border-width: 0 0 0 1px;
}
.avi-prompt .avi-modal-footer button.prev {
  border-radius: 0;
  background-clip: padding-box;
}
.avi-prompt .avi-modal-footer .margin9 {
  margin: 8px 9px 9px 9px;
}
.avi-prompt .avi-modal-footer > div[avi-loader] {
  margin-top: 0.75em;
}
.avi-prompt .modal-confirm {
  display: block;
  position: absolute;
  z-index: 10001;
  top: 46px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 10%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.avi-prompt .avi-modal-footer {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 85px;
  padding: 20px;
  border-top: 1px solid #c4c4c4;
  background-color: transparent;
}
.avi-prompt .avi-modal-footer .avi-btn-primary,
.avi-prompt .avi-modal-footer .avi-btn-default {
  min-width: 150px;
  height: 40px;
}
.avi-prompt h1 {
  background: none;
  color: #444f51;
  font-size: 24px;
  font-weight: 500;
}
.avi-prompt h3 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: inherit;
}
.avi-prompt h4 {
  font-size: 16px;
  font-weight: inherit;
}
.avi-prompt .duration-combo-box .add-on {
  background-color: white;
}
.avi-prompt .control-group > label,
.avi-prompt label.control-label {
  margin: 0 0 5px 2px;
  color: #444f51;
  font-size: 14px;
  font-weight: 500;
}
.avi-prompt .checkbox-label-on-top .controls {
  box-sizing: border-box;
  padding-top: 10px;
}
.avi-prompt .link {
  color: #8ead1f;
}
.avi-prompt .link:hover {
  text-decoration: none;
}
.avi-prompt .trash-button {
  font-size: 25px;
  cursor: pointer;
}
.avi-prompt .modal-confirm {
  display: flex;
  top: 0;
  bottom: 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0.74);
}
.avi-prompt .modal-confirm h2.confirmation {
  margin: 0 0 2em;
  padding: 0;
  color: white;
}
.avi-prompt .close-button {
  display: inline-block;
  position: absolute;
  top: 25px;
  right: 20px;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  background: transparent;
  cursor: pointer;
}
.avi-prompt .close-button:before {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  transform: rotate(45deg);
  border-bottom: 1px solid;
}
.avi-prompt .close-button:after {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  transform: rotate(-45deg);
  border-bottom: 1px solid;
}
.avi-prompt.xl-padding > .avi-modal-header > h1 {
  padding: 0 100px;
}
.avi-prompt.xl-padding > .avi-modal-body {
  padding: 15px 100px;
}
.avi-prompt section {
  margin: 30px 0;
}
.avi-prompt .warning {
  align-self: center;
}
.avi-prompt .avi-modal-header {
  min-height: initial;
}
.avi-prompt h1 {
  padding: 0;
  font-weight: inherit;
}
.avi-prompt .control-group > label {
  margin: initial;
}
textarea.no-resize {
  resize: none;
}
.table-layout {
  width: 100%;
  table-layout: auto;
}
.table-layout td:not(:last-child),
.table-layout th:not(:last-child) {
  padding-right: 2%;
  vertical-align: middle;
}
.col-layout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.col-layout > .col {
  flex: 1 1 0;
  min-width: 0;
}
.col-layout > .col:not(:last-child) {
  padding-right: 2%;
}
.col-layout > .col.col-half {
  max-width: 49%;
}
.col-layout > .col.collapsed {
  flex: 0 0 auto;
  width: auto;
}
[vs-repeat] {
  overflow-anchor: none;
}
.application-profile-modal__description {
  height: 70px;
}
