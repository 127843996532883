.waf-policy-whitelist-rule__expander {
  display: block;
  margin-bottom: 2px;
}
.waf-policy-whitelist-rule__expander-opener {
  background: white;
}
.waf-policy-whitelist-rule__opener {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 50px;
  padding: 10px;
}
.waf-policy-whitelist-rule__name {
  padding-left: 10px;
  font-weight: 600;
}
.waf-policy-whitelist-rule__buttons-container {
  display: flex;
}
.waf-policy-whitelist-rule__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 50px;
  background: #263242;
  color: white;
  cursor: pointer;
}
.waf-policy-whitelist-rule__button:hover {
  color: #ff4B00;
}
.waf-policy-whitelist-rule__drag-icon {
  padding-right: 10px;
}
