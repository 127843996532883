.client-insight-nav-timing-chart .timing-container,
.client-insight-nav-timing-chart .timing-container-inner {
  border-radius: 3px;
  background-clip: padding-box;
}
.client-insight-nav-timing-chart .timing-container {
  height: 31px;
  margin-top: -10px;
  line-height: 0;
}
.client-insight-nav-timing-chart .timing-container .pltAndDlt.bar {
  position: absolute;
  height: 20px;
}
.client-insight-nav-timing-chart .bar-mark {
  position: absolute;
  z-index: 1;
  width: 3px;
  margin-top: -16px;
  margin-left: -1.5px;
  vertical-align: bottom;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
.client-insight-nav-timing-chart .bar-mark.page_download_time {
  height: 36px;
  border-bottom-right-radius: 3px;
}
.client-insight-nav-timing-chart .bar-mark-placeholder {
  width: 6px;
}
