.bgp-table {
  margin-bottom: 20px;
  text-align: left;
}
.bgp-table td,
.bgp-table th {
  max-width: 125px;
  padding: 0 2em 0.5em 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bgp-table tbody tr td {
  font-size: 1.15em;
  font-weight: bold;
}
.bgp-table tbody tr td.center {
  text-align: center;
}
