.pie-chart-dashboard {
  position: relative;
}
.pie-chart-dashboard .legend {
  flex: 1 0 25%;
  height: 158px;
}
.pie-chart-dashboard .highlight0,
.pie-chart-dashboard .highlight1 {
  position: absolute;
}
.pie-chart-dashboard .highlight0 {
  top: -10px;
  left: 0;
}
.pie-chart-dashboard .highlight1 {
  right: 10px;
  bottom: -20px;
}
.pie-chart-dashboard div.pie-chart {
  flex: 1 0 70%;
}
.pie-chart-dashboard div.pie-chart > svg {
  position: initial;
}
.pie-chart-dashboard .security-pct-txt {
  font-size: 1.3em;
}
@media (max-width: 1152px) {
  .pie-chart-dashboard .security-pct-txt {
    font-size: 1em;
  }
}
