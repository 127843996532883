.item-alert-bell__icon_level_high {
  color: #ce4b18;
}
.item-alert-bell__icon_level_medium {
  color: #dca512;
}
.item-alert-bell__icon_level_low {
  color: #3b8dab;
}
.aviPopover.with-item-alert-bell-popover {
  z-index: 1000;
}
