.pool-group-deployment-policy-modal__cell:not(:first-child) {
  padding-left: 0.5%;
}
.pool-group-deployment-policy-modal__cell:not(:last-child) {
  padding-right: 0.5%;
}
.pool-group-deployment-policy-modal__rules-table {
  width: 100%;
}
.pool-group-deployment-policy-modal__table-trash-bin-col {
  width: 15px;
}
