div[pie-chart] {
  position: relative;
}
div[pie-chart] > svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
div[pie-chart] > svg path.placeholder {
  stroke-width: 2;
  stroke: #CCC;
  fill: none;
}
