.plt-down-arrow {
  height: 10px;
  margin-top: 2px;
}
.plt-down-arrow .box {
  height: 2px;
  background-color: #AFCC48;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.plt-down-arrow .box .down-arrow {
  width: 0;
  height: 0;
  margin: auto;
  border-width: 8px 5px 0 5px;
  border-style: solid;
  border-color: #AFCC48 transparent transparent transparent;
}
