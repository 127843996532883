.avi-confirmation__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 1.125rem;
}
.avi-confirmation__header-text {
  padding-top: 4px;
  overflow: hidden;
  color: #ff4B00;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.avi-confirmation__header-close-button {
  align-self: flex-start;
  cursor: pointer;
  justify-self: flex-start;
}
.avi-confirmation__body {
  box-sizing: border-box;
  width: 600px;
  height: auto;
  max-height: 500px;
  padding: 0 16px;
  overflow-y: auto;
}
.avi-confirmation__body p {
  margin-bottom: 20px;
}
.avi-confirmation__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: #eee;
}
