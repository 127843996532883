.waf-policy-psm-group-expander-content {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background: white;
}
.waf-policy-psm-group-expander-content__action {
  display: flex;
  padding-right: 15px;
}
.waf-policy-psm-group-expander-content__action-label {
  padding-right: 10px;
  font-weight: 600;
}
