.collapsible-list__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
}
.collapsible-list__header--clickable {
  cursor: pointer;
}
.collapsible-list__button {
  cursor: pointer;
}
.collapsible-list__toggle {
  flex: 0 0 auto;
}
.collapsible-list__waf-item:not(.visible) {
  display: none !important;
}
