.vs-waf-top-hits {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.vs-waf-top-hits_hits-wrapper {
  display: inherit;
  flex-wrap: wrap;
  width: 100%;
}
.vs-waf-top-hits_hits-wrapper > waf-top-hits-container {
  box-sizing: border-box;
  flex: 1 1 auto;
  width: 30%;
  margin: 0 10px;
}
.vs-waf-top-hits_hits-wrapper > waf-top-hits-container:nth-child(n + 4) {
  margin-top: 20px;
}
.vs-waf-top-hits_hits-wrapper > waf-top-hits-container:nth-child(-3n + 6) {
  margin-right: 0;
}
.vs-waf-top-hits_hits-wrapper > waf-top-hits-container:nth-child(-3n + 4) {
  margin-left: 0;
}
.vs-waf-top-hits_hits-wrapper > waf-top-hits-container:nth-child(4) {
  width: 18%;
}
.vs-waf-top-hits_hits-wrapper > waf-top-hits-container:nth-child(6) {
  width: 42%;
}
.vs-waf-top-hits_hits-wrapper > waf-top-hits-container.combination {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.vs-waf-top-hits_buttons-row {
  display: flex;
  width: 100%;
  padding: 10px 0;
}
.vs-waf-top-hits_buttons-row-right,
.vs-waf-top-hits_buttons-row-left {
  display: flex;
  width: 50%;
}
.vs-waf-top-hits_buttons-row-left {
  justify-content: flex-start;
}
.vs-waf-top-hits_buttons-row-right {
  justify-content: flex-end;
}
.vs-waf-top-hits_buttons-row button {
  transition: all 0.1s linear;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-height: 35px;
  padding: 0 20px;
  overflow: hidden;
  border-radius: 3px;
  outline: 0;
  background-color: #999;
  color: white;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  margin: 0 5px;
}
.vs-waf-top-hits_buttons-row button:hover,
.vs-waf-top-hits_buttons-row button:active {
  background-color: #666;
}
.vs-waf-top-hits_buttons-row button.avi-btn-primary,
.vs-waf-top-hits_buttons-row button.avi-btn-success {
  border: 0;
  background-color: #8ead1f;
  color: white;
}
.vs-waf-top-hits_buttons-row button.avi-btn-primary:hover,
.vs-waf-top-hits_buttons-row button.avi-btn-success:hover,
.vs-waf-top-hits_buttons-row button.avi-btn-primary:active,
.vs-waf-top-hits_buttons-row button.avi-btn-success:active {
  background-color: #7a9518;
}
.vs-waf-top-hits_buttons-row button.avi-btn-primary i,
.vs-waf-top-hits_buttons-row button.avi-btn-success i {
  color: inherit;
}
.vs-waf-top-hits_buttons-row button.avi-btn-danger {
  border: 0;
  color: white;
}
.vs-waf-top-hits_buttons-row button.avi-btn-danger,
.vs-waf-top-hits_buttons-row button.avi-btn-danger:hover {
  background-color: #EA7648;
}
.vs-waf-top-hits_buttons-row button.avi-btn-transparent {
  padding: 0 5px;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  color: #666;
}
.vs-waf-top-hits_buttons-row button.avi-btn-transparent:hover {
  background-color: rgba(158, 158, 158, 0.2);
}
.vs-waf-top-hits_buttons-row button.icon-button {
  display: flex;
  width: 35px;
  padding: 0;
  color: #666;
  font-size: 20px;
}
.vs-waf-top-hits_buttons-row button.icon-button:not(.avi-btn-transparent),
.vs-waf-top-hits_buttons-row button.icon-button:not(.avi-btn-transparent):hover {
  border: 1px solid #ccc;
  background-color: white;
}
.vs-waf-top-hits_buttons-row button.icon-button .big {
  width: 50px;
  height: 50px;
  font-size: 25px;
}
.vs-waf-top-hits_buttons-row button.disabled,
.vs-waf-top-hits_buttons-row button[disabled] {
  cursor: default;
  pointer-events: none;
}
.vs-waf-top-hits_buttons-row button.disabled,
.vs-waf-top-hits_buttons-row button[disabled],
.vs-waf-top-hits_buttons-row button.disabled:hover,
.vs-waf-top-hits_buttons-row button[disabled]:hover,
.vs-waf-top-hits_buttons-row button.disabled:active,
.vs-waf-top-hits_buttons-row button[disabled]:active {
  background-color: #CCC;
  color: #888;
}
.vs-waf-top-hits_buttons-row button.avi-btn-sm {
  padding: 5px;
  font-size: 12px;
}
.vs-waf-top-hits_buttons-row button:last-child {
  margin-right: 0;
}
.vs-waf-top-hits_buttons-row button:first-child {
  margin-left: 0;
}
.waf-top-hits-container {
  display: flex;
  position: relative;
  flex-direction: column;
  border: 1px solid #888;
  border-radius: 3px;
  background-color: white;
}
.waf-top-hits-container [avi-loader] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  opacity: 0.75;
}
.waf-top-hits-container__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.waf-top-hits-container__title {
  font-size: 16px;
}
.waf-top-hits-container__label {
  color: #999;
  font-size: 10px;
  text-transform: uppercase;
}
.waf-top-hits-container__grid {
  width: 100%;
  height: 150px;
  overflow-y: auto;
}
.waf-top-hits-container__list-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 7px 10px;
  border-bottom: 1px solid #ccc;
}
.waf-top-hits-container__list-item:hover {
  background-color: #e5e5e5;
}
.waf-top-hits-container__list-item:nth-child(n + 5):last-child {
  border-bottom: none;
}
.waf-top-hits-container__list-item--selected {
  background-color: #f2f2f2;
}
.waf-top-hits-container__hit {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  font-weight: 500;
}
.waf-top-hits-container__hit--name {
  flex-grow: 1;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.waf-top-hits-container__hit--total {
  display: flex;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.waf-top-hits-container__total {
  display: flex;
  height: 30px;
  background-color: #e5e5e5;
  color: #888;
  text-transform: uppercase;
  pointer-events: none;
}
.waf-top-hits-container__total .waf-top-hits-container__list-item {
  border-bottom: 0;
}
.waf-top-hits-container__waf-enable {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
}
.waf-exception-preview__exceptions-container {
  margin: 10px;
  border: 1px solid #b3b3b3;
  border-radius: 3px;
  background-color: white;
}
.waf-exception-preview__exception-group:not(:last-child) {
  border-bottom: 1px solid #b3b3b3;
}
.waf-exception-preview__exception-container {
  display: flex;
}
.waf-exception-preview__exception-container:not(:last-child) {
  border-bottom: 1px solid #b3b3b3;
}
.waf-exception-preview__close-button-container {
  position: relative;
  flex: 0 0 20px;
}
.waf-exception-preview__close-button {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  background: transparent;
  cursor: pointer;
}
.waf-exception-preview__close-button:before {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  transform: rotate(45deg);
  border-bottom: 1px solid;
}
.waf-exception-preview__close-button:after {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  transform: rotate(-45deg);
  border-bottom: 1px solid;
}
.waf-exception-preview__exception {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  width: 92%;
  padding: 10px 5px 10px 7px;
}
.waf-exception-preview__exception--rule {
  padding-left: 12px;
}
.waf-exception-preview__exception-name {
  padding: 7px;
  border-bottom: 1px solid #b3b3b3;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.waf-exception-preview__exception-name--rule {
  padding-left: 12px;
}
.waf-exception-preview__exception-capsule:not(:last-child) {
  margin-bottom: 5px;
}
.waf-exception-preview__add-exception-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.waf-exception-preview__empty-list {
  margin-top: 20px;
  text-align: center;
}
.waf-enable--disabled {
  color: #cccccc;
}
.waf-enable__data {
  display: flex;
  flex-grow: 1;
  min-width: 0;
}
.waf-enable__name {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
}
.vs-waf-page_main-viewport {
  display: block;
  flex-grow: 1;
  padding: 10px 30px;
  overflow-y: auto;
}
.vs-waf-page_top-charts {
  display: flex;
  flex-shrink: 0;
}
.vs-waf-page .vs-waf-top-hits {
  flex-shrink: 0;
}
.vs-waf-page .vs-waf-top-enforcements {
  margin-top: 2em;
}
.vs-waf-page .two-column-container .left-section {
  overflow-y: scroll;
}
