.waf-psm-match-element-config-form {
  background-color: #f1f1f1;
}
.waf-psm-match-element-config-form__header {
  display: flex;
  justify-content: flex-end;
}
.waf-psm-match-element-config-form__close-button {
  background: none;
}
.waf-psm-match-element-config-form__content {
  padding: 5px 10px;
}
