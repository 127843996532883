.avi-header {
  display: flex;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  flex-direction: column;
  min-width: 768px;
  height: 40px;
  background-color: #434343;
  color: white;
}
.avi-header__navigation {
  display: flex;
}
.avi-header__category-navbar {
  flex: 1;
}
.avi-header__tenant-selector {
  margin-right: 5px;
}
