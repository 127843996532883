.tri-switch__container {
  width: 50px;
  cursor: pointer;
}
.tri-switch__switch {
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: flex-end;
  padding: 2px;
  border-radius: 25px;
  background-color: #A1BF36;
}
.tri-switch__circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: white;
}
.tri-switch__indeterminate {
  width: 12px;
  height: 2px;
  margin-right: 7px;
  background-color: white;
}
.tri-switch--disabled {
  background-color: #cccccc;
  display: flex;
  justify-content: flex-start;
}
.tri-switch--gray {
  background-color: #cccccc;
}
.tri-switch[disabled] .tri-switch__switch {
  background-color: #e5e5e5;
}
.tri-switch--small .tri-switch__container {
  width: 28px;
  padding: 1px;
  border: 1px solid #888;
  border-radius: 25px;
}
.tri-switch--small .tri-switch__circle {
  width: 10px;
  height: 10px;
}
.tri-switch--small[disabled] {
  pointer-events: none;
}
.tri-switch--small[disabled] .tri-switch__container {
  border-color: #e5e5e5;
}
