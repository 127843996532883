.tech-support {
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}
.tech-support .control-group {
  margin-bottom: 0;
}
.tech-support .control-group--vertical-gap {
  margin-bottom: 15px;
}
.tech-support--gap--none {
  grid-column-gap: 0;
  grid-row-gap: 0;
}
.tech-support--gap--legacy {
  grid-column-gap: 2%;
  grid-row-gap: 15px;
}
.tech-support__capture-section {
  display: flex;
  align-items: center;
  height: 50px;
  border: 1px solid #E2E6EA;
  border-radius: 2px;
  background-color: white;
}
.tech-support__capture-section--ready {
  justify-content: center;
}
.tech-support__capture-section--in-progress {
  padding-left: 10px;
}
.tech-support__capture-section-resource-name,
.tech-support__capture-section-complete-message {
  font-weight: 500;
}
.tech-support__capture-section-text {
  display: inline-block;
  padding-left: 3px;
}
.tech-support-expander {
  margin-left: 90px;
}
.tech-support-expander__file-name-label {
  margin-right: 5px;
}
