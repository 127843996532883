.traffic-capture {
  padding: 10px 30px;
}
.traf-cap-switch-text {
  line-height: 1.8em;
}
.traf-cap-grid th.rowactions {
  width: 5%;
}
virtualservice-traffic-capture-status {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  display: block;
  position: relative;
  width: 50%;
  margin-right: 0%;
  margin-left: 0%;
  float: none;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 15px;
  background-color: #fff;
}
.traffic-capture .progress {
  height: 8px;
  padding: 1px;
  border: 1px solid #888;
  border-radius: 5px;
}
.traffic-capture .progress .bar {
  display: inline-block;
  height: 8px;
  transition: width 1s;
  border-radius: 3px;
  background-color: #B5DA2F;
}
