log-timeline {
  display: block;
  position: relative;
}
log-timeline > div.wrap {
  display: flex;
  position: relative;
  min-width: 4px;
  height: 18px;
  overflow: hidden;
  border-radius: 5px;
}
log-timeline > div.wrap > div {
  display: inline-block;
  min-width: 1px;
  height: 100%;
  margin: 0;
  padding: 0;
}
log-timeline > div.duration {
  position: absolute;
  top: 3px;
  height: 1em;
  padding-left: 0.3em;
  font-size: small;
  line-height: 1em;
}
log-timeline .c-rtt {
  background-color: #AFCC48;
}
log-timeline .s-rtt {
  background-color: #67B0CB;
}
log-timeline .app-resp {
  background-color: #EEBB33;
}
log-timeline .data-tr {
  background-color: #AD73E8;
}
log-timeline .total {
  background-color: #cccccc;
}
log-timeline .waf-tt {
  background-color: #49A1C1;
}
log-timeline svg.log-timeline-request-chart {
  display: block;
}
.log-timeline-popover {
  position: absolute;
  z-index: 1000;
  width: 232px;
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background: #fff;
}
.log-timeline-popover table {
  width: 100%;
  table-layout: fixed;
}
.log-timeline-popover table td.name {
  width: 50%;
}
.log-timeline-popover table td.name.name.c-rtt span {
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  margin: 0.35em 0.35em 0 0;
  background: #AFCC48;
}
.log-timeline-popover table td.name.name.s-rtt span {
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  margin: 0.35em 0.35em 0 0;
  background: #67B0CB;
}
.log-timeline-popover table td.name.name.app-resp span {
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  margin: 0.35em 0.35em 0 0;
  background: #EEBB33;
}
.log-timeline-popover table td.name.name.data-tr span {
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  margin: 0.35em 0.35em 0 0;
  background: #AD73E8;
}
.log-timeline-popover table td.name.name.total span {
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  margin: 0.35em 0.35em 0 0;
  background: #cccccc;
}
.log-timeline-popover table td.name.name.waf-tt span {
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  margin: 0.35em 0.35em 0 0;
  background: #49A1C1;
}
.log-timeline-popover table td.val {
  padding-left: 0.6em;
}
.log-timeline-popover table tr.t_border td {
  padding-top: 3px;
  border-top: 1px solid #cccccc;
}
.log-timeline-popover table tr.b_border td {
  padding-bottom: 3px;
  border-bottom: 1px solid #cccccc;
}
.log-timeline-popover:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: -7px;
  left: 167px;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #CCC;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.log-timeline-popover:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: -6px;
  left: 168px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #FFF;
  border-left: 6px solid transparent;
}
