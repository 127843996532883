.waf-exceptions-modal__title,
.waf-exceptions-modal__subtitle {
  font-size: 14px;
}
.waf-exceptions-modal__title {
  margin: 10px 0;
  text-transform: uppercase;
}
.waf-exceptions-modal__subtitle {
  margin: 5px 0 5px 1em;
  font-weight: bold;
}
.waf-exceptions-modal__exception-static {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 5px;
  margin-left: 1em;
  font-size: 12px;
  font-weight: bold;
}
.waf-exceptions-modal__exception-static.no-padding {
  padding: 0;
}
.waf-exceptions-modal__exception-static.align-right {
  justify-content: flex-end;
}
.waf-exceptions-modal__exception-static.align-bottom {
  align-items: flex-end;
}
.waf-exceptions-modal__exception-static.align-top {
  align-items: flex-start;
}
.waf-exceptions-modal__exception-static .h-control {
  flex: 1 1 auto;
  max-width: 100%;
  padding-right: 5px;
}
.waf-exceptions-modal__exception-static .h-control label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  vertical-align: baseline;
}
.waf-exceptions-modal__exception-static .h-control i.icon-help-circled {
  vertical-align: baseline;
}
.waf-exceptions-modal__exception-static .h-control .inner-text {
  width: 100%;
}
.waf-exceptions-modal__exception-static .h-control:last-child {
  padding-right: 0;
}
.waf-exceptions-modal__exception-static .h-control.no-flex {
  flex: 0 0 auto;
}
.waf-exceptions-modal__exception-static .h-control.equal {
  flex: 1 1 0;
  min-width: 0;
}
.waf-exceptions-modal__exception-static .h-control.equal.for-two {
  max-width: 50%;
}
.waf-exceptions-modal__exception-static .h-control.flex-11 {
  flex: 1 1 0;
  overflow: hidden;
}
.waf-exceptions-modal__exception-static .h-control.flex-22 {
  flex: 2 2 0;
  overflow: hidden;
}
.waf-exceptions-modal__exception-static .h-control.flex-33 {
  flex: 3 3 0;
  overflow: hidden;
}
.waf-exceptions-modal__exception-static .h-control.flex-4 {
  flex: 0 0 100%;
}
.waf-exceptions-modal__exception-static .h-control.flex-3 {
  flex: 0 0 75%;
}
.waf-exceptions-modal__exception-static .h-control.flex-2 {
  flex: 0 0 50%;
}
.waf-exceptions-modal__exception-static .h-control.flex-1 {
  flex: 0 0 25%;
}
.waf-exceptions-modal__exception-static.with-checkbox {
  align-items: stretch;
}
.waf-exceptions-modal__exception-static.with-checkbox > .h-control.with-checkbox {
  display: flex;
  justify-content: center;
  padding-top: 21px;
}
.waf-exceptions-modal__exception-static.col-gap > .h-control:not(:last-child) {
  padding-right: 2%;
}
.waf-exceptions-modal__exception-static.col-gap > .h-control.equal.for-two {
  max-width: 49%;
}
.waf-exceptions-modal__exception-static .h-col {
  box-sizing: border-box;
  flex: initial;
  width: 50%;
  padding: 5px 0;
}
.waf-exceptions-modal__exception-static-entry {
  display: inline-block;
  position: relative;
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #ccc;
}
.waf-exceptions-modal__inputs {
  display: flex;
}
.waf-exceptions-modal__input {
  padding-right: 5px;
}
