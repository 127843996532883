svg.chart {
  margin: 0;
  padding: 0;
}
svg.chart rect.event-rect {
  opacity: 0;
}
svg.chart g.line-container {
  stroke: #8084E7;
}
svg.chart g.line-container path.line-path {
  stroke-width: 2px;
  fill: none;
}
svg.chart g.guideline g.guideline-container {
  pointer-events: none;
}
svg.chart g.guideline g.guideline-container line.v-line {
  stroke-width: 1px;
  stroke: #787878;
  stroke-opacity: 0.35;
}
svg.chart g.guideline g.guideline-container circle.v-circle {
  stroke-width: 2px;
  stroke: #FFF;
  stroke-opacity: 0.8;
  fill-opacity: 1;
  r: 5px;
}
svg.chart g.guideline g.guideline-container g.y-value rect.y-value-bg {
  stroke-width: 1px;
  stroke: #999;
  fill: white;
}
svg.chart g.guideline g.guideline-container g.y-value text.y-value-text {
  fill: #2a2a2d;
  font-size: 12px;
  font-weight: bold;
}
svg.chart line.average-line {
  stroke-dasharray: 10;
  stroke: #999;
}
svg.chart text.average-label {
  fill: #666;
  font-size: 12px;
  text-anchor: end;
  alignment-baseline: middle;
}
svg.chart g.dominators-container {
  pointer-events: none;
}
svg.chart g.dominators-container g.chart-dominators polygon.dompoly {
  fill: #8084E7;
  fill-opacity: 0.35;
}
g.chart-axis path,
g.chart-axis line {
  stroke: #999;
  shape-rendering: crispEdges;
}
g.chart-axis .tick text {
  fill: #666;
}
g.axis-timestamp text.axis-timestamp-text {
  fill: #666;
  font-size: 8px;
  text-anchor: middle;
}
g.axis-timestamp-circle circle {
  fill: #666;
  r: 3;
}
.axis-left g.chart-axis line {
  stroke-width: 0;
}
.axis-bottom g.chart-axis line {
  stroke-width: 1px;
}
.axis-bottom g.chart-axis g.tick text {
  transform: translate(0, 5px);
}
.axis-bottom g.axis-timestamp text.axis-timestamp-text {
  transform: translate(0, 12px);
}
.axis-bottom g.axis-timestamp-circle circle {
  transform: translate(0, 1px);
}
.chart-tooltip {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  box-sizing: border-box;
  max-width: 200px;
  padding: 5px 10px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 3px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.chart-tooltip .chart-tooltip-row {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 5px 0;
  font-size: 12px;
}
.chart-tooltip .chart-tooltip-row .chart-tooltip-cell {
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chart-tooltip .chart-tooltip-row .chart-tooltip-cell.name {
  flex: 1 0 0;
  min-width: 0;
  padding-right: 10px;
}
.chart-tooltip .chart-tooltip-row .chart-tooltip-cell.value {
  text-align: right;
}
.chart-tooltip.compact {
  padding: 0 10px;
}
.chart-tooltip.compact .chart-tooltip-cell.name {
  display: none;
}
