pool-group-list-expander grid div.grid .body-table-wrapper tr.background-blue {
  background-color: #EEF9FD;
}
pool-group-list-expander grid div.grid .body-table-wrapper tr.background-gray-1 {
  background-color: #e5e5e5;
}
pool-group-list-expander grid div.grid .body-table-wrapper tr.background-gray-2 {
  background-color: #ededed;
}
pool-group-list-expander grid div.grid .body-table-wrapper tr.background-gray-3 {
  background-color: #f5f5f5;
}
pool-group-list-expander grid div.grid .body-table-wrapper tr.background-gray-4 {
  background-color: #fcfcfc;
}
pool-group-list-expander grid .pool-group-status {
  line-height: 1.5em;
}
