.avi-icon {
  size: 12px;
}
.avi-icon--success {
  color: #C5D648;
}
.avi-icon--error {
  color: #DF221C;
}
.avi-icon--warning {
  color: #F2CC0D;
}
.avi-icon--info {
  color: #3EACC4;
}
