.new-progress-bar .new-progress-bar__loader-icon {
  font-size: 14px;
}
.new-progress-bar__title {
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: bold;
}
.new-progress-bar--default .new-progress-bar__progress {
  height: 8px;
  padding: 1px;
  border: 1px solid #888;
  border-radius: 5px;
}
.new-progress-bar--default .new-progress-bar__progress-bar {
  height: 8px;
  transition: width 1s ease;
  border-radius: 3px;
  background-color: #AFCC48;
}
.new-progress-bar--transition {
  height: 4px;
  padding: 0;
  border: 0;
}
.new-progress-bar--transition .new-progress-bar__progress-bar {
  height: 4px;
  transition-property: width;
  transition-duration: 4s;
  background-color: #ff4B00;
}
.new-progress-bar--transition--fast .new-progress-bar__progress-bar {
  transition-duration: 500ms;
}
.new-progress-bar--message {
  box-sizing: border-box;
  padding: 16px 20px 10px 20px;
  border: 1px solid #E2E6EA;
  border-radius: 2px;
  background-color: white;
}
.new-progress-bar--message .new-progress-bar__progress {
  width: 100%;
  height: 5px;
  border-radius: 2px;
  background-color: #E2E6EA;
}
.new-progress-bar--message .new-progress-bar__progress-bar {
  height: 100%;
  transition: width 1s;
  border-radius: 2px;
  background-color: #9AAB26;
}
.new-progress-bar--message .new-progress-bar__text {
  display: flex;
  flex-direction: row;
  padding-top: 2px;
  font-size: 0.75rem;
}
.new-progress-bar--message .new-progress-bar__message-text {
  padding-left: 3px;
}
