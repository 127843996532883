div.avi-modal.app-vs-secure-app > div[avi-loader] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body {
  height: 90%;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper {
  height: 90%;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > * {
  display: inline-block;
  box-sizing: border-box;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > div {
  height: 100%;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > div.buttons {
  width: 10%;
  text-align: center;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > div.buttons > button {
  width: 90%;
  height: 2em;
  margin: 0.5em 0;
  font-size: 35px;
  line-height: 2em;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > div.list {
  width: 45%;
  border: 1px solid #888;
  border-top: none;
  border-radius: 3px;
  background-clip: padding-box;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > div.list .header {
  height: 25px;
  margin: 0;
  padding: 0 15px;
  border: 1px solid #414666;
  background-color: #5F6695;
  color: #fff;
  line-height: 25px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > div.list ul {
  height: 90%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  background-color: #fff;
  color: #3b8dab;
  list-style: none;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > div.list ul li {
  box-sizing: border-box;
  height: 35px;
  padding: 0 15px;
  border: 1px solid transparent;
  line-height: 35px;
  cursor: pointer;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > div.list ul li:nth-child(even) {
  background-color: #fff;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > div.list ul li:not(.selected):hover {
  border-color: #414666;
  box-shadow: 0 0 5px 0 rgba(65, 70, 102, 0.5);
}
div.avi-modal.app-vs-secure-app > div.avi-modal-body > div.table-wrapper > div.list ul li.selected {
  border: 1px solid #839c2b;
  background-color: #ecf3d4;
  cursor: default;
}
div.avi-modal.app-vs-secure-app > div.avi-modal-footer > div[avi-loader] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
