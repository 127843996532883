ms-map-graph-chart {
  display: block;
  position: relative;
  height: 100%;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  background-color: #fff;
}
ms-map-graph-chart svg > g.zoom-wrapper > rect.background {
  fill: #fff;
  pointer-events: all;
}
ms-map-graph-chart svg > g.zoom-wrapper > g.wrapper > g.covered {
  opacity: 0.1;
}
ms-map-graph-chart svg > g.zoom-wrapper > g.wrapper.animation-in-progress > g.link > text.metric-value {
  transition: none;
  opacity: 0;
}
ms-map-graph-chart svg > g.legend {
  transition: opacity 0.4s ease;
}
ms-map-graph-chart svg > g.legend > rect {
  fill: none;
}
ms-map-graph-chart svg > g.legend g.node > text.name {
  fill: #000;
  font-weight: lighter;
}
ms-map-graph-chart svg > g.legend g.node > text.hs {
  font-size: 13px;
}
ms-map-graph-chart svg > g.legend g.link > text.name {
  fill: #000;
  font-size: 15px;
  font-weight: lighter;
}
ms-map-graph-chart svg > g.legend g.separators > line {
  stroke-width: 1;
  stroke: #cccccc;
}
ms-map-graph-chart svg > g.legend.transparent {
  opacity: 0;
}
ms-map-graph-chart svg g.node > circle.main {
  stroke-width: 2;
  stroke: #fff;
}
ms-map-graph-chart svg g.node > circle.main.high {
  fill: #8EAD1F;
}
ms-map-graph-chart svg g.node > circle.main.medium {
  fill: #F3EB16;
}
ms-map-graph-chart svg g.node > circle.main.low {
  fill: #FF9900;
}
ms-map-graph-chart svg g.node > circle.main.down {
  fill: #CC0000;
}
ms-map-graph-chart svg g.node > circle.main.loading {
  fill: #e6e6e6;
}
ms-map-graph-chart svg g.node > circle.main.ip,
ms-map-graph-chart svg g.node > circle.main.user {
  stroke-width: 1;
  stroke: #cccccc;
}
ms-map-graph-chart svg g.node > circle.main.ip {
  fill: #fff;
}
ms-map-graph-chart svg g.node > circle.main.user {
  fill: #000;
}
ms-map-graph-chart svg g.node > circle.border.vs {
  stroke-width: 2px;
  fill: #cccccc;
}
ms-map-graph-chart svg g.node > text {
  font-size: 15px;
}
ms-map-graph-chart svg g.node > text.hs {
  fill: #2A2A2D;
}
ms-map-graph-chart svg g.node > text.name {
  fill: #999;
}
ms-map-graph-chart svg g.node > rect.name-background {
  opacity: 0.9;
  fill: #fff;
}
ms-map-graph-chart svg g.node.user-node div.container {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 18px;
}
ms-map-graph-chart svg g.node.user-node div.container i.icon.icon-user {
  display: inline-block;
  margin-left: -2px;
  color: #fff;
  font-size: 27px;
  line-height: 45px;
}
ms-map-graph-chart svg g.node.secured > circle,
ms-map-graph-chart svg g.node.white-list > circle,
ms-map-graph-chart svg g.node.black-list > circle {
  stroke-width: 2;
}
ms-map-graph-chart svg g.node.secured > circle.border.secured,
ms-map-graph-chart svg g.node.white-list > circle.border.secured,
ms-map-graph-chart svg g.node.black-list > circle.border.secured {
  fill: #67B0CB;
}
ms-map-graph-chart svg g.node.secured > circle.border.white-list,
ms-map-graph-chart svg g.node.white-list > circle.border.white-list,
ms-map-graph-chart svg g.node.black-list > circle.border.white-list,
ms-map-graph-chart svg g.node.secured > circle.border.black-list,
ms-map-graph-chart svg g.node.white-list > circle.border.black-list,
ms-map-graph-chart svg g.node.black-list > circle.border.black-list {
  stroke-dasharray: 6, 2;
  fill: #fff;
}
ms-map-graph-chart svg g.node.secured > circle.border.white-list,
ms-map-graph-chart svg g.node.white-list > circle.border.white-list,
ms-map-graph-chart svg g.node.black-list > circle.border.white-list {
  stroke: #8c8c8c;
}
ms-map-graph-chart svg g.node.secured > circle.border.black-list,
ms-map-graph-chart svg g.node.white-list > circle.border.black-list,
ms-map-graph-chart svg g.node.black-list > circle.border.black-list {
  stroke: #ff3333;
}
ms-map-graph-chart svg g.link > path {
  stroke-width: 1;
  stroke: #999;
  fill: none;
}
ms-map-graph-chart svg g.link > text.metric-value {
  transition: opacity 0.4s ease;
  text-anchor: middle;
  font-size: 11.25px;
}
ms-map-graph-chart svg g.link.full-drops.white-list > path,
ms-map-graph-chart svg g.link.some-drops.white-list > path {
  stroke-dasharray: 6, 2;
}
ms-map-graph-chart svg g.link.white-list.full-drops > path,
ms-map-graph-chart svg g.link.black-list > path,
ms-map-graph-chart svg g.link.no-list.full-drops > path,
ms-map-graph-chart svg g.link.no-list.some-drops > path {
  stroke: #ff3333;
}
ms-map-graph-chart svg marker.arrow > path,
ms-map-graph-chart svg marker.arrow-self-ref-start > path,
ms-map-graph-chart svg marker.arrow-self-ref-end > path {
  fill: #8c8c8c;
}
ms-map-graph-chart svg marker.arrow-red > path,
ms-map-graph-chart svg marker.arrow-self-ref-start-red > path,
ms-map-graph-chart svg marker.arrow-self-ref-end-red > path {
  fill: #ff3333;
}
ms-map-graph-chart > div[avi-loader] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
ms-map-graph-chart > div.zoom-buttons {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: x-large;
  font-weight: bold;
}
ms-map-graph-chart > div.main-legend {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 15px;
}
ms-map-graph-chart > div.main-legend.collapsed > svg,
ms-map-graph-chart > div.main-legend.collapsed div.text-legend {
  display: none;
}
ms-map-graph-chart > div.outer-legend {
  position: absolute;
  left: 20px;
  color: #2A2A2D;
  font-size: 15px;
  font-weight: lighter;
  line-height: 1.5em;
  pointer-events: none;
}
ms-map-graph-chart > div.outer-legend.top {
  top: 18px;
}
