.waf-top-hits-logs-list-modal {
  width: 90%;
}
.waf-top-hits-logs-list-modal_filters-wrapper {
  display: flex;
  margin-bottom: 20px;
  padding: 4px;
  background-color: #e5e5e5;
}
.waf-top-hits-logs-list-modal_filters-wrapper-left {
  display: flex;
  flex: 1 1 85%;
  flex-wrap: wrap;
  font-size: 1.1em;
}
.waf-top-hits-logs-list-modal_filters-wrapper-left capsule {
  margin: 3px;
}
.waf-top-hits-logs-list-modal_go-to-logs-button {
  flex: 1 0 auto;
  transition: all 0.1s linear;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-height: 35px;
  padding: 0 20px;
  overflow: hidden;
  border-radius: 3px;
  outline: 0;
  background-color: #999;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  cursor: pointer;
  border: 0;
  background-color: #8ead1f;
  color: white;
}
.waf-top-hits-logs-list-modal_go-to-logs-button:hover,
.waf-top-hits-logs-list-modal_go-to-logs-button:active {
  background-color: #666;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-primary,
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-success {
  border: 0;
  background-color: #8ead1f;
  color: white;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-primary:hover,
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-success:hover,
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-primary:active,
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-success:active {
  background-color: #7a9518;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-primary i,
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-success i {
  color: inherit;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-danger {
  border: 0;
  color: white;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-danger,
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-danger:hover {
  background-color: #EA7648;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-transparent {
  padding: 0 5px;
  border: 0;
  background-color: transparent;
  box-shadow: none;
  color: #666;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-transparent:hover {
  background-color: rgba(158, 158, 158, 0.2);
}
.waf-top-hits-logs-list-modal_go-to-logs-button.icon-button {
  display: flex;
  width: 35px;
  padding: 0;
  color: #666;
  font-size: 20px;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.icon-button:not(.avi-btn-transparent),
.waf-top-hits-logs-list-modal_go-to-logs-button.icon-button:not(.avi-btn-transparent):hover {
  border: 1px solid #ccc;
  background-color: white;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.icon-button .big {
  width: 50px;
  height: 50px;
  font-size: 25px;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.disabled,
.waf-top-hits-logs-list-modal_go-to-logs-button[disabled] {
  cursor: default;
  pointer-events: none;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.disabled,
.waf-top-hits-logs-list-modal_go-to-logs-button[disabled],
.waf-top-hits-logs-list-modal_go-to-logs-button.disabled:hover,
.waf-top-hits-logs-list-modal_go-to-logs-button[disabled]:hover,
.waf-top-hits-logs-list-modal_go-to-logs-button.disabled:active,
.waf-top-hits-logs-list-modal_go-to-logs-button[disabled]:active {
  background-color: #CCC;
  color: #888;
}
.waf-top-hits-logs-list-modal_go-to-logs-button.avi-btn-sm {
  padding: 5px;
  font-size: 12px;
}
.waf-top-hits-logs-list-modal_go-to-logs-button:hover,
.waf-top-hits-logs-list-modal_go-to-logs-button:active {
  background-color: #7a9518;
}
.waf-top-hits-logs-list-modal_go-to-logs-button i {
  color: inherit;
}
.waf-top-hits-logs-list-modal log-timeline {
  margin-right: 2em;
}
