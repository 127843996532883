.main-menu {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 240px;
  height: 40px;
  padding: 0 25px;
  font-size: 18px;
  cursor: pointer;
}
.main-menu i.icon-cancel-2,
.main-menu i.icon-pencil-4 {
  margin: 0;
}
.main-menu i.icon-cancel-2 {
  display: none;
}
.main-menu--open i.icon-cancel-2 {
  display: inline-block;
}
.main-menu--open i.icon-menu {
  display: none;
}
.main-menu__text {
  margin-left: 8px;
}
@media screen and (max-width: 1100px) {
  .main-menu {
    width: auto;
  }
  .main-menu__text {
    display: none;
  }
}
