div.infra-gslb-page > div.panel.adminList section {
  margin: 10px 0 25px 0;
}
div.infra-gslb-page > div.panel.adminList section span.domain-name {
  margin-right: 15px;
  font-size: larger;
}
div.infra-gslb-page > div.panel.adminList grid .body-table-row.is-owner {
  background: rgba(142, 173, 30, 0.1);
}
div.infra-gslb-page > div.panel.adminList grid:first-of-type {
  margin-bottom: 3em;
}
div.infra-gslb-page > div.panel.adminList > div[avi-loader] {
  margin-top: 2em;
}
div.infra-gslb-page__members-list .c-grid__table-cell--field-name--status {
  line-height: initial;
}
