div[scatter-plot] {
  display: block;
}
div[scatter-plot] .brush {
  stroke-width: 0;
  stroke-opacity: 0.125;
  stroke: black;
  fill-opacity: 0.125;
  shape-rendering: crispEdges;
}
div[scatter-plot] g.axis.no-ticks {
  stroke-width: 1px;
  stroke: #777;
}
div[scatter-plot] .red {
  stroke: #ce4b18 !important;
  fill: #ce4b18 !important;
}
