.tri-checkbox__container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
}
.tri-checkbox {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 1px solid #666;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: white;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}
.tri-checkbox--checked {
  border-color: #8DAD3F;
  background: #8DAD3F;
}
.tri-checkbox--indeterminate::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-color: #8DAD3F;
  background: #8DAD3F;
}
.tri-checkbox__check {
  display: block;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 14px;
  line-height: 20px;
}
