.waf-policy-whitelist-rule-modal__enable-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  background: white;
}
.waf-policy-whitelist-rule-modal__match-label {
  display: flex;
  align-items: center;
}
.waf-policy-whitelist-rule-modal__match-help-icon {
  font-size: 0.875rem;
}
.waf-policy-whitelist-rule-modal__match-label-required::after {
  top: 0;
}
