.item-alert-popover {
  display: block;
  width: 300px;
}
.item-alert-popover__header {
  display: flex;
  height: 2em;
  padding: 0 0.5em;
  border-radius: 3px 3px 0 0;
  background-color: #333333;
  color: #fff;
  line-height: 2em;
  text-transform: capitalize;
  white-space: nowrap;
}
.item-alert-popover__header-title {
  flex: 1 1 40%;
}
.item-alert-popover__header-count {
  flex: 1 1 content;
}
.item-alert-popover__header-count:not(:last-child) {
  padding-right: 0.45em;
}
.item-alert-popover__content {
  position: relative;
  min-height: 3em;
  border-width: 0 1px;
  border-style: solid;
  border-color: #999;
}
.item-alert-popover__content [avi-loader] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.item-alert-popover__content_button_no {
  border-bottom-width: 1px;
  border-radius: 0 0 3px 3px;
}
.item-alert-popover__alert {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  min-height: 3em;
  padding: 0.25em 0;
  overflow: hidden;
  border-bottom: 1px solid #e5e5e5;
  color: #8c8c8c;
}
.item-alert-popover__alert_level_high .item-alert-popover__alert-summary {
  color: #ce4b18;
}
.item-alert-popover__alert_level_medium .item-alert-popover__alert-summary {
  color: #dca512;
}
.item-alert-popover__alert_level_low .item-alert-popover__alert-summary {
  color: #3b8dab;
}
.item-alert-popover__alert .item-alert-level-icon {
  flex: 0 1 2em;
}
.item-alert-popover__alert-text {
  flex: 1 5 100%;
}
.item-alert-popover__alert-obj-name {
  white-space: nowrap;
}
.item-alert-popover__alert-action {
  flex: 0 1 1em;
  color: #000;
}
.item-alert-popover__alert-action:hover {
  color: #ce4b18;
  cursor: pointer;
}
.item-alert-popover__alert:last-of-type {
  border-bottom: none;
}
.item-alert-popover__no-alerts {
  color: #CCC;
  line-height: 3em;
  text-align: center;
}
.item-alert-popover__go-to-button {
  width: 100%;
  border-radius: 0 0 3px 3px;
}
