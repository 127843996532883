*[percent-arc-chart] {
  height: 100%;
}
*[percent-arc-chart] > svg g.percent-arc.free > path {
  fill: #e5e5e5;
}
*[percent-arc-chart] > svg g.percent-arc.used > path {
  fill: #67B0CB;
}
server-percent-arc-chart {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
