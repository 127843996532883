scatter-plot-card {
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 70px;
  padding: 5px 0 5px 5px;
}
scatter-plot-card:hover {
  background: #F6F6F6;
  cursor: pointer;
}
scatter-plot-card > div[scatter-plot] {
  height: 49px;
}
