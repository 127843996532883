.dns-zones__header {
  margin-bottom: 10px;
}
.dns-zones__row {
  margin: 5px 0;
  display: grid;
  grid-auto-rows: minmax(min-content, max-content);
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}
.dns-zones__row .control-group {
  margin-bottom: 0;
}
.dns-zones__row .control-group--vertical-gap {
  margin-bottom: 15px;
}
.dns-zones__row--gap--none {
  grid-column-gap: 0;
  grid-row-gap: 0;
}
.dns-zones__row--gap--legacy {
  grid-column-gap: 2%;
  grid-row-gap: 15px;
}
.dns-zones__column {
  grid-column: span 4;
}
.dns-zones__column.grid-container {
  grid-template-columns: repeat(4, 1fr);
}
.dns-zones__column--admin-email {
  grid-column: span 3;
}
.dns-zones__column--admin-email.grid-container {
  grid-template-columns: repeat(3, 1fr);
}
.dns-zones__column--trash-btn {
  grid-column: span 1;
}
.dns-zones__column--trash-btn.grid-container {
  grid-template-columns: repeat(1, 1fr);
}
.dns-zones__add-domain {
  margin: 10px 0;
}
