.async-file-submit__file-upload-button-container {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 1px solid #E2E6EA;
  border-radius: 2px;
  background-color: white;
}
.async-file-submit__file-upload-button {
  box-sizing: border-box;
  height: 40px;
  font-size: 0.75rem;
}
