.waf-policy-psm-rules-config__header {
  display: flex;
  padding: 0 0 3px 70px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}
.waf-policy-psm-rules-config__label,
.waf-policy-psm-rules-config__value {
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.waf-policy-psm-rules-config__label--name,
.waf-policy-psm-rules-config__value--name {
  width: 300px;
}
.waf-policy-psm-rules-config__label--matches,
.waf-policy-psm-rules-config__value--matches {
  width: 200px;
}
