.waf-policy-psm-locations-config__header {
  display: flex;
  padding: 0 0 3px 20px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}
.waf-policy-psm-locations-config__label,
.waf-policy-psm-locations-config__value {
  width: 400px;
  padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
