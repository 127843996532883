.timing-tile {
  display: flex;
  box-sizing: border-box;
  flex: 1 1 auto;
  overflow: hidden;
  text-align: center;
}
.timing-tile--highlighted {
  background-color: #F9F9F9;
}
.timing-tile__icon {
  display: inherit;
  flex: 1 1 50%;
  flex-direction: column;
  justify-content: center;
}
.timing-tile__icon-image {
  display: block;
  flex: 0 0 auto;
  max-width: 50px;
  max-height: 35px;
  margin: 0 auto;
}
.timing-tile__icon-title {
  flex: 0 0 auto;
  height: 1.5em;
  line-height: 1.5em;
}
.timing-tile__value {
  display: inherit;
  flex: 1 1 50%;
  flex-direction: column;
  justify-content: center;
}
.timing-tile__value-title {
  flex: 0 1 auto;
}
.timing-tile__value-value {
  flex: 0 1 auto;
}
.timing-tile__value-value .timeValue {
  font-size: 1.286em;
}
.timing-tile__value-value .timing-units {
  color: #999;
  font-size: 0.857em;
  vertical-align: bottom;
}
.timing-tiles {
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 100px;
  padding: 10px;
  overflow: hidden;
  background: #fff;
  white-space: nowrap;
}
.timing-tiles__header {
  position: absolute;
  z-index: 0;
  top: 5px;
  left: 10px;
  font-size: 1em;
}
.timing-tiles__list-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.timing-tiles .timing-tile {
  z-index: 1;
}
.timing-tiles .timing-tile--icon {
  flex-grow: 0.5;
  flex-shrink: 2;
}
