.dropdown-list-option {
  display: block;
  padding: 10px 12px;
  border-bottom: 1px solid #f4f4f4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-list-option:hover {
  background-color: #f4f4f4;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
