.avi-certificate-list textarea.copy-certificate {
  width: 95%;
  height: 100px;
  margin: 5px;
  margin-top: 10px;
  cursor: pointer;
}
.avi-certificate-list .icon-circle.status-good {
  color: #8EAD1F;
}
.avi-certificate-list .icon-circle.status-expired {
  color: #CC0000;
}
.avi-certificate-list .icon-circle.status-expiring {
  color: #FF9900;
}
.avi-certificate-list .icon-circle.status-pending {
  color: #999;
}
.avi-certificate-list .grid-field-status {
  width: 75px;
}
.avi-certificate-list .grid-cell-status {
  text-align: center;
}
.certificate-status-tooltip .status-expiring {
  color: #FF9900;
}
