div[pie-chart-card] {
  position: relative;
  overflow: hidden;
}
div[pie-chart-card]:last-child > div {
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
div[pie-chart-card] > div {
  box-sizing: border-box;
  height: 100%;
  padding: 5px;
  border-width: 1px 1px 1px 0;
  border-style: solid;
  border-color: #888;
}
div[pie-chart-card] > div.active {
  background-color: #fff;
  background-image: none;
}
div[pie-chart-card] > div > div {
  display: inline-block;
  box-sizing: border-box;
  width: 49%;
}
div[pie-chart-card] > div > div.current-values {
  padding-left: 0.3em;
  white-space: nowrap;
}
div[pie-chart-card] > div > div.current-values > div {
  padding-left: 0.5em;
}
div[pie-chart-card] > div > div.current-values[pie-chart] {
  width: 50%;
  height: 50%;
}
