client-log-filters {
  display: block;
  position: relative;
}
client-log-filters .configuration .control-group.enabled {
  width: 4%;
}
client-log-filters .configuration .control-group.name {
  width: 64%;
  margin-right: 2%;
}
client-log-filters .configuration .control-group.name .inner-text {
  width: 100%;
}
client-log-filters .configuration .control-group.duration {
  width: 15%;
}
client-log-filters .configuration .control-group.duration .inner-text {
  width: 100%;
}
client-log-filters .configuration .control-group.all-headers {
  width: 15%;
  padding-top: 1.5em;
}
client-log-filters .full-client-logs .inner-text {
  width: 60%;
}
client-log-filters .add-log-button-container {
  position: absolute;
  z-index: 1;
  right: 0;
  transform: translate(0, 5px);
}
client-log-filters dropdown.string-match-criteria {
  width: 200px;
  margin: 0 5px 5px 0;
}
