unit-card[type=net] .card-info .unit-name:before {
  content: '\e826';
}
.unit-card {
  display: block;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 215px;
  height: 60px;
  border: 1px solid;
  cursor: pointer;
}
.unit-card .card-info {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}
.unit-card .card-info .health-circle {
  flex: 0 0 auto;
  padding-left: 5px;
}
.unit-card .card-info .unit-name {
  display: block;
  position: relative;
  margin-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  color: #2a2a2d;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unit-card .card-info .unit-name .name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.unit-card .unit-card-type {
  position: absolute;
  right: 4px;
  bottom: 2px;
  overflow: hidden;
  font-size: 10px;
  text-transform: uppercase;
}
.unit-card .info-block {
  position: absolute;
  top: 0;
  right: 2px;
  color: #cccccc;
  font-size: 12px;
}
.unit-card .info-block i.icon {
  color: #666;
}
.unit-card .info-block > * {
  display: inline-block;
  position: relative;
  margin-left: 2px;
}
.unit-card.high {
  border-color: #8ead1f;
  background-color: #F3F6E8;
}
.unit-card.medium {
  border-color: #f3d416;
  background-color: #FDFAE7;
}
.unit-card.low {
  border-color: #ff9900;
  background-color: #FFF4E5;
}
.unit-card.down {
  border-color: #CC0000;
  background-color: #fff0f0;
}
.unit-card.disabled {
  border-color: #ccc;
  background-color: #f9f9f9;
}
.unit-card.fqdn {
  border-color: #56bfe5;
  background-color: #f9f9f9;
}
unit-card {
  display: inline-block;
  position: relative;
  overflow: hidden;
}
unit-card:not([type=server]):not([type=net]) .unit-card.down,
unit-card:not([type=server]):not([type=net]) .unit-card.disabled {
  color: inherit;
}
unit-card:not([type=server]):not([type=net]) .unit-card.down .card-info,
unit-card:not([type=server]):not([type=net]) .unit-card.disabled .card-info {
  color: inherit;
}
unit-card[type=server] .unit-card,
unit-card[type=net] .unit-card {
  width: auto;
  height: auto;
  border: 0;
  background-color: transparent;
}
unit-card[type=net] .card-info .unit-name {
  margin-left: 0;
  padding-left: 20px;
}
unit-card[type=net] .card-info .unit-name:before {
  position: absolute;
  top: 2px;
  left: 0;
  width: 1em;
  height: 1em;
  /* stylelint-disable-next-line */
  font-family: 'avi';
}
unit-card[type=net] .card-info .health-circle {
  display: none;
}
unit-card[type=server].minimized .health-entity-name.unit-name {
  display: none;
}
unit-card[type=server] .unit-card .unit-name {
  margin-left: 5px;
}
unit-card[type=se] .unit-card {
  width: 165px;
  height: 50px;
  border-radius: 25px;
}
unit-card[type=se] .unit-card .card-info .unit-name {
  margin: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
unit-card[type=se] .unit-card .unit-card-type {
  right: 20px;
}
unit-card[type=se] .info-block {
  top: 2px;
  right: 15px;
}
unit-card[type=pool] .unit-card {
  border-radius: 12px;
}
unit-card[type=pool] .unit-card .unit-card-type {
  right: 10px;
}
unit-card[type=pool] .info-block {
  top: 2px;
  right: 10px;
}
body > div.aviPopover.unit-card-popover {
  z-index: 1001;
}
body > div.aviPopoverCarat.unit-card-popover {
  z-index: 1002;
}
body > div.aviPopoverCarat.unit-card-popover.top:before {
  top: 1px;
  border-top-color: #bfbfbf;
}
body > div.aviPopoverCarat.unit-card-popover.top:after {
  border-top-color: #ebebeb;
}
body > div.aviPopoverCarat.unit-card-popover.bottom:before {
  top: -1px;
  border-bottom-color: #bfbfbf;
}
body > div.aviPopoverCarat.unit-card-popover.bottom:after {
  border-bottom-color: #ebebeb;
}
body > div.aviPopoverCarat.unit-card-popover.right:before {
  left: -1px;
  border-right-color: #bfbfbf;
}
body > div.aviPopoverCarat.unit-card-popover.right:after {
  border-right-color: #ebebeb;
}
body > div.aviPopoverCarat.unit-card-popover.left:before {
  left: 1px;
  border-left-color: #bfbfbf;
}
body > div.aviPopoverCarat.unit-card-popover.left:after {
  border-left-color: #ebebeb;
}
pool-group-unit-cards {
  display: block;
}
pool-group-unit-cards.dashboard .poolgroup-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 235px;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #cccccc;
}
pool-group-unit-cards.dashboard .poolgroup-member {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
}
pool-group-unit-cards.dashboard .poolgroup-member .unit-card-wrapper {
  padding: 0 5px;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
}
pool-group-unit-cards.dashboard .poolgroup-priority:last-child .poolgroup-member:last-child .unit-card-wrapper {
  border-bottom: 1px solid #cccccc;
}
pool-group-unit-cards.dashboard .priority-label {
  width: 235px;
  padding: 0 5px;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
}
pool-group-unit-cards.dashboard .no-pools-wrapper {
  width: 235px;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #cccccc;
}
pool-group-unit-cards .poolgroup-name {
  padding: 5px;
}
pool-group-unit-cards .poolgroup-priority .unit-card-wrapper,
pool-group-unit-cards .poolgroup-priority .priority-label {
  background-clip: content-box;
  background-color: #f5f5f5;
}
pool-group-unit-cards .poolgroup-priority:first-child .unit-card-wrapper,
pool-group-unit-cards .poolgroup-priority:first-child .priority-label {
  background-color: #EEF9FD;
}
pool-group-unit-cards .poolgroup-priority:nth-child(2) .unit-card-wrapper,
pool-group-unit-cards .poolgroup-priority:nth-child(2) .priority-label {
  background-color: #e5e5e5;
}
pool-group-unit-cards .poolgroup-priority:nth-child(3) .unit-card-wrapper,
pool-group-unit-cards .poolgroup-priority:nth-child(3) .priority-label {
  background-color: #ededed;
}
pool-group-unit-cards .poolgroup-priority .priority-label > div {
  padding: 5px 10px;
}
pool-group-unit-cards .poolgroup-priority .poolgroup-member unit-card[type=pool] {
  padding: 0 10px;
}
pool-group-unit-cards .poolgroup-priority .poolgroup-member:last-child .unit-card-wrapper {
  padding-bottom: 5px;
}
pool-group-unit-cards .poolgroup-priority .poolgroup-member:last-child unit-card[type=pool] {
  padding-bottom: 10px;
}
pool-group-unit-cards .poolgroup-priority .poolgroup-member ul.nets {
  margin-left: 4em;
}
pool-group-unit-cards .no-pools-wrapper {
  padding: 0 5px 5px 5px;
  background-clip: content-box;
  background-color: #f5f5f5;
}
pool-group-unit-cards .no-pools-wrapper .no-pools {
  padding: 5px;
}
.gslb-service-unit-tree-group {
  max-width: 490px;
  margin-bottom: 30px;
  padding: 10px 0 20px 20px;
  background: #f9f9f9;
}
.gslb-service-unit-tree-group .header {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: bold;
}
.gslb-service-unit-tree-group .member-list {
  display: block;
  position: relative;
  white-space: normal;
}
.gslb-service-unit-tree-group .member-list .member {
  display: inline-block;
  position: relative;
  margin-right: 20px;
}
.gslb-service-unit-tree-group .member-list .member:not(:last-child) {
  margin-bottom: 20px;
}
.gslb-service-unit-tree-group .member-list .member .unit-card {
  cursor: default;
}
.unit-card.gslb-service-unit-name {
  display: inline-block;
}
