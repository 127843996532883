.dropdown-menu-rollback-option {
  display: block;
  position: relative;
  padding: 10px 12px;
  border-bottom: 1px solid #f4f4f4;
  color: inherit;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-menu-rollback-option__title {
  font-weight: bold;
}
.dropdown-menu-rollback-option:hover {
  background-color: #f4f4f4;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
