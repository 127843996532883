.log-filter-number-popover {
  display: block;
  padding: 5px;
  border: 1px solid #cccccc;
  background-color: #fff;
  text-align: right;
  border-radius: 3px;
  background-clip: padding-box;
}
.log-filter-number-popover .log-filter-number-popover_filter {
  display: block;
  padding: 5px;
}
.log-filter-number-popover .log-filter-number-popover_filter:hover {
  text-decoration: underline;
}
.log-filter-number-popover .log-filter-number-popover_filter:nth-child(n + 2) {
  border-top: 1px solid #cccccc;
}
