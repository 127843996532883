.user-modal-tenant-settings__label {
  margin-top: 10px;
}
.user-modal-tenant-settings__no-tenants-message {
  margin-top: 10px;
}
.user-modal-tenant-settings__default-tenant {
  margin-top: 15px;
}
.user-modal-tenant-settings__add-tenant {
  margin-top: 15px;
}
.user-modal-tenant-settings__add-tenant--icon {
  margin-right: 5px;
}
.user-modal-tenant-settings__tenant-select {
  margin-top: 15px;
}
.user-modal-tenant-settings__tenant-delete-btn {
  margin-top: 35px;
}
