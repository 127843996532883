.template-confirm {
  position: absolute;
  top: 47px;
  bottom: 45px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 1%;
  padding-bottom: 60px;
  overflow-y: auto;
}
.template-confirm .matrix-container {
  max-height: 250px;
  margin: 0 30px;
  overflow-y: auto;
  border-bottom: 1px solid #888;
}
.template-confirm .matrix-container grid {
  text-align: left;
}
.template-confirm .matrix-container grid div.grid > table tr.row {
  height: 30px;
}
.template-confirm .c-grid__table-cell {
  width: 50%;
}
