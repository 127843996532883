.waf-log-match-element {
  display: inline-flex;
  margin-right: 5px;
  border: 1px solid #ccc;
  font-size: 0.825rem;
  font-weight: 600;
}
.waf-log-match-element__name {
  padding: 3px 10px;
  border-right: 1px solid #ccc;
}
.waf-log-match-element__value {
  padding: 3px 10px;
}
