switch .switch-state .true.selected:before {
  content: '\e818';
}
switch .switch-state .false.selected:before {
  content: '\e81e';
}
switch {
  display: inline-block;
  cursor: pointer;
}
switch .switch-state {
  width: 50px;
  height: 25px;
  border-radius: 15px;
  background-color: #E55B24;
  color: #FFF;
  /* stylelint-disable-next-line */
  font-family: 'avi';
  font-size: 14px;
  line-height: 20px;
}
switch .switch-state .true {
  width: 19px;
  height: 19px;
  margin: 2px;
  float: left;
  border: 1px solid #3FA1E1;
  border-radius: 15px;
  background: #67B0CB;
}
switch .switch-state .true.selected {
  margin-right: -7px;
  margin-left: 7px;
  border: none;
  background-color: #AFCC48;
}
switch .switch-state .true.selected:before {
  /* stylelint-disable-next-line */
  font-family: 'avi';
}
switch .switch-state .false {
  width: 19px;
  height: 19px;
  margin: 2px;
  float: right;
  border: 1px solid #3FA1E1;
  border-radius: 15px;
  background: #67B0CB;
}
switch .switch-state .false.selected {
  border: none;
  background-color: #E55B24;
}
switch .switch-state .false.selected:before {
  /* stylelint-disable-next-line */
  font-family: 'avi';
}
switch .switch-state.enabled {
  background-color: #AFCC48;
}
switch.small .switch-state {
  width: 40px;
  height: 20px;
}
switch.small .switch-state .true {
  width: 14px;
  height: 14px;
}
switch.small .switch-state .true.selected {
  margin-top: 0;
  margin-left: 5px;
}
switch.small .switch-state .false {
  width: 14px;
  height: 14px;
}
switch.small .switch-state .false.selected {
  margin-top: 1px;
}
switch.vertswitch {
  display: inline-block;
  padding: 1px;
  text-align: center;
  cursor: pointer;
}
switch.vertswitch .switch-state {
  position: relative;
  width: 20px;
  height: 35px;
  border-radius: 15px;
  background-color: #8c8c8c;
  color: #FFF;
  /* stylelint-disable-next-line */
  font-family: 'avi';
  font-size: 10px;
  line-height: 20px;
  text-align: center;
}
switch.vertswitch .switch-state .true,
switch.vertswitch .switch-state .false {
  position: absolute;
  width: 14px;
  height: 14px;
  margin: 0 2px;
  border: 1px solid #2e6d85;
  background: #67B0CB;
  /* stylelint-disable-next-line */
  font-family: 'avi';
}
switch.vertswitch .switch-state .true.selected,
switch.vertswitch .switch-state .false.selected {
  width: 16px;
  height: 16px;
  border: none;
  background-color: transparent;
}
switch.vertswitch .switch-state .true {
  bottom: 2px;
}
switch.vertswitch .switch-state .false {
  top: 2px;
}
switch.vertswitch .switch-state.enabled {
  box-sizing: border-box;
  background-color: #AFCC48;
}
