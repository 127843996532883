.ordered-grid__create-menu.avi-menu-list {
  width: 150px;
  padding: 5px;
  border: 0;
  border-radius: 0;
  background-color: #999;
}
.ordered-grid__create-menu.avi-menu-list .create-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #85ba65;
}
.ordered-grid__create-menu.avi-menu-list .create-button .plus {
  color: white;
  font-size: 20px;
  font-weight: 100;
  line-height: 20px;
}
.ordered-grid__create-menu__list-item.avi-menu-list-item {
  height: 28px;
  padding: 0 10px;
  background-color: #999;
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: flex-start;
}
.ordered-grid__create-menu__list-item.avi-menu-list-item > .icon-wrapper {
  width: 20px;
  margin-right: 5px;
}
.ordered-grid__create-menu__list-item.avi-menu-list-item:hover {
  background-color: #cccccc;
  color: white;
}
ordered-grid-controls .grid-controls {
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin: 12px 0;
}
ordered-grid-controls .grid-controls .create-button {
  background-color: #85ba65;
}
ordered-grid-controls .grid-controls i.icon-search {
  color: #8c8c8c;
  font-size: 22px;
}
ordered-grid-controls .grid-controls .sl-icon-plus {
  color: #67B0CB;
  font-size: 24px;
}
ordered-grid-controls .grid-controls .grid-control-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
}
ordered-grid-controls .grid-controls .grid-control-section.separator {
  height: 35px;
  border-right: 1px solid #cccccc;
}
ordered-grid-controls .grid-controls .grid-control-section.filters {
  align-items: flex-start;
}
ordered-grid-controls .grid-controls .grid-control-section .filterset {
  flex: 1;
}
ordered-grid-controls .grid-controls .grid-control-section .filterset:not(:first-child) {
  padding-left: 15px;
  border-left: 1px solid #cccccc;
}
ordered-grid-controls .grid-controls .grid-control-section .filterset:not(:last-child) {
  padding-right: 15px;
}
ordered-grid-controls .grid-controls .grid-control-section .filterset .filter {
  display: inline-block;
  margin: 2px 0;
  padding: 7px;
  border: 1px solid #cccccc;
  border-radius: 15px;
  font-size: 12px;
  cursor: pointer;
}
ordered-grid-controls .grid-controls .grid-control-section .filterset .filter:not(:last-child) {
  margin-right: 5px;
}
ordered-grid-controls .grid-controls .grid-control-section .filterset .filter:hover {
  background-color: #f3f4f8;
}
ordered-grid-controls .grid-controls .grid-control-section .filterset .filter.enabled {
  border-color: #999;
  background-color: #999;
  color: white;
}
