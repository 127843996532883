.sideways-stacking-bar-chart {
  z-index: 1;
  width: 100%;
}
.sideways-stacking-bar-chart .bar {
  padding-bottom: 5px;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.sideways-stacking-bar-chart .bar .viewable-title {
  padding-right: 2px;
  padding-left: 2px;
  overflow: hidden;
}
.sideways-stacking-bar-chart .oneline-bar-chart {
  overflow: hidden;
  border-radius: 3px;
}
.sideways-stacking-bar-chart .oneline-bar-chart .container {
  width: 100%;
}
.sideways-stacking-bar-chart .oneline-bar-chart .oneline-bar {
  display: inline-block;
  border: 0;
  color: white;
  text-align: center;
  vertical-align: top;
}
.sideways-stacking-bar-chart .oneline-bar-chart .oneline-bar .viewable-title {
  padding-right: 2px;
  padding-left: 2px;
  overflow: hidden;
  white-space: nowrap;
}
.sideways-stacking-bar-chart .oneline-bar-chart .first-bar {
  border-top-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
  background-clip: padding-box;
}
.sideways-stacking-bar-chart .oneline-bar-chart .last-bar {
  border-top-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
  background-clip: padding-box;
}
.sideways-stacking-bar-chart .oneline-bar-chart .first-bar.last-bar {
  border-radius: 3px;
}
