hs-graph {
  display: block;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  padding: 0.75em;
  background-color: #fff;
}
hs-graph div[avi-loader] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
hs-graph svg g.wrapper g.node circle {
  stroke-width: 1.5px;
  stroke: #999;
  fill: #fff;
}
hs-graph svg g.wrapper g.node text {
  font-size: 15px;
}
hs-graph svg g.wrapper g.node.parent {
  cursor: pointer;
}
hs-graph svg g.wrapper g.node.leaf circle {
  fill: #fff;
}
hs-graph svg g.wrapper g.node.root circle {
  stroke-width: 3px;
}
hs-graph svg g.wrapper g.node.root circle.high {
  fill: #8EAD1F;
}
hs-graph svg g.wrapper g.node.root circle.medium {
  fill: #F3EB16;
}
hs-graph svg g.wrapper g.node.root circle.low {
  fill: #FF9900;
}
hs-graph svg g.wrapper g.node.root circle.down {
  fill: #CC0000;
}
hs-graph svg g.wrapper g.node.root circle.loading {
  fill: #e6e6e6;
}
hs-graph svg g.wrapper g.node.root div.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 30px;
  color: #666;
  text-align: center;
}
hs-graph svg g.wrapper g.node.root div.container > * {
  font-size: 30px;
  line-height: 60px;
}
hs-graph svg g.wrapper g.node.root div.container > metrics-value {
  width: 100%;
}
hs-graph svg g.wrapper g.node.contributor circle {
  stroke-width: 3px;
}
hs-graph svg g.wrapper g.node.score div.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  color: #666;
  text-align: center;
}
hs-graph svg g.wrapper g.node.score div.container > * {
  font-size: 20px;
  line-height: 40px;
}
hs-graph svg g.wrapper g.node.score div.container > metrics-value {
  width: 100%;
}
hs-graph svg g.wrapper g.node.active text {
  fill: #ff4B00;
}
hs-graph svg g.wrapper .link {
  stroke-width: 1px;
  stroke: #999;
  fill: none;
  stroke-opacity: 0.4;
}
hs-graph svg g.wrapper .link.contributor {
  stroke-width: 2px;
  stroke-opacity: 1;
}
