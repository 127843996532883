.expander {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
}
.expander__expander-content {
  flex: 1;
}
.expander__caret {
  padding-right: 10px;
  color: #2a2a2d;
  font-size: 1rem;
}
.expander__hover-control {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.expander__options {
  padding: 0 10px;
}
