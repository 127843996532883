virtualservice-faults {
  display: block;
  margin: 15px;
  font-size: 15px;
}
virtualservice-faults.message {
  box-sizing: border-box;
  width: 500px;
}
virtualservice-faults .count {
  color: #EA7648;
}
virtualservice-faults .count:hover {
  color: #CC0000;
}
virtualservice-faults li:before {
  content: "•";
}
virtualservice-faults li.pool {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 5px 10px;
}
virtualservice-faults li.pool:before {
  content: "‣";
}
virtualservice-faults i.icon-attention-2 {
  color: #CC0000;
}
