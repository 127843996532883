.user-menu {
  display: flex;
  padding-right: 30px;
}
.user-menu__item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #ccc;
  cursor: pointer;
}
.user-menu__item:hover {
  color: #fff;
}
.user-menu__item:not(:last-child) {
  margin-right: 20px;
}
.user-menu__avi-logo {
  font-size: 24px;
}
.user-menu__avi-logo:hover {
  color: #fff;
}
.user-menu__list {
  display: inline-block;
  padding: 0;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.27);
  color: #666;
  line-height: normal;
}
.user-menu__list--user-card-menu {
  width: 330px;
}
.user-menu__list-item {
  display: block;
  position: relative;
  padding: 10px;
  overflow: hidden;
  color: inherit;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-menu__list-item--border {
  border-bottom: 1px solid #D5D5D5;
}
.user-menu__list-item.active {
  color: #FF4B00;
}
.user-menu__list-item--user-card {
  min-height: 115px;
  padding: 0;
}
.user-menu__list-item--with-hover:hover {
  background-color: #F1F1F1;
  color: inherit;
  text-decoration: none;
}
.aviPopover.user-menu-popover {
  z-index: 1000;
}
