.avi-serviceengine-analytics h4 {
  height: 20px;
  margin: 0;
  padding: 0;
  font-size: 18px;
}
.avi-serviceengine-analytics .card-quarter {
  height: 105px;
}
.avi-serviceengine-analytics .sparkline-card,
.avi-serviceengine-analytics .gauge-card-class,
.avi-serviceengine-analytics .sparkline-card-class {
  padding: 0 10px;
}
.avi-serviceengine-analytics .performance-chart,
.avi-serviceengine-analytics .chart-with-overlays {
  width: 100%;
}
.avi-serviceengine-analytics .avi-subheader .navbar {
  width: auto;
}
.avi-serviceengine-analytics .navbar-bottom-section {
  height: 35px;
}
